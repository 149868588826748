
export default {
  // Credit Card
  "Enter payment information": "Введите платежную информацию",
  "Enter the amount in USD": "Введите сумму в USD",
  "Deposit Amount": "Сумма депозита",
  "Well Done !": "Отлично !",
  "NEXT": "ДАЛЕЕ",
  "Card info": "Информация о карте",
  "Card number": "Номер карты",
  "MM/YY": "ММ/ГГ",
  "PREVIOUS": "ПРЕДЫДУЩАЯ",
  "Billing info": "Оплата",
  "Country": "Страна",
  "Canton": "Область",
  "Canton*": "Область*",
  "Postal/ZIP code": "Почтовый индекс",
  "Postal/ZIP code*": "Почтовый индекс*",
  "Address": "Адрес",
  "Address*": "Адрес*",
  "City": "Город",
  "City*": "Город*",
  "Phone": "Телефон",
  "NEW DEPOSIT": "НОВЫЙ ДЕПОЗИТ",
  "Deposit declined!": "Депозит отклонён!",
  "Error !": "Ошибка !",
  "deposit_declined_text" : "Ваш запрос был отклонен. Данный вид вклада в настоящее время недоступен в вашей стране." +
    "</br> Пожалуйста, свяжитесь со службой поддержки для получения дополнительной информации",
  "deposit_pending_text": "Ваш запрос на депозит был отправлен, статус \"В ожидании\". " +
    "</br> Если это будет необходимо, мы свяжемся с вами",
  "Please enter Deposit Amount": "Пожалуйста, введите сумму депозита",
  "Please enter a valid Card Number (16 digits)": "Пожалуйста, введите действительный номер карты (16 цифр)",
  "Please enter a valid Expiry Date (MM/YY)": "Пожалуйста, введите действительную дату истечения срока действия (ММ/ГГ)",
  "Please enter a valid CVC (3 digits)": "Пожалуйста, введите правильный CVC (3 цифры)",
  "Please enter Canton": "Пожалуйста, укажите область",
  "Please enter Address": "Пожалуйста, укажите адрес",
  "Please enter Postal Code": "Пожалуйста, укажите почтовый индекс",
  "Please enter City": "Пожалуйста, укажите город",
  "Please enter Phone Number": "Пожалуйста, укажите номер телефона",

  // Crypto
  "Crypto Deposit": "Депозит криптовалюты",
  "Currency": "Валюта",
  "Total": "Всего",
  "DEPOSIT": "ДЕПОЗИТ",

  // Deposit details
  "Deposit details": "Детали депозита",
  "Amount in USD": "Сумма в USD",
  "Amount in asset": "Сумма в активе",

  // Bank details
  "Banks List": "Список банков",
  "Bank deposit is temporary disabled !": "Банковский депозит временно отключен !",
  "This information is not currently available.": "В настоящее время эта информация недоступна.",
  "For more details, please contact support.": "Для получения более подробной информации обратитесь в службу поддержки.",
  "BANK INFORMATION": "БАНКОВСКАЯ ИНФОРМАЦИЯ",
  "Banks list is empty.": "Список банков пуст."
}
