
export default {
  // Balance Widget
  "Balance": "Баланс",
  "Available": "Доступный",
  "Locked": "Закрытый",
  "Total amount": "Общая сумма",

  // Account Balance Dropdown
  "Account Balance": "Баланс аккаунта",
  "No balance": "Нет баланса",

  // Exchange Form
  "Locked Balance": "Закрытый баланс",
  "Available Balance": "Доступный баланс",
  "Sell asset balance": "Баланс продаваемых активов",
  "Sell asset": "Продаваемый актив",
  "Buy asset": "Покупаемый актив",
  "Sell amount": "Сумма продажи",
  "Buy amount": "Сумма покупки",
  "Transaction Fees": "Комиссия",
  "Total sell amount": "Общая сумма продажи",
  "Total pay amount:": "Общая сумма оплаты:",
  "Max sum": "Максимальная сумма",
  "Exchange message header": "Полученная сумма может отличаться от текущей.",
  "Exchange message content": "Этот курс обмена гарантируется в течение 30 минут после создания заказа. В редких случаях, " +
    "когда для оплаты Вашего платежа потребуется более 30 минут, мы будем обновлять обменный курс каждые 1 минуту " +
    "до тех пор, пока оплата не поступит на наш счет.",
  "You receive:": "Вы получите:",
  "Paying from Available Balance": "Плата снимается с доступного баланса",
  "Select the asset you want to sell with a balance greater than zero.": "Выберите актив, который вы хотите " +
    "продать, с балансом больше нуля.",
  "Buy amount must be greater than zero": "Сумма покупки должна быть больше нуля",

  //Verification status icon
  "Not verified": "Не верифицирован",
  "Verified": "Верифицирован",

  // Profile dropdown
  "Profile": "Профиль",
  "Logout": "Выход",

  //Transaction Details Modal
  "Transaction Details": "Детали Транзакции",
  "Buy amount in USD": "Сумма покупки в USD",
  "Withdrawal": "Вывод"
}
