import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {Button, Card, CardBody, Col, Container, Form, Row} from 'reactstrap'
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth"
import {useDispatch} from "react-redux"
import {useTranslation} from "react-i18next"

//import images
import default_avatar from "../../assets/images/users/default_avatar.webp"
import {verify2fa} from "../../api/auth"
import {setTwoFactorAuthState} from "../../slices/auth/profile/reducer"
import {toast} from "react-toastify"
import {getProfile} from "../../api/account";

// Toastify
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const TwoFactorAuth = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [oneTimePassword, setOneTimePassword] = useState('')
  const [profileData, setProfileData] = useState([])
  const brandLogosUrl = import.meta.env.VITE_APP_URL + '/storage/brand_logos/'
  const logoHeight = import.meta.env.VITE_THEME_LOGO_SIZE || 70
  const logoLightUrl = brandLogosUrl + 'logo-light.png'

  const { t } = useTranslation()

  const getProfileData = () => {
    getProfile().then(r => {
      setProfileData(r)
    })
  }

	const unlockAccess = (e) => {
		const btn = e.target
		btn.disabled = true

		const data = {
			'one_time_password': oneTimePassword,
		}

		verify2fa(data).then(r => {
			if (r.success) {
				dispatch(setTwoFactorAuthState(false))
				navigate('/dashboard')
			} else {
				toast.error(t(r.message))
			}
		})
      .catch(message => {
        toast.error(t(message))
        setOneTimePassword('')
      })
      .finally(() => btn.disabled = false)
	}

  useEffect(() => {
    getProfileData()
  }, [])

	document.title = import.meta.env.VITE_APP_NAME + " | "

	return (
		<React.Fragment>
      <ToastContainer />
			<div className="auth-page-content">
				<div className="auth-page-wrapper">
					<ParticlesAuth>
						<div className="auth-page-content">
							<Container>
								<Row>
									<Col lg={12}>
										<div className="text-center mt-sm-5 mb-4 text-white-50">
											<div>
												<Link to="/dashboard" className="d-inline-block auth-logo">
                          {
                            import.meta.env.VITE_APP_NAME === 'Crypto Clasic' ?
                              <img className="bg-light rounded-circle" src={logoLightUrl} alt="" height={logoHeight}/> :
                              <img src={logoLightUrl} alt="" height={logoHeight}/>
                          }
												</Link>
											</div>
										</div>
									</Col>
								</Row>

								<Row className="justify-content-center">
									<Col md={8} lg={6} xl={5}>
										<Card className="mt-4">
											<CardBody className="p-4">
												<div className="text-center mt-2">
													<h5 className="text-primary">{t("Lock Screen")}</h5>
													<p className="text-muted">{t("Enter your 2FA code to unlock the screen!")}</p>
												</div>
												<div className="user-thumb text-center">
													<img
                            src={profileData.avatar_url ? profileData.avatar_url : default_avatar}
                            className="rounded-circle img-thumbnail avatar-lg" alt="thumbnail"
                          />
													{/*<h5 className="font-size-15 mt-3">{user.name}</h5>*/}
												</div>
												<div className="p-2 mt-4">
													<Form onSubmit={e => e.preventDefault()}>
														<div className="mb-3">
															<label className="form-label" htmlFor="2fa_code">{t("2FA code")}</label>
															<input
																type="text"
																className="form-control"
																id="2fa_code"
																placeholder={t("Enter code")}
																value={oneTimePassword}
																onChange={e => setOneTimePassword(e.target.value)}
																onKeyDown={e => {
																	if (e.key === 'Enter') {
																		unlockAccess()
																	}
																}}
																autoComplete="off"
																required
															/>
														</div>
														<div className="mb-2 mt-4">
															<Button
																color="success"
																className="w-100"
																type="button"
																onClick={unlockAccess}
															>
                                {t("Unlock")}
															</Button>
														</div>
													</Form>
												</div>
											</CardBody>
										</Card>
									</Col>
								</Row>
							</Container>
						</div>
					</ParticlesAuth>
				</div>
			</div>
		</React.Fragment>
	)
}

export default TwoFactorAuth
