import {configureStore} from "@reduxjs/toolkit"
import rootReducer from "./slices"

const store = configureStore({
    reducer: rootReducer,
    // devTools: true,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
});

export {store}
