import React, {useEffect, useImperativeHandle, useRef, useState} from 'react'
import TableFilters from './TableFilters'
import {
  Row,
  Col, Button,
} from 'reactstrap'

import DataTable from 'react-data-table-component'
import {APIClient} from "../../helpers/api_helper"
import {useSelector} from "react-redux"
import classnames from "classnames"
import {useTranslation} from "react-i18next"

const BaseTable = (props) => {
  const api = new APIClient
  const {layoutModeType} = useSelector(state => ({
    layoutModeType: state.Layout.layoutModeType,
  }))

  const { t } = useTranslation()

  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(true)
  const [totalRows, setTotalRows] = useState(0)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)
  const [filters, setFilters] = useState([])
  const loadingFinished = useRef(false)
  const [overlay, setOverlay] = useState(false)

  // Table handlers
  const handlePageChange = page => {
    setPage(page)
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage)
    setPage(page)
  }

  // Filters
  const filtersHandler = (filtersData) => {
    setFilters(filtersData)
  }

  const handleSelect = ({selectedRows}) => {
    props.selectedRowsHandler(selectedRows)
  }

  const actionsMemo = React.useMemo(()=> (
    <>
      {/* Refresh button */}
      <Button
        color="text-muted"
        outline
        className="hstack justify-content-center text-muted align-items-center fs-20 rotate"
        onClick={() => {
          if (loadingFinished.current) {
            getData(true)
          }
        }}
      >
        <i className="ri-refresh-line"></i>
      </Button>
    </>
  ), [])

  // Get data
  useEffect(() => {
    setLoading(true)
    getData()
  }, [page, perPage, filters])

  const getData = (withOverlay) => {
    if (withOverlay) setOverlay(true)

    loadingFinished.current = false

    api.get(props.url, {page: page, per_page: perPage, ...filters}).then(response => {
      setTableData(response.data)
      setTotalRows(response.total)
      setLoading(false)
    }).finally(() => {
      setLoading(false)
      setOverlay(false)
      loadingFinished.current = true
    })
  }

  useImperativeHandle(props.methodsRef, () => ({
    updateTable: () => {
      getData()
    }
  }))

  return (
    <React.Fragment>
      {props.filterFields?.length &&
        <Row>
          <Col className="col-12">
            <TableFilters filterFields={props.filterFields} filtersHandler={filtersHandler}/>
          </Col>
        </Row>
      }
      <Row className="mb-3">
        <Col className="col-12 dropdown_area">
          <DataTable
            className={classnames({'rdt_Table-overlay': overlay})}
            theme={layoutModeType === 'dark' ? 'dark' : 'default'}
            striped={true}
            dense={true}
            title={props.title}
            columns={props.columns}
            data={tableData}
            selectableRows
            onSelectedRowsChange={handleSelect}
            contextActions={props.actions || []}
            progressPending={loading}
            pagination
            paginationServer
            paginationRowsPerPageOptions={[25, 50, 100]}
            paginationPerPage={perPage}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: t('Rows per page:')
            }}
            progressComponent={<h1 className="py-4">{t('Loading...')}</h1>}
            noDataComponent={<div className="text-center py-4">{t("There are no records to display")}</div>}
            actions={actionsMemo}
          />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default BaseTable
