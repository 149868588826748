import React, {useRef} from 'react'
import {Col, Container, Row} from "reactstrap"
import BreadCrumb from "../../Components/Common/BreadCrumb"
import {AdvancedRealTimeChart} from "react-ts-tradingview-widgets"
import '../../assets/scss/TradingChartClass.css'
import {useSelector} from "react-redux"
import ExchangeCard from "./ExchangeCard"
import LastTransactions from "../../Widgets/LastTransactions"
import {useTranslation} from "react-i18next"

const Exchange = () => {
  const { t } = useTranslation()
  const {layoutModeType} = useSelector(state => ({
    layoutModeType: state.Layout.layoutModeType,
  }))

  const lastTransactionsMethods = useRef()

  const updateTransactions = () => {
    lastTransactionsMethods.current.updateTable()
  }

  document.title = t("Exchange") +  ' | ' + import.meta.env.VITE_APP_NAME

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Home" pageTitle="Exchange" />
          <Row>
            <Col className="col-xxl-5 col-lg-7 col-md-12 col-12">
	            <ExchangeCard height={'100%'} formSubmit={updateTransactions}/>
            </Col>
            <Col className="col-xxl-7 col-lg-5 col-md-12 col-12 mb-4">
              <div className="h-100">
                <AdvancedRealTimeChart
                  container_id={'tradingview_widget_wrapper'}
                  copyrightStyles={{parent: {display: 'none'}}}
                  width={'100%'}
                  height={'100%'}
                  theme={layoutModeType}
                  symbol="COINBASE:BTCUSD"
                />
              </div>
            </Col>
            <Col xs={12}>
              <LastTransactions limit={5} type={'exchange'} methodsRef={lastTransactionsMethods}/>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Exchange
