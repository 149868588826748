import React, {useEffect, useState} from 'react'
import {Card, CardHeader} from 'reactstrap'
import BankTransferForm from "./BankTransferForm"
import {useTranslation} from "react-i18next"

const BankTransferCard = ({ formSubmit }) => {
  const [activeTab, setActiveTab] = useState('1')
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576)
  const { t } = useTranslation()

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const formSent = () => {
    if (formSubmit) {
      formSubmit()
    }
  }

  useEffect(() => {
    const resize = () => {
      setIsMobile(window.innerWidth <= 576)
    }
    window.addEventListener('resize', resize)

    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="align-items-center border-0 d-flex flex-column flex-sm-row">
          <h4 className={`card-title mb-0 flex-grow-1 ${isMobile ? 'mb-3' : ''}`}>{t("Bank Transfer")}</h4>
          {/*<div className="flex-shrink-0 d-flex justify-content-end">
            <Nav className="nav justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0"
                 role="tablist">
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({active: activeTab === '1'})}
                  onClick={() => {toggleTab('1')}}
                >
                  Locked Balance
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({active: activeTab === '2'})}
                  onClick={() => {toggleTab('2')}}
                >
                  Available Balance
                </NavLink>
              </NavItem>
            </Nav>
          </div>*/}
        </CardHeader>
        <div className="card-body">
          <BankTransferForm formSentHandler={formSent} />
          {/*<TabContent activeTab={activeTab} className="p-0">
            <TabPane tabId="1">
              <BankTransferForm formSentHandler={formSent} />
            </TabPane>
            <TabPane tabId="2">
              <BankTransferForm formSentHandler={formSent} isReal={true}/>
            </TabPane>
          </TabContent>*/}
        </div>
      </Card>
    </React.Fragment>
  )
}

export default BankTransferCard
