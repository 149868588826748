import React from 'react'

if (import.meta.env.VITE_THEME_STYLE === 'modern') {
  import('./assets/scss/theme-modern.scss')
} else {
  import('./assets/scss/theme-default.scss')
}

//import Route
import Route from './Routes'

function App() {
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App
