import React, {useEffect} from 'react'
import {Card, CardBody, Col, Row} from 'reactstrap'
import {Swiper, SwiperSlide} from "swiper/react"
import {Autoplay, Mousewheel} from "swiper"
import {cryptoRates} from "../api/widget"
import moment from "moment"

import "swiper/css"
import "swiper/css/pagination"

import {WidgetsCharts} from '../pages/DashboardCrypto/DashboardCryptoCharts'
import btc from "../assets/images/svg/crypto-icons/btc.svg"
import eth from "../assets/images/svg/crypto-icons/eth.svg"
import usdt from "../assets/images/svg/crypto-icons/usdt.svg"
import xrp from "../assets/images/svg/crypto-icons/xrp.svg"
import doge from "../assets/images/svg/crypto-icons/doge.svg"
import ada from "../assets/images/svg/crypto-icons/ada.svg"
import ltc from "../assets/images/svg/crypto-icons/ltc.svg"

const currencyImg = {
  BTC: btc,
  ETH: eth,
  USDT: usdt,
  XRP: xrp,
  DOGE: doge,
  ADA: ada,
  LTC: ltc
}

const CryptoRates = () => {
  const [cryptoRatesInfo, setCryptoRatesInfo] = React.useState([])

  useEffect(() => {
    cryptoRates().then((r => {
      // TODO: needs to be reworked to a different exclusion method
      setCryptoRatesInfo(r.filter(i => i.currency.code !== 'USDT (ERC20)').map((asset) => {
        return {
          currency: asset.currency,
          price: asset.price,
          volume: asset.volume,
        }
      }))
    }))
  }, [])

  const generateSeries = code => {
    return cryptoRatesInfo.filter(i => i.currency.code === code).map((asset) => {
      return {
        name: asset.currency.code,
        data: asset?.volume['7d']?.map((item) => ({
          x: moment(item.date).format('LL'),
          y: Number(item.priceUsd).toFixed(4)
        }))
      }
    })
  }

  return (
    <React.Fragment>
      <Col className="col-xxl-12 col-xl-12 col-12">
        <Swiper
          slidesPerView={1}
          spaceBetween={24}
          mousewheel={true}
          autoplay={{
            delay: 30000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            570: {
              slidesPerView: 2,
            },
            880: {
              slidesPerView: 3,
            },
            1270: {
              slidesPerView: 3,
            },
            1370: {
              slidesPerView: 4,
            },
            1710: {
              slidesPerView: 5,
            }
          }}
          modules={[Autoplay, Mousewheel]}
          className="cryptoSlider">

          {(cryptoRatesInfo || []).map((item, idx) => (
            <SwiperSlide key={idx}>
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center">
                    <img src={currencyImg[item.currency.code]}
                         className="bg-light rounded-circle p-1 avatar-xs img-fluid" alt=""/>
                    <h6 className="ms-2 mb-0 fs-14">{item.currency.name}</h6>
                  </div>
                  <Row className="align-items-center g-0">
                    <Col xs={6}>
                      <h5 className="mb-1 mt-4">{'$' + Number(item.price).toFixed(4)}</h5>
                      <p className={"fs-13 fw-medium mb-0 text-" +
                        (item.volume["1d"].percentExchangeVolume < 0 ? 'danger' : 'success')}>
                        {Number(item.volume['1d'].percentExchangeVolume).toFixed(2) + '%'}
                        <span className="text-muted ms-2 fs-10 text-uppercase">
                          ({item.currency.code})
                        </span>
                      </p>
                    </Col>
                    <Col xs={6}>
                      <div className="apex-charts crypto-widget" dir="ltr">
                        <WidgetsCharts
                          seriesData={generateSeries(item.currency.code)}
                          chartsColor={item.volume['1d'].percentExchangeVolume < 0 ? '#f06548' : '#0ab39c'}/>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      </Col>
    </React.Fragment>
  )
}

export default CryptoRates
