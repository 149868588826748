
export default {
  "Subject field is required": "Поле \"Тема\" является обязательным",
  "Message field is required": "Поле \"Сообщение\" является обязательным",
  "Select a ticket from the existing ones": "Выберите тикет из существующих",
  "or create a new one": "или создайте новый",
  "Chats list": "Список чатов",
  "Create New Chat": "Создать новый чат",
  "Waiting": "Ожидает",
  "Open": "Открыт",
  "Closed": "Закрыт",
  "Answered": "Отвеченный",
  "Chat": "Чат",
  "Create new ticket": "Создать новый тикет",
  "Subject": "Тема",
  "Message": "Сообщение",
  "Enter your subject": "Введите тему",
  "Type your message...": "Введите ваше сообщение...",
  "Please place here full description of your issue.": "Пожалуйста, разместите здесь полное описание вашей проблемы.",
  "Create ticket": "Создать тикет",
  "Close ticket": "Закрыть тикет",
  "Support Team": "Служба поддержки",
  "Opened": "Открыт",
  "Copy": "Скопировать"
}
