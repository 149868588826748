import {Button, Card, Col, Form, Row} from "reactstrap";
import TextInput from "./FilterFields/TextInput";
import SelectInput from "./FilterFields/SelectInput";
import {useState} from "react";

const TableFilters = (props) => {
  const [selectedData, setSelectedData] = useState({});

  const selectedDataHandler = (data) => {
    setSelectedData((prevState) => {
      if (data.selectName) {
        if (!prevState) prevState = {};
        prevState[data.selectName] = data.values;

        return prevState;
      } else {
        return prevState;
      }
    });
  }

  return (
    <>
      <Card className="p-3">
        <h4>Filters</h4>
        <Form
          onSubmit={(e) => {
            e.preventDefault();

            let formData = new FormData(e.target);
            let filtersObj = {};

            formData.forEach((value, key) => filtersObj[key] = value);
            for (const [selectName, values] of Object.entries(selectedData)) {
              if(values) {
                if(Array.isArray(values) && values.length) {
                  filtersObj[selectName] = values;
                } else {
                  filtersObj[selectName] = values;
                }
              }
            }

            props.filtersHandler(filtersObj);

            return false;
          }}
          action="#"
        >
          <Row className="g-3 align-items-center">

            {props.filterFields.map((filter, index) => {
              if (filter.type === 'text') {
                return <TextInput key={index} {...filter} />;
              } else if (filter.type === 'select') {
                return <SelectInput key={index} {...filter} selectedDataHandler={selectedDataHandler}/>;
              }
            })}

            <Col className="col-12 col-sm-12 col-md-12 col-xs-12 col-lg-12">
              <Button className="btn-lg btn-info me-2" type="reset">
                Reset
              </Button>
              <Button className="btn-lg" type="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
}

export default TableFilters;
