
export default {
  // Crypto
  "Withdraw Crypto": "Вывод Криптовалюты",
  "Available_w": "Доступно",
  "Remaining Daily Withdrawal Amount": "Оставшаяся суточная сумма снятия",
  "Unlimited": " Безлимитный",
  "Select currency . . . ": "Выберите валюту . . . ",
  "Fee": "Комиссия",
  "Withdrawal Fee": "Комиссия за вывод",
  "Estimated time": "Примерное время",
  "Withdrawal Amount": "Сумма вывода",
  "Paying from Available Balance": "Оплата из доступного баланса",
  "Total Amount": "Общая сумма",
  "Withdraw Address": "Адресс вывода",
  "WITHDRAW": "ВЫВОД",
  "You receive": "Вы получите",
  "Network is busy. Gas prices are high and estimates are less accurate.": "Большая нагрузка на сеть. " +
    "Сетевые сборы могут быть увеличены.",
  "Low": "Низкая",
  "Economy": "Эконом",
  "Normal": "Обычная",
  "High": "Высокая",
  "Your balance exceeds the limit for this type of fee.": "Ваш баланс превышает лимит для этого типа комиссии.",
  "Please select currency": "Пожалуйста, выберите валюту",
  "Please enter an amount": "Пожалуйста, введите сумму",
  "Please enter an withdrawal address": "Пожалуйста, введите адрес для вывода средств",

  // Bank transfer
  "Enter payment information": "Введите платежную информацию",
  "Contacts": "Контакты",
  "Contact": "Контакты",
  "Account holder": "Владелец счета",
  "Bank": "Банк",
  "Account number": "Номер счета",
  "Swift code": "Свифт-код",
  "Payment reference number": "Номер платежного поручения",
  "Select country": "Выберите страну",
  "NEW BANK TRANSFER": "НОВЫЙ БАНКОВСКИЙ ПЕРЕВОД",
  "You request for a bank transfer has been submitted, status \"Pending\".": "Ваш запрос на банковский перевод " +
    "был отправлен, статус \"В ожидании\".",
  "If will be necessary, we will contact you.": "Если будет необходимо, мы свяжемся с вами.",
  "Minimum withdrawal amount is $": "Минимальная сумма вывода составляет",
  "Please select a country": "Пожалуйста, укажите страну",
  "Please select a canton": "Пожалуйста, укажите область",
  "Please select an address": "Пожалуйста, укажите адрес",
  "Please select a postal code": "Пожалуйста, укажите почтовый индекс",
  "Please select a city": "Пожалуйста, укажите город",
  "Please select a phone": "Пожалуйста, укажите номер телефона",
  "Please enter account holder": "Пожалуйста, введите владельца счета",
  "Please enter bank": "Пожалуйста, введите банк",
  "Please enter city": "Пожалуйста, введите город",
  "Please enter account number": "Пожалуйста, введите номер счета",
  "Please enter iban": "Пожалуйста, введите IBAN",
  "Please enter swift code": "Пожалуйста, введите свифт-код",
  "5 days": "5 дней",
  "1 day": "1 день",
  "24 hours": "24 часа",
  "6 hours": "6 часов",
  "2 hours": "2 часа",
  "30 min": "30 минут"
}
