import React, {useEffect, useState} from 'react'
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  Table,
  UncontrolledButtonDropdown,
} from "reactstrap"
import BreadCrumb from "../../Components/Common/BreadCrumb"
import {Link, useNavigate, useParams} from "react-router-dom"
import {balancePay, cryptoPay, getInvoice} from "../../api/invoice"
import {prettyDate, toDateFormat} from "../../helpers/utils"
import moment from "moment"
import {getProfile} from "../../api/account"
import {toast} from "react-toastify"
import classnames from "classnames"
import {getBrands} from "../../api/brand"
import {useDispatch} from "react-redux"
import {getAccountBalance} from "../../slices/auth/profile/thunk"
import {useTranslation} from "react-i18next"

const InvoiceDetails = () => {
  const dispatch = useDispatch()
  const navigator = useNavigate()
  const {id} = useParams()
  const { t } = useTranslation()
  const [invoiceData, setInvoiceData] = useState({})
  const [profileData, setProfileData] = useState({})
  const [brand, setBrand] = useState([])

  const dueDate = toDateFormat(invoiceData.due_date)
  const invoiceDate = toDateFormat(invoiceData.invoicing_date)

  const [invoiceLogo, setInvoiceLogo] = useState('')
  const dueDateFormatted = moment(dueDate, 'YYYY-MM-DD');
  const invoiceDateFormatted = moment(invoiceDate, 'YYYY-MM-DD');
  const termsDays = dueDateFormatted.diff(invoiceDateFormatted, 'days')

  const getInvoiceData = () => {
    getInvoice(id).then((r) => {
      if (Object.keys(r).length) {
        setInvoiceData(r)
        getProfileData(r.customer_id)
      } else {
        navigator('/invoices')
      }
    })
  }

  const getBrandData = () => {
    getBrands().then(r => {
      setBrand(r.find(i => String(invoiceData.mail_from) === String(i.id)))
    })
  }

  const getProfileData = (id) => {
    getProfile(id || invoiceData.customer_id).then((r) => {
      setProfileData(r)
    })
  }

  const cryptoPayHandler = (id) => {
    if (confirm(t('Do you want to pay by crypto?'))) {
      cryptoPay(id).then(r => {
        let message = r?.message || 'Empty response message.'

        if (r.success) {
          toast.success(r.message)
          if (r.redirect) {
            navigator('/deposit/show/' + r.transaction_id)
          }
          navigator('/deposit/show/' + r.transaction_id)
        } else {
          toast.error(message)
        }
      })
    }
  }

  const balancePayHandler = (id) => {
    if (confirm(t('Do you want to pay from your balance?'))) {
      balancePay(id).then(r => {
        let message = r?.message || 'Empty response message.'

        if (r.success) {
          toast.success(r.message)
          getInvoiceData()
          dispatch(getAccountBalance())
        } else {
          toast.error(message)
        }
      })
    }
  }

  const printInvoice = () => {
    window.print()
  }

  useEffect(() => {
    getInvoiceData()
  }, [])

  useEffect(() => {
    getBrandData()

    if (invoiceData.mail_from === "custom" && invoiceData.meta.custom_from) {
      setInvoiceLogo(import.meta.env.VITE_REMOTE_STORAGE + invoiceData.meta.custom_from.logo)
    }
  }, [invoiceData])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Crypto" pageTitle="Invoice"/>
          <Row className="d-flex justify-content-center">
            <Col md={12} lg={10} xl={8} xxl={6}>
              <Card>
                <CardBody>
                  <Row className="row d-flex justify-content-between mt-3 mb-2">
                    <Col
                      xs={12} sm={6} md={6} lg={6}
                      className="flex-column align-items-center mb-3"
                    >
                      {brand ?
                        <div className="d-flex flex-column">
                          <img
                            src={import.meta.env.VITE_REMOTE_STORAGE + brand?.logo_path}
                            alt={brand?.title}
                            width="190"
                            className="img-fluid pb-1"
                          />
                          <h3>{brand?.title}</h3>
                          <p className="fs-16 text-muted mb-0">
                            {brand?.address}
                          </p>
                        </div> :
                        invoiceData.mail_from === "custom" ? (
                          <div className="mb-3">
                            <img
                              src={invoiceLogo}
                              alt="brand_logo"
                              width="200"
                              className="img-fluid pt-2 pb-1"
                            />
                            <h5>{invoiceData.meta.custom_from.custom_name}</h5>
                            <p className="fs-16 text-muted mb-1">
                              {invoiceData.meta.custom_from.custom_address &&
                                invoiceData.meta.custom_from.custom_address.split(/\r?\n/)
                                  .map((string, idx) => (
                                    <React.Fragment key={idx}>
                                      <span>{string}</span> <br/>
                                    </React.Fragment>
                                  ))
                              }
                            </p>
                            <p className="fs-16 text-muted">
                              {invoiceData.meta.custom_from.custom_info}
                            </p>
                          </div>
                          ) :
                          (
                            <div className="d-flex justify-content-center">
                              <Spinner className="flex-shrink-0"></Spinner>
                            </div>
                          )
                      }

                      <div>
                        <h5 className="mb-1">{t("Bill to")}:</h5>
                        <p className="fs-16">{profileData?.name || 'Loading...'}</p>
                      </div>
                    </Col>
                    <Col
                      xs={12} sm={6} md={6} lg={6}
                      className="d-flex flex-column"
                    >
                      <Table responsive className="mt-3 fs-14">
                        <tbody>
                        <tr className="border-top">
                          <td><strong>{t("Invoice ID")}:</strong></td>
                          <td className="text-end">{invoiceData.uniq_id}</td>
                        </tr>
                        <tr>
                          <td><strong>{t("Invoicing Date")}:</strong></td>
                          <td className="text-end">{prettyDate(invoiceDate, false)}</td>
                        </tr>
                        <tr>
                          <td><strong>{t("Due Date")}:</strong></td>
                          <td className="text-end">{prettyDate(dueDate, false)}</td>
                        </tr>
                        <tr>
                          <td><strong>{t("Terms")}:</strong></td>
                          <td className="text-end">
                            {termsDays === 1 ? `${termsDays} ${t('day')}` : `${termsDays} ${t('days')}`}
                          </td>
                        </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>

                  <Row className="d-flex justify-content-end">
                    <Col xs={12} className="mb-3">
                      <Table responsive>
                        <thead>
                        <tr className="table-active border-bottom">
                          <th className="col-6">{t("ITEM")}</th>
                          <th className="col-2 text-center">{t("QUANTITY")}</th>
                          <th className="col-2 text-center">{t("RATE")}</th>
                          <th className="col-2 text-center">{t("AMOUNT")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {(invoiceData.items || []).map((row, idx) => (
                          <tr key={idx} className="border-bottom">
                            <td className="fw-medium">{row.product_description}</td>
                            <td className="text-center">{row.quantity}</td>
                            <td className="text-center">
                              {Number(row.rate).toFixed(2)} {invoiceData.formatted_code}
                            </td>
                            <td className="text-center table-active">
                              {Number(row.amount).toFixed(2)} {invoiceData.formatted_code}
                            </td>
                          </tr>
                        ))}
                        </tbody>
                      </Table>
                    </Col>
                    <Col xs={12} sm={6} md={5} lg={4} className="mb-3">
                      <Table>
                        <tbody>
                        <tr className="fw-medium">
                          <td>{t("Sub Total")}:</td>
                          <td className="text-end">
                            {Number(invoiceData.subtotal_amount).toFixed(2)} {invoiceData.formatted_code}
                          </td>
                        </tr>
                        <tr>
                          <td>{t("Discount")}:</td>
                          <td className="text-end">{invoiceData.discount ? invoiceData.discount : '0'}%</td>
                        </tr>
                        <tr>
                          <td>{t("Tax")}:</td>
                          <td className="text-end">{invoiceData.tax ? invoiceData.tax : '0'}%</td>
                        </tr>
                        <tr className="table-active">
                          <td className="fw-bold">{t("Total")}:</td>
                          <td className="text-end fw-bold">
                            {Number(invoiceData.total_amount).toFixed(2)} {invoiceData.formatted_code}
                          </td>
                        </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>

                  <Row className="mb-3 d-print-none text-end">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-end">
                        <Button color="primary" className="btn" onClick={() => navigator(-1)}>
                          {t("Back")}
                        </Button>
                      </div>
                      <div className="d-flex flex-column">
                        <div className={classnames('mb-3', {'d-none' : invoiceData.status === 'paid'})}>
                          <ButtonGroup>
                            <Button
                              color="primary"
                              onClick={() => cryptoPayHandler(invoiceData.id)}
                            >
                              {t("Top Up")}
                            </Button>
                            <UncontrolledButtonDropdown id="btnGroupDrop1">
                              <DropdownToggle color="primary" caret>
                              </DropdownToggle>
                              <DropdownMenu end>
                                <DropdownItem onClick={() => balancePayHandler(invoiceData.id)}>
                                  {t("Pay from balance")}
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          </ButtonGroup>
                        </div>
                        <div>
                          <Link to="#" onClick={printInvoice} className="btn btn-warning">
                            <i className="ri-printer-line align-bottom me-1"></i>
                            {t("Print")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default InvoiceDetails
