
export default {
  // Sign Up
  "Create Your Account": "Создайте свой аккаунт",
  "First Name": "Имя",
  "Last Name": "Фамилия",
  "Password": "Пароль",
  "Confirm password": "Подтвердите пароль",
  "Create an account": "Создать аккаунт",
  "Already have an account": "Уже есть аккаунт",
  "Sign In": "Войти",
  "Easy crypto trading for everyone": "Простая торговля криптовалютами для всех",
  "Successful traders use": "Успешные трейдеры используют",
  "as a simple, easy, and secure platform to buy and sell Bitcoin, Ethereum and dozens of other digital assets instantly": " как простая, " +
    "удобная и безопасная платформа для мгновенной покупки и продажи Bitcoin, Ethereum и дюжины других цифровых активов",
  "Join us today! Sign up for a new account and explore a realm of exciting opportunities": "Присоединяйтесь к нам " +
    "сегодня! Зарегистрируйте новую учетную запись и откройте для себя множество захватывающих возможностей",
  "Enter first name": "Введите имя",
  "Enter last name": "Введите фамилию",
  "Enter email address": "Введите адрес электронной почты",
  "Enter password": "Введите пароль",
  "Name is too short": "Имя слишком короткое",
  "Name is too long": "Имя слишком длинное",
  "This field is required": "Это поле обязательно для заполнения",
  "Password must be at least 8 characters": "Пароль должен состоять не менее чем из 8 символов",
  "At least lowercase letter": "Не менее одной прописной буквы",
  "At least uppercase letter": "Не менее заглавная буква буквы",
  "At least one number": "Не менее одной цифры",
  "Second name is too short": "Фамилия слишком короткая",
  "Second name is too long": "Фамилия слишком длинная",
  "Passwords do not match": "Пароли не совпадают",
  "The email must be a valid email address.": "Электронная почта должна быть действительным адресом электронной почты.",
  "CAPTCHA field is required.": "Поле CAPTCHA является обязательным.",
  "Invalid CAPTCHA. You need to prove you are human.": "Неверная CAPTCHA. Вам нужно доказать, что вы человек.",
  "Sign Up": "Регистрация",
  "Your account successfully registered, check your email for further instructions.": "Ваша учетная запись " +
    "успешно зарегистрирована, проверьте свою электронную почту для получения дальнейших инструкций.",
  "Go to Sign In": "Перейти на страницу входа",

  // Sign In
  "Welcome Back!": "С возвращением!",
  "Sign in to": "Войдите в",
  "Register now": "Зарегистрироваться сейчас",
  "Email": "Почта",
  "Enter email": "Введите e-mail",
  "Enter Password": "Введите пароль",
  "Remember me": "Запомнить меня",
  "Don't have an account ?": "У вас нет аккаунта ?",
  "Please Enter Your Email": "Пожалуйста, введите ваш e-mail",
  "Please Enter Your Password": "Пожалуйста, введите пароль",

  //Pending Page
  "Thank you for registration!": "Благодарим вас за регистрацию!",
  "Soon your account will be activated": "Вскоре ваш аккаунт будет активирован",
  "Logout": "Выход",

  //2fa
  "Lock Screen": "Экран блокировки",
  "Enter your 2FA code to unlock the screen!": "Введите свой код 2FA, чтобы разблокировать экран!",
  "2FA code": "Код 2FA",
  "Enter code": "Введите код",
  "Unlock": "Разблокировать",
  "Invalid credentials": "Неверные учетные данные"
}

