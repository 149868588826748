import {setAccountBalance, setProfile} from "./reducer"
import {getBalance, getProfileInfo as getProfileInfoData} from "../../../api/account"

export const getProfileInfo = () => async (dispatch) => {
  try {
    let response

    response = await getProfileInfoData()

    if (response) {
      const data = JSON.parse(localStorage.getItem('authUser'))

      dispatch(setProfile(response))
      data.user = response?.user || {}
      localStorage.setItem('authUser', JSON.stringify(data))
    }
  } catch (error) {
    console.log('getProfileInfo', error)
  }
}

export const getAccountBalance = () => async (dispatch) => {
  try {
    let response

    response = await getBalance()

    if (response) {
      dispatch(setAccountBalance(response))
    }
  } catch (error) {
    console.log('getProfileInfo', error)
  }
}
