import React, {useEffect} from "react";
import {Navigate, Route, useNavigate} from "react-router-dom";
import {setAuthorization} from "../helpers/api_helper";
import {useDispatch, useSelector} from "react-redux";

import {useProfile} from "../Components/Hooks/UserHooks";

import {logoutUser} from "../slices/auth/login/thunk";

import {createEchoInstance} from '../ws'
import {getNotifications} from "../slices/notification/thunk";
import {setNotifications} from "../slices/notification/reducer";
import {getAccountBalance, getProfileInfo} from "../slices/auth/profile/thunk";
import {getTicketsData} from "../slices/tickets/thunks";
import {changeLanguage} from "../api/account"

let echoEventsConfState = false
let isLanguageDetected = false

function echoEventsConf(userId) {
  if (echoEventsConfState) return

  let dispatch = useDispatch()

  const echo = createEchoInstance()

  echo.private(`notification.${userId}`)
    .listen('UserNotification', () => {
      getNotifications().then(r => dispatch(setNotifications(r)))
    })
    .listen('VerificationStatus', () => {
      getNotifications().then(r => dispatch(setNotifications(r)))
    })
    .listen('UserPlanChanged', () => {
      dispatch(getProfileInfo())
    })
    .listen('BalanceChanged', () => {
      dispatch(getAccountBalance())
    })
    .listen('TicketUpdated', () => {
      dispatch(getTicketsData())
    })

  echoEventsConfState = true
}

const AuthProtected = (props) => {
  const dispatch = useDispatch()
  const {userProfile, loading, token} = useProfile()
  const twoFactorAuthRequired = useSelector(s => s.Profile.twoFactorAuthRequired)
  const userInfo = useSelector(s => s.Profile.user)
  const navigate = useNavigate()

  useEffect(() => {
    const isAuthenticated = (userProfile && !loading && token)
    if (isAuthenticated && (userInfo?.profile?.verification_status === 'pending')) {
      setAuthorization(token)
      navigate('/pending-page')
    } else {
      if ((window.location.pathname === '/pending-page') &&
        (isAuthenticated && (userInfo?.profile?.verification_status !== 'pending'))
      ) {
        navigate('/dashboard')
      }
    }

    if (isAuthenticated) {
      setAuthorization(token)
    } else if ((!userProfile && loading && !token) ||
      !Object.keys(userInfo).length ||
      userInfo?.profile?.verification_status === 'banned' ||
      !userInfo
    ) {
      dispatch(logoutUser())
      navigate('/login')
    }

    let lang = localStorage.getItem('I18N_LANGUAGE')
    let userLanguage = userInfo?.profile?.settings?.language

    if ((token && userInfo && !userLanguage) ||
      (userLanguage && userLanguage !== lang)) {

      if (userLanguage && userLanguage !== lang) {
        isLanguageDetected = false
      }

      if (isLanguageDetected) return

      changeLanguage({ selected_language: lang })
        .then(r => {

          if (r.success) {
            dispatch(getProfileInfo())
          } else {
            console.error(r.message)
          }
        })
        .catch(error => {
          console.error(error)
        })

      isLanguageDetected = true
    }

  }, [token, userProfile, loading, dispatch, userInfo])
  /*
    Navigate is un-auth access protected routes via url
    */

  if (!userProfile && loading && !token) {
    return (
      <Navigate to={{pathname: "/login", state: {from: props.location}}}/>
    )
  }

  if (twoFactorAuthRequired) {
    return (
      <Navigate to={{pathname: "/2fa"}}/>
    )
  }

  if (userProfile?.id) {
    echoEventsConf(userProfile.id)
  }

  return <>{props.children}</>
};

const AccessRoute = ({component: Component, ...rest}) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (<> <Component {...props} /> </>)
      }}
    />
  );
};

export {AuthProtected, AccessRoute}
