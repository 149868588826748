import React, {useEffect, useState} from 'react'
import {useSelector} from "react-redux"
import {Col, Container, Row} from 'reactstrap'
import {useTranslation} from "react-i18next"

import BreadCrumb from '../../Components/Common/BreadCrumb'

//Widgets
import {AdvancedRealTimeChart} from "react-ts-tradingview-widgets"
import '../../assets/scss/TradingChartClass.css'
import LastTransactions from "../../Widgets/LastTransactions"
import CryptoRates from "../../Widgets/CryptoRates"
import AccountBalance from "../../Widgets/AccountBalance"

const DashboardCrypto = () => {
  const { t } = useTranslation()
  const {layoutModeType} = useSelector(state => ({
    layoutModeType: state.Layout.layoutModeType,
  }))

  const [chartKey, setChartKey] = useState(0);

  useEffect(() => {
    setChartKey(prevKey => prevKey + 1)
  }, [layoutModeType])

  document.title = t('Dashboard') + ' | ' + import.meta.env.VITE_APP_NAME
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Home" pageTitle="Dashboard"/>
          <Row>
            <Col>
              <CryptoRates />
            </Col>
          </Row>
          <Row>
            <Col className="col-xxl-3 col-xl-4 col-lg-5 col-md-12 col-sm-12 col-12">
              <AccountBalance />
            </Col>
            <Col className="col-xxl-9 col-xl-8 col-lg-7 col-md-12 col-sm-12 col-12 mb-4">
              <div className="h-100">
                <AdvancedRealTimeChart
                  key={chartKey}
                  container_id={'tradingview_widget_wrapper'}
                  copyrightStyles={{parent: {display: 'none'}}}
                  width={'100%'}
                  height={'100%'}
                  theme={layoutModeType}
                  symbol="COINBASE:BTCUSD"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <LastTransactions limit={5}/>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DashboardCrypto
