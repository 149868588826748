import {combineReducers} from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
// Account
import AccountReducer from "./account/reducer";
// Profile
import ProfileReducer from './auth/profile/reducer'
// Notification
import NotificationReducer from './notification/reducer'
// Tickets
import TicketsReducer from './tickets/reducer'

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,
  Account: AccountReducer,
  Profile: ProfileReducer,
  Notification: NotificationReducer,
  Tickets: TicketsReducer,
});

export default rootReducer;
