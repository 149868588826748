import {Col, Container, Row} from "reactstrap"
import BreadCrumb from "../../../Components/Common/BreadCrumb"
import LastTransactions from "../../../Widgets/LastTransactions"
import React, {useRef} from "react"
import BankTransferCard from "./BankTransferCard"
import {useTranslation} from "react-i18next"

const BankTransfer = () => {
  const lastTransactionsMethods = useRef()
  const { t } = useTranslation()

  const updateTransactions = () => {
    lastTransactionsMethods.current.updateTable()
  }

  document.title = t("Bank Transfer") + ' | ' + import.meta.env.VITE_APP_NAME

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Home" pageTitle="Bank Transfer"/>
          <Row className="d-flex justify-content-center">
            <Col xl={8}>
              <BankTransferCard formSubmit={updateTransactions}/>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <LastTransactions
                limit={5} type={'deduction'}
                currencyType={'fiat'}
                methodsRef={lastTransactionsMethods}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BankTransfer
