import {Col, Input, Label} from "reactstrap";

const TextInput = (props) => {
  return (
    <Col className="col-12 col-sm-6 col-md-6 col-xs-4 col-lg-3">
      <div className="form-floating">
        <Input
          type="text"
          className="form-control"
          placeholder={props.title}
          name={props.name}
        />
        <Label>{props.title}</Label>
      </div>
    </Col>
  )
};

export default TextInput;
