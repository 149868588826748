import {APIClient} from "../helpers/api_helper"

const api = new APIClient()

export function selectListData(name) {
	return api.get('/spa/select-list-data', {name: name})
}

export function staticData(name, args) {
  let data = args ? {name: name, args: args} : {name: name}

	return api.post('/spa/static-data', data)
}
