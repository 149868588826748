import {getTickets} from "../../api/support"
import {setTickets} from "./reducer"
import {toast} from "react-toastify"

export const getTicketsData = () => async (dispatch) => {
  try {
    let response

    response = await getTickets()

    if (response) {
      dispatch(setTickets(response))
    }
  } catch (error) {
    toast.error(error.message)
  }
}
