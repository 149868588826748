import React, {useEffect, useRef, useState} from 'react'
import BreadCrumb from "../../Components/Common/BreadCrumb"
import {Col, Container, Row, Table, Badge, Card, CardBody} from "reactstrap"
import {Link, useParams} from "react-router-dom"
import {getDeposit} from "../../api/deposit"
import QRCode from "react-qr-code";
import {copyToClipboard, isTwoPrecision, toTwoPrecision} from "../../helpers/utils";
import LastTransactions from "../../Widgets/LastTransactions";
import {useTranslation} from "react-i18next"

const DepositDetail = () => {
  const {id} = useParams()
  const [assetCode, setAssetCode] = useState('')
  const [assetAddress, setAssetAddress] = useState('')
  const [assetAmount, setAssetAmount] = useState('')
  const [usdAmount, setUsdAmount] = useState('')
  const [status, setStatus] = useState('')
  const [statusColor, setStatusColor] = useState('')
  const lastTransactionsMethods = useRef()
  const { t } = useTranslation()

  useEffect(() => {
    getDeposit(id).then(r => {
      setAssetCode(r.code)
      setAssetAddress(r.address_to)
      setAssetAmount(r.amount)
      setUsdAmount(r.usd_amount)
      setStatus(r.status.capitalize())
      setStatusColor(r.status_color)
    })
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Cryto Deposit" pageTitle="Deposit details"/>
            <Card className="p-3">
              <CardBody className="d-flex">
                <Row className="flex-grow-1 overflow-auto">
                    <Col xs={12} sm={12} md={8}>
                      <h2 className="mb-4">{t("Deposit details")}</h2>
                      {assetAddress ?
                      <Table responsive className="table-striped border-top-groove">
                        <tbody>
                        <tr>
                          <th className="w-25 text-nowrap text-uppercase"><span>{assetCode}</span> {t("Address")}:</th>
                          <th className="fw-bold d-flex justify-content-between">
                            <span>{assetAddress}</span>
                            <div>
                            <span>
                              <Link
                                to="#"
                                className="btn copy-address py-0"
                                title="Copy"
                                onClick={() => copyToClipboard(assetAddress)}
                              >
                                <i className="ri-file-copy-2-line"></i>
                              </Link>
                            </span>
                            </div>
                          </th>
                        </tr>
                        <tr>
                          <th className="w-25 text-nowrap text-uppercase">{t("Amount in asset")}:</th>
                          <th className="text-right fw-bold">{isTwoPrecision(assetCode, assetAmount)}</th>
                        </tr>
                        <tr>
                          <th className="w-25 text-nowrap text-uppercase">{t("Amount in USD")}:</th>
                          <th className="text-right fw-bold">{toTwoPrecision(usdAmount)}</th>
                        </tr>
                        <tr>
                          <th className="w-25 text-uppercase">{t("Status")}:</th>
                          <th className="text-right fw-bold">
                            <Badge className={'badge bg-' + statusColor}>{t(`${status}`)}</Badge>
                          </th>
                        </tr>
                        </tbody>
                      </Table>
                        :
                        <div className="d-flex align-items-center justify-content-center">
                          <span className="spinner-border flex-shrink-0" role="status"></span>
                        </div>
                      }
                    </Col>
                  <Col xs={12} sm={12} md={4} className="d-flex flex-column align-items-center ">
                    <h2 className="mb-4 text-center"><span>{assetCode}</span> {t("Address")}</h2>
                    <div style={{ height: "auto", margin: "0 auto", maxWidth: 200, width: "100%" }}>
                      {assetAddress &&
                        <QRCode
                          size={256}
                          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                          value={assetAddress}
                          viewBox={`0 0 256 256`}
                        />
                      }
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          <Row>
            <Col xs={12}>
              <LastTransactions limit={5} type={'acquisition'} methodsRef={lastTransactionsMethods}/>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DepositDetail
