import React from "react"
import {Col} from "reactstrap"
import {Carousel} from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import {Link} from "react-router-dom"
import {useTranslation} from "react-i18next"

// Import Images
const logoHeight = import.meta.env.VITE_THEME_LOGO_HEIGHT || 70
const logoSmHeight = import.meta.env.VITE_THEME_LOGO_HEIGHT_SM || 30
const brandLogosUrl = import.meta.env.VITE_APP_URL + '/storage/brand_logos/'
const logoLightUrl = brandLogosUrl + 'logo-light.png'
const logoLightSmUrl = brandLogosUrl + 'logo-light-sm.png'
const logoDarkUrl = brandLogosUrl + 'logo-dark.png'
const logoDarkSmUrl = brandLogosUrl + 'logo-dark-sm.png'
import ImageBG from "../../assets/images/bg-image.png"

const AuthSlider = () => {
  const { t } = useTranslation()

  return (
    <React.Fragment>

      <Col lg={6}>
        <div className="p-lg-5 p-4 auth-one-bg h-100">
          <img src={ImageBG} className="bg-register" alt=""/>
          <div className="bg-overlay"></div>
          <div className="position-absolut h-100 d-flex flex-column">
            <div className="mb-4">
              <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                      <img src={logoDarkSmUrl} alt="" height={logoSmHeight}/>
                  </span>
                <span className="logo-lg">
                    <img src={logoDarkUrl} alt="" height={logoHeight}/>
                  </span>
              </Link>

              <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoLightSmUrl} alt="" height={logoSmHeight}/>
                  </span>
                <span className="logo-lg">
                    <img src={logoLightUrl} alt="" height={logoHeight}/>
                  </span>
              </Link>
            </div>
            <div className="mt-auto">
              <div className="mb-3">
                <i className="ri-double-quotes-l display-4 text-success"></i>
              </div>

              <Carousel
                interval={10000}
                showThumbs={false}
                autoPlay={true}
                showArrows={false}
                showStatus={false}
                infiniteLoop={true}
                className="carousel slide"
                id="qoutescarouselIndicators"
              >
                <div className="carousel-inner text-center text-white-50 pb-5">
                  <div className="item">
                    <p className="fs-15 fst-italic">" {t("Easy crypto trading for everyone")} "</p>
                  </div>
                </div>
                <div className="carousel-inner text-center text-white-50 pb-5">
                  <div className="item">
                    <p className="fs-15 fst-italic">" {t("Successful traders use")} {import.meta.env.VITE_APP_NAME}
                      {t("as a simple, easy, and secure platform to buy and sell Bitcoin, Ethereum and dozens of other digital assets instantly")} "</p>
                  </div>
                </div>
                <div className="carousel-inner text-center text-white-50 pb-5">
                  <div className="item">
                    <p className="fs-15 fst-italic">" {t("Join us today! Sign up for a new account and explore a realm of exciting opportunities")} "</p>
                  </div>
                </div>
              </Carousel>

            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default AuthSlider;
