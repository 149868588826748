import React, {useContext, useEffect, useState} from "react"
import {getDocuments} from "../../api/account"
import UploadDocument from "./Modals/UploadDocument"
import {ModalContext} from "../../contexts"
import {Button, Col, Row, Table} from "reactstrap"
import {Link} from "react-router-dom"
import classnames from "classnames"
import {prettyDate} from "../../helpers/utils"
import {useTranslation} from "react-i18next"

const Documents = ({activeTab}) => {
	const {openModal} = useContext(ModalContext)
	const [documents, setDocuments] = useState([])
  const { t } = useTranslation()

	const getDocumentsData = () => {
		getDocuments().then(r => {
			setDocuments(r)
		})
	}

	const uploadFileHandler = () => {
		openModal({
			title: t('Upload document'),
			content: <UploadDocument updateTable={getDocumentsData} />
		})
	}

	useEffect(() => {
		if (activeTab === 'documents') {
			getDocumentsData()
		}
	}, [activeTab])

	return (
		<>
			<div className="d-flex align-items-center mb-4">
				<h5 className="card-title flex-grow-1 mb-0">{t("Documents")}</h5>
				<div className="flex-shrink-0">
					<Button className="btn btn-danger" onClick={uploadFileHandler}>
						<i className="ri-upload-2-fill me-1 align-bottom"></i>
            {t("Upload File")}
					</Button>
				</div>
			</div>
			<Row>
				<Col xs={12}>
					<div className="table-responsive">
						<Table className="table-borderless align-middle mb-0">
							<thead className="table-light">
							<tr>
								<th scope="col">{t("File Name")}</th>
								<th scope="col">{t("Type")}</th>
								<th scope="col">{t("Status")}</th>
								<th scope="col">{t("Upload Date")}</th>
							</tr>
							</thead>
							<tbody>
							{documents.length ?
								documents.map((document, key) => (
									<tr key={key}>
										<td>
											<Link to={document.img_url} target='_blank'>
												{document.meta?.file_name || 'No file name'}
											</Link>
										</td>
										<td>{document.document_type.type}</td>
										<td>
												<span
													className={classnames('badge', {
														'bg-info': document.status === 'not verified',
														'bg-danger': document.status === 'declined',
														'bg-success': document.status === 'verified',
													})}
												>
													{t(document.status.capitalize())}
												</span>
										</td>
										<td>{prettyDate(document.created_at)}</td>
									</tr>
								)) :
								<tr>
									<td colSpan={5} className='text-center'>{t("No data")}</td>
								</tr>
							}
							</tbody>
						</Table>
					</div>
				</Col>
			</Row>
		</>
	)
}

export default Documents
