import {Navigate} from "react-router-dom"

const DirectLogin = () => {
  localStorage.setItem('login_as', 1)

  return (
    <Navigate to={{pathname: '/login'}} />
  )
}

export default DirectLogin
