
export default {
  "Bill to": "Выставлен",
  "ITEM": "ПУНКТ",
  "QUANTITY": "КОЛИЧЕСТВО",
  "RATE": "СТАВКА",
  "AMOUNT": "СУММА",
  "Sub Total": "Суммарно",
  "Terms": "Сроки",
  "Discount": "Скидка",
  "Tax": "Налог",
  "Back": "Назад",
  "Print": "Печать",
  "Invoice": "Счет",
  "days": "дня(дней)",
  "day": "день",
  "Do you want to pay by crypto?": "Вы хотите платить криптовалютой?",
  "Do you want to pay from your balance?": "Вы хотите оплатить с баланса?"
}
