import React, {useEffect, useState} from 'react'
import {Card, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap'
import classnames from 'classnames'
import WithdrawalCryptoForm from "./WithdrawalCryptoForm"
import {useTranslation} from "react-i18next"

const WithdrawalCryptoCard = ({ formSubmit }) => {
  const [activeTab, setActiveTab] = useState('1')
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576)
  const { t } = useTranslation()

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const formSent = () => {
    if (formSubmit) {
      formSubmit()
    }
  }

  useEffect(() => {
    const resize = () => {
      setIsMobile(window.innerWidth <= 576)
    }
    window.addEventListener('resize', resize)

    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="align-items-center border-0 d-flex flex-column flex-sm-row">
          <h4 className={`card-title mb-0 flex-grow-1 ${isMobile ? 'mb-3' : ''}`}>{t("Withdraw Crypto")}</h4>
          <div className="d-flex justify-content-end">
            <Nav className="nav justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({active: activeTab === '1'})}
                  onClick={() => {toggleTab('1')}}
                >
                  {t("Locked Balance")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({active: activeTab === '2'})}
                  onClick={() => {toggleTab('2')}}
                >
                  {t("Available Balance")}
                </NavLink>
              </NavItem>
            </Nav>
          </div>

        </CardHeader>
        <div className="card-body">
          <TabContent activeTab={activeTab} className="p-0">
            <TabPane tabId="1">
              <WithdrawalCryptoForm formSentHandler={formSent} />
            </TabPane>
            <TabPane tabId="2">
              <WithdrawalCryptoForm formSentHandler={formSent} isReal={true}/>
            </TabPane>
          </TabContent>
        </div>
      </Card>
    </React.Fragment>
  )
}

export default WithdrawalCryptoCard
