import React, {useEffect, useRef, useState} from 'react'
import BreadCrumb from "../../Components/Common/BreadCrumb"
import {Col, Container, Row, Form, Button, Input, Card, CardBody, CardHeader} from "reactstrap"
import {useNavigate} from "react-router-dom"
import {createCryptoDeposit} from "../../api/deposit"
import {toast} from "react-toastify"
import LastTransactions from "../../Widgets/LastTransactions"
import {selectListData} from "../../api/general"
import {useFormik} from "formik"
import * as Yup from "yup"
import {useTranslation} from "react-i18next"

const CryptoDeposit = () => {
  const navigate = useNavigate()
  const [selectedCurrency, setSelectedCurrency] = useState('')
  const [amount, setAmount] = useState('')
  const [totalUsdAmount, setTotalUsdAmount] = useState('')
  const [assets, setAssets] = useState([])
  const lastTransactionsMethods = useRef()
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [response, setResponse] = useState()
  const { t } = useTranslation()
  const formik = useFormik({
    initialValues: {
      total_usd: '',
      amount: '',
      asset_id: '',
    },
    validationSchema: Yup.object({
      total_usd: Yup.number().required(),
      amount: Yup.number().required(),
      asset_id: Yup.number().required(),
    }),
    onSubmit: (data) => {
      setIsButtonDisabled(true)

      createCryptoDeposit(data).then((r) => {
        if (r.success) {

          if (r.status === 'declined') {
            setResponse(r)
            toast.error(r.message)
          } else {
            toast.success(r.message)
            navigate('/deposit/show/' + r.id)
          }

          setIsButtonDisabled(false)
        } else {
          toast.error(r.message)
          setIsButtonDisabled(false)
        }
      })
    }
  })

  const currencyChangeHandler = (e) => {
    setSelectedCurrency(e.target.value)
    formik.setFieldValue('asset_id', e.target.value)
  };

  const amountChangeHandler = (e) => {
    const newAmount = e.target.value
    setAmount(newAmount)
    calculateTotalUsdAmount(newAmount)
    formik.setFieldValue('amount', newAmount)
  };

  const totalUsdChangeHandler = (e) => {
    const newTotalUsdAmount = e.target.value
    setTotalUsdAmount(newTotalUsdAmount)
    calculateAmount(newTotalUsdAmount)
    formik.setFieldValue('total_usd', newTotalUsdAmount)
  }

  const calculateTotalUsdAmount = (newAmount) => {
    assets.forEach((option) => {
      if (option.value === Number(selectedCurrency) && newAmount > 0) {
        const newTotalUsd = (newAmount * Number(option.price)).toFixed(2) * 1
        setTotalUsdAmount(newTotalUsd)
        formik.setFieldValue('total_usd', newTotalUsd)
      } else if (newAmount <= 0) {
        setTotalUsdAmount('')
      }
    })
  }

  const calculateAmount = (newTotalUsdAmount) => {
    assets.forEach((option) => {
      if (option.value === Number(selectedCurrency) && newTotalUsdAmount > 0) {
        const newAmount = (newTotalUsdAmount / Number(option.price)).toFixed(8) * 1
        setAmount(newAmount)
        formik.setFieldValue('amount', newAmount)
      } else if (newTotalUsdAmount <= 0) {
        setAmount('')
      }
    })
  }

  useEffect(() => {
    selectListData('crypto_list').then((res) => {
      setAssets(res)
      setSelectedCurrency(res[0]?.value)
      formik.setFieldValue('asset_id', res[0]?.value)
    });
  }, []);

  useEffect(() => {
    calculateTotalUsdAmount(amount)
  }, [amount, selectedCurrency])

  document.title = t('Crypto') +  ' | ' + import.meta.env.VITE_APP_NAME

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Home" pageTitle="Crypto" />
          <Row className="justify-content-center">
            <Col xxl={8} sm={10} xs={12}>
              <Card>
                <CardHeader className="py-3 d-flex bg-transparent align-items-center">
                  <h6 className="mb-0 fw-bold">{!response ? t('Crypto Deposit') : t('Deposit declined!')}</h6>
                </CardHeader>
                {!response ?
                  <CardBody>
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault()

                        if (!formik.values.asset_id) {
                          toast.error('Please select asset')
                        } else if (!formik.values.amount) {
                          toast.error('Please enter amount')
                        } else if (!formik.values.total_usd) {
                          toast.error('Please enter total usd')
                        } else {
                          formik.handleSubmit(e)
                        }
                      }}
                    >
                      <div className="input-group mb-3">
                        <span className="input-group-text w-25">{t("Currency")}</span>
                        <select
                          name="asset_id"
                          className="form-select select-currency"
                          onChange={(e) => currencyChangeHandler(e)}
                          value={selectedCurrency}
                        >
                          {assets.map((option) => (
                            <option
                              key={option.value}
                              value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="input-group mb-3">
                        <span className="input-group-text w-25">{t("Amount")}</span>
                        <Input
                          type="number"
                          step="any"
                          name="amount"
                          className="form-control"
                          placeholder="0.00000000"
                          autoComplete="off"
                          value={amount}
                          onChange={(e) => amountChangeHandler(e)}
                        />
                      </div>
                      <div className="input-group mb-3">
                        <span className="input-group-text w-25">{t("Total")}</span>
                        <Input
                          type="number"
                          step="any"
                          name="total_usd"
                          className="form-control"
                          placeholder="0.00000000"
                          autoComplete="off"
                          min="50"
                          max="1000000000"
                          value={totalUsdAmount}
                          onChange={(e) => totalUsdChangeHandler(e)}
                        />
                        <span className="input-group-text justify-content-center">USD</span>
                      </div>
                      <Button
                        type="submit"
                        disabled={isButtonDisabled}
                        className="btn flex-fill btn-soft-success py-2 fs-5 px-5 w-100"
                      >
                        {t("DEPOSIT")}
                      </Button>
                    </Form>
                  </CardBody> :
                  <CardBody>
                    <Row className="w-100">
                      <Col xs={12} className="d-flex">
                        <div className="d-flex flex-column align-items-center w-100">
                          <div className="avatar-xl mt-2 mb-4 mx-auto">
                            <div className="avatar-title bg-light text-danger rounded-circle"
                                 style={{fontSize: '108px'}}>
                              <i className="mdi mdi-close-circle"></i>
                            </div>
                          </div>
                          <h4>{t(`${response.title}`)}</h4>
                          <span className="text-center" dangerouslySetInnerHTML={{__html: t(`${response.text}`)}}></span>
                          <div className="d-flex gap-3 mt-4 justify-content-center w-50">
                            <button
                              type="button"
                              className="btn btn-soft-success nexttab fs-16 w-100"
                              onClick={() => {
                                setResponse('')
                                setAmount('')
                              }}
                            >
                              {t("NEW DEPOSIT")}
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                }
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <LastTransactions
                limit={5}
                currencyType={'crypto'}
                type={'acquisition'}
                methodsRef={lastTransactionsMethods}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CryptoDeposit
