
export default {
  "Notifications": "Уведомления",
  "Mark all as read": "Отметить все",
  "View All Notifications": "Посмотреть все уведомления",
  "Title": "Название",
  "Subtitle": "Описание",
  "Created At": "Создано",
  "Mark as unread": "Отметить как непрочитанное",
  "Mark as read": "Отметить как прочитанное",
  "Read": "Прочитанное",
  "Unread": "Не прочитанное",
  "All notifications is read!": "Все уведомления прочитаны!",
  "You got invoice {tr_id}, status is \"{tr_status}\"": "Вы получили счет {tr_id}, статус \"{tr_status}\"",
  "Transaction #{tr_id} was updated": "Транзикция #{tr_id} была обновлена",
  "New status is \"{tr_status}\"": "Новый статус - \"{tr_status}\"",
  "Transaction #{tr_id} status is \"{tr_status}\"": "Транзакция #{tr_id} статус \"{tr_status}\"",
  "Sold \"{sold_asset}\" and Bought \"{buy_asset}\"": "Продано \"{sold_asset}\" и Куплено \"{buy_asset}\"",
  "Verification status!": "Статус верификации!",
  "Now your verification status is \"{tr_status}\"": "Теперь Ваш статус верификации - \"{tr_status}\"",
  "Ticket C-{tr_id} was answered": "Ответ на тикет C-{tr_id} получен",
  "Ticket C-{tr_id} was closed": "Тикет C-{tr_id} был закрыт",
  "Ticket C-{tr_id} was opened": "Тикет C-{tr_id} был открыт",
  "Your document was approved!": "Ваш документ был утвержден!",
  "Document type: \"{doc_type}\"": "Тип документа: \"{doc_type}\"",
  "Your document was declined!": "Ваш документ был отклонен!",
  "Invoice {tr_id} status updated": "Счет {tr_id} - статус обновлен",
  "Transaction #{tr_id} got new comment": "Транзакция #{tr_id} получила новый комментарий",
  "You created #{tr_id} transaction to pay invoice {invoice_id}, status: \"{tr_status}\"": "Вы создали " +
    "транзакцию #{tr_id} для оплаты счета {invoice_id}, статус: \"{tr_status}\"",
  "You successfully paid invoice {invoice_id}, transaction id: #{tr_id}": "Вы успешно оплатили " +
    "счет {invoice_id}, ID транзакции: #{tr_id}"
}
