import React from "react"
import BreadCrumb from "../../Components/Common/BreadCrumb"
import {Button, Col, Container, Form, Input, Label, Row} from "reactstrap"
import {createTicket} from "../../api/support"
import {toast} from "react-toastify"
import {useNavigate} from "react-router-dom";

const CreateTicket = () => {
  const navigate = useNavigate();

  const submitHandler = (e) => {
    e.preventDefault()
    let data = new FormData(e.target)

    if (data.get('subject') === '') {
      toast.error('Subject field is required')
    } else if (data.get('message') === '') {
      toast.error('Message field is required')
    } else {
      createTicket(data).then(r => {
        if (r.success) {
          toast.success(r.message)
          navigate('/support/show/' + r?.id)
        } else {
          toast.error(r.message)
        }
      }).catch(e => {
        toast.error(e)
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Support" pageTitle="Create Ticket" />
          <Row>
            <Col className="offset-md-3 col-12 col-md-6">
              <Form onSubmit={submitHandler}>
                <div className="mb-3">
                  <Label htmlFor="ticketSubject" className="form-label">Subject</Label>
                  <Input name="subject" type="text" className="form-control" id="ticketSubject" placeholder="Enter your subject"/>
                </div>
                <div className="mb-1">
                  <Label htmlFor="ticketMessage" className="form-label">Message</Label>
                  <textarea name="message" className="form-control" id="ticketMessage" rows="3" placeholder="Enter your message"></textarea>
                </div>
                <p className="text-muted">Please place here full description of your issue.</p>
                <Button type="submit" color="primary" className="btn w-100">SEND</Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default CreateTicket
