import React from 'react'
import classnames from "classnames"
import {Tooltip} from "react-tooltip"
import {printTransactionType} from "../../helpers/utils"
import {useTranslation} from "react-i18next"

const TransactionBadgeIcon = ({transactionId, transactionType, invoiceId, invoiceAmount}) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <div
        className="avatar-xxs"
        data-tooltip-id={transactionId}
        data-tooltip-content={
          t(`${printTransactionType(invoiceId && Number(invoiceAmount) < 0 ? 'invoice' :
            transactionType).capitalize()}`)
        }
      >
        <div className={classnames('avatar-title rounded-circle fs-16', {
          'bg-soft-danger' : transactionType === 'deduction' && !invoiceId,
          'bg-soft-success' : transactionType === 'acquisition',
          'bg-soft-info': transactionType === 'exchange' && !invoiceId,
          'bg-soft-warning' : invoiceId && Number(invoiceAmount) < 0
        })}>
          <i className={classnames({
            'ri-arrow-right-up-fill text-danger': transactionType === 'deduction' && !invoiceId,
            'ri-arrow-left-down-fill text-success': transactionType === 'acquisition',
            'ri-arrow-left-right-fill text-info rotate-45': transactionType === 'exchange' && !invoiceId,
            'ri-currency-fill text-warning' : invoiceId && Number(invoiceAmount) < 0
          })}></i>
          <Tooltip id={transactionId} />
        </div>
      </div>
    </React.Fragment>
  )
}

export default TransactionBadgeIcon
