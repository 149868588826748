
export default {
  "Last 5 Transactions": "Последние 5 Транзакций",
  "Type": "Тип",
  "Created date": "Дата создания",
  "Base Asset": "Базовый Актив",
  "Quote Asset": "Котировочный Актив",
  "Amount": "Сумма",
  "USD Amount": "Сумма в USD",
  "Fee Pct": "Комиссия %",
  "Status": "Статус",
  "Executed": "Выполнено",
  "Pending": "В ожидании",
  "Declined": "Отклонено",
  "Partial": "Частичный",
  "Paid": "Оплачено",
  "Date": "Дата",
  "Invoice ID": "ID Счета",
  "Invoicing Date": "Дата выставления",
  "Due Date": "Дата оплаты",
  "Payout Asset": "Выплачиваемый актив",
  "Actions": "Действия",
  "Top Up": "Пополнить",
  "Pay from balance": "Оплатить с баланса",
  "Loading": "Загрузка",
  "There are no records to display": "Нет записей для отображения",
  "Rows per page:": "Строк на странице:",
  "Loading...": "Загрузка..."
}

