import React from 'react'
import {Container} from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import 'react-toastify/dist/ReactToastify.css'
import InvoiceTable from "./InvoiceTable"
import {useTranslation} from "react-i18next"

const Invoices = () => {
  const { t } = useTranslation()

  document.title = t("Invoices") + ' | ' + import.meta.env.VITE_APP_NAME
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Home" pageTitle="Invoices"/>
          <InvoiceTable/>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Invoices
