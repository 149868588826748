import React, {useEffect, useState} from 'react'
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner
} from 'reactstrap'
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth"
import {useTranslation} from "react-i18next"

//redux
import {useSelector, useDispatch} from "react-redux"

import {Link} from "react-router-dom"
import withRouter from "../../Components/Common/withRouter"

// Formik validation
import * as Yup from "yup";
import {useFormik} from "formik"

// actions
import {loginUser, resetLoginFlag} from "../../slices/thunks"
import LanguageDropdown from "../../Components/Common/LanguageDropdown";

const Login = (props) => {
  const dispatch = useDispatch()
  const logoHeight = import.meta.env.VITE_THEME_LOGO_HEIGHT || 70
  const brandLogosUrl = import.meta.env.VITE_APP_URL + '/storage/brand_logos/'
  const logoLightUrl = brandLogosUrl + 'logo-light.png'
  const {user, error, loading, errorMsg} = useSelector(state => ({
    user: state.Account.user,
    error: state.Login.error,
    loading: state.Login.loading,
    errorMsg: state.Login.errorMsg,
  }));

  const { t } = useTranslation()

  const [userLogin, setUserLogin] = useState([])
  const [passwordShow, setPasswordShow] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: userLogin.email || '',
      password: userLogin.password || '',
      remember_me: false,
    },
    validationSchema: Yup.object({
      email: Yup.string().required(t("Please Enter Your Email")),
      password: Yup.string().required(t("Please Enter Your Password")),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate))
    }
  })

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        dispatch(resetLoginFlag());
      }, 3000);
    }
  }, [dispatch, errorMsg, error]);

  document.title = t("Sign In")
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      {
                        import.meta.env.VITE_APP_NAME === 'Crypto Clasic' ?
                          <img className="bg-light rounded-circle" src={logoLightUrl} alt="" height={logoHeight}/> :
                          <img src={logoLightUrl} alt="" height={logoHeight}/>
                      }
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2 position-relative">
                      {(import.meta.env.VITE_MULTILANGUAGE === 'true') &&
                        <div className="position-absolute" style={{bottom: '0px', right: '0px'}}>
                          <LanguageDropdown/>
                        </div>
                      }
                      <h5 className="text-primary">{t("Welcome Back!")}</h5>
                      <p className="text-muted">{t("Sign in to")} {import.meta.env.VITE_APP_NAME}.</p>
                    </div>
                    {error && error ? (<Alert color="danger"> {t(error)} </Alert>) : null}
                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action="#">

                        <div className="mb-3">
                          <Label htmlFor="email" className="form-label">{t("Email")}</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder={t("Enter email")}
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              !!(validation.touched.email && validation.errors.email)
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          {/*<div className="float-end">*/}
                          {/*  <Link to="/forgot-password" className="text-muted">Forgot password?</Link>*/}
                          {/*</div>*/}
                          <Label className="form-label" htmlFor="password-input">{t("Password")}</Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type={passwordShow ? "text" : "password"}
                              className="form-control pe-5"
                              placeholder={t("Enter Password")}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                !!(validation.touched.password && validation.errors.password)
                              }
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                              type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><i
                              className="ri-eye-fill align-middle"></i></button>
                          </div>
                        </div>

                        <div className="form-check">
                          <Input className="form-check-input"
                                 id="remember_me"
                                 name="remember_me"
                                 type="checkbox"
                                 onChange={validation.handleChange}
                                 onBlur={validation.handleBlur}
                                 value={validation.values.remember_me || ''}
                          />
                          <Label className="form-check-label" htmlFor="remember_me">{t("Remember me")}</Label>
                        </div>

                        <div className="mt-4">
                          <Button color="success" disabled={error ? null : loading} className="btn btn-success w-100"
                                  type="submit">
                            {loading ? <Spinner size="sm" className='me-2'> {t("Loading")}... </Spinner> : null}
                            {t("Sign In")}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>

                {import.meta.env.VITE_REGISTRATION_BLOCKED === 'false' &&
                  <div className="mt-4 text-center">
                    <p className="mb-0">
                      {t("Don't have an account ?")}
                    </p>
                    <div>
                      <p className="mb-0">
                        <Link
                          to="/create-new-account"
                          className="fw-semibold text-primary text-decoration-underline"
                        > {t("Register now")} </Link>
                      </p>
                    </div>
                  </div>
                }

              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Login);
