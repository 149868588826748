import React, {useEffect, useRef, useState} from "react"
import {APIClient} from "../../helpers/api_helper"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import Cleave from "cleave.js/react"
import BreadCrumb from "../../Components/Common/BreadCrumb"
import "react-intl-tel-input/dist/main.css"
import {loadAnimation} from "lottie-web"
import {defineElement} from "lord-icon-element"
import {createCreditCardDeposit} from "../../api/deposit"
import {toast} from "react-toastify"
import PhoneInputComponent from "../../Components/IntlTelInput/intlTelInput"
import {getProfile} from "../../api/account"
import LastTransactions from "../../Widgets/LastTransactions"
import Select from "react-select"
import {selectListData} from "../../api/general"
import {useFormik} from "formik"
import * as Yup from "yup"
import {useTranslation} from "react-i18next"

defineElement(loadAnimation)

const api = new APIClient()

const CreditCard = () => {
  const [activeTab, setActiveTab] = useState(1)
  const [dateFormat, setDateFormat] = useState('')
  const [progressBarValue, setProgressBarValue] = useState(0)
  const [creditCardNo, setCreditCardNo] = useState('')
  const countriesList = useRef([])
  const [country, setCountry] = useState([])
  const lastTransactionsMethods = useRef()
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [response, setResponse] = useState()
  const { t } = useTranslation()
  const formik = useFormik({
    initialValues: {
      deposit_amount: '',
      cvc: '',
      expiry_date: '',
      card_number: '',
      country: '',
      canton: '',
      address: '',
      postal_code: '',
      city: '',
      phone: ''
    },
    validationSchema: Yup.object({
      deposit_amount: Yup.number().required(''),
      cvc: Yup.string()
        .min(3, 'Invalid CVC')
        .required(''),
      expiry_date: Yup.string()
        .min(5, 'Invalid Expiry Date')
        .required(''),
      card_number: Yup.string()
        .min(19, 'Invalid Card Number')
        .required(''),
      country: Yup.string().required(''),
      canton: Yup.string().required(''),
      address: Yup.string().required(''),
      postal_code: Yup.string().required(''),
      city: Yup.string().required(''),
      phone: Yup.string().required(''),
  }),
    onSubmit: (data) => {
      setIsButtonDisabled(true)

      createCreditCardDeposit(data).then(r => {
        let message = r?.message || 'Empty response message.'
        setResponse(r)

        if (r.success) {

          if (r.status === 'declined') {
            toast.error(message)
          } else {
            toast.success(t(message))
          }

          lastTransactionsMethods.current.updateTable()
          resetForm()
          setIsButtonDisabled(false)
          toggleTab(4)
          window.scrollTo(0, 0)
        } else {
          toast.error(message)
          setIsButtonDisabled(false)
        }
      })
    }
  })

  const statusIcons = {
    pending:
      <lord-icon
        src="https://cdn.lordicon.com/lupuorrc.json"
        trigger="loop"
        colors="primary:#0ab39c,secondary:#405189"
        style={{width: "120px", height: "120px"}}
      >
      </lord-icon>,
    executed:
      <lord-icon
        src="https://cdn.lordicon.com/lupuorrc.json"
        trigger="loop"
        colors="primary:#0ab39c,secondary:#405189"
        style={{width: "120px", height: "120px"}}
      >
      </lord-icon>,
    declined:
      <div className="avatar-xl mt-4 mb-4 mx-auto">
        <div className="avatar-title bg-light text-danger rounded-circle" style={{fontSize: '108px'}}>
          <i className="mdi mdi-close-circle"></i>
        </div>
      </div>
  }

  const resetForm = () => {
    setCreditCardNo('')
    setDateFormat('')
    formik.values.deposit_amount = ''
    formik.values.cvc = ''
    formik.values.expiry_date = ''
    formik.values.card_number = ''
  }

  const getProfileData = () => {
    getProfile().then(r => {
      formik.setFieldValue('phone', !r.phone ? '' : r.phone)
      formik.setFieldValue('canton', !r.state ? '' : r.state)
      formik.setFieldValue('address', !r.address ? '' : r.address)
      formik.setFieldValue('postal_code', !r.postal_code ? '' : r.postal_code)
      formik.setFieldValue('city', !r.city ? '' : r.city)
      setCountry({
        label: !r.customer_country ? '' : r.customer_country,
        value: !r.country ? '' : r.country,
      })
      formik.setFieldValue('country', !r.country ? '' : r.country)
    })
  }

  const getCountriesData = async () => {
    countriesList.current = await selectListData('countries')
  }

  function toggleTab(tab, value) {
    if (activeTab !== tab) {

      if (tab === 2) {
        if (formik.values.deposit_amount > 0) {
          setActiveTab(tab)
          setProgressBarValue(value)
        } else {
          toast.error(t('Please enter Deposit Amount'))
        }
      } else if (tab === 3) {
        if (formik.values.card_number &&
          formik.values.expiry_date &&
          formik.values.cvc &&
          formik.values.cvc.length === 3 &&
          formik.values.expiry_date.length === 5 &&
          formik.values.card_number.length === 19
        ) {
          setActiveTab(tab)
          setProgressBarValue(value)
        } else if (!formik.values.card_number || formik.values.card_number.length < 19) {
          toast.error(t('Please enter a valid Card Number (16 digits)'))
        } else if (!formik.values.expiry_date || formik.values.expiry_date.length < 5) {
          toast.error(t('Please enter a valid Expiry Date (MM/YY)'))
        } else if (!formik.values.cvc || formik.values.cvc.length < 3) {
          toast.error(t('Please enter a valid CVC (3 digits)'))
        }
      } else {
        setActiveTab(tab)
        setProgressBarValue(value)
      }

    }
  }

  function onDateFormatChange(e) {
    setDateFormat(e.target.rawValue)
  }

  function onCreditCardChange(e) {
    setCreditCardNo(e.target.rawValue)
  }

  useEffect(() => {
    ;(async () => {
      await getCountriesData()
      getProfileData()
    })()
  }, [])

  document.title = t('Credit Card') + ' | ' + import.meta.env.VITE_APP_NAME

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Home" pageTitle="Credit Card"/>
          <Row className="justify-content-center">
            <Col xs={12} md={10} xl={6}>
              <Card>
                <CardHeader className="border-bottom-0">
                  {(activeTab === 4) ?
                    <h4 className="card-title mb-0">{t(`${response?.message}`)}</h4>
                    :
                    <h4 className="card-title mb-0">{t("Enter payment information")}</h4>
                  }
                </CardHeader>
                <CardBody>
                  <Form onSubmit={(e) => {
                    e.preventDefault()

                    if (!formik.values.country) {
                      toast.error(t('Please select a country'))
                    } else if (!formik.values.canton) {
                      toast.error(t('Please enter Canton'))
                    } else if (!formik.values.address) {
                      toast.error(t('Please enter Address'))
                    } else if (!formik.values.postal_code) {
                      toast.error(t('Please enter Postal Code'))
                    } else if (!formik.values.city) {
                      toast.error(t('Please enter City'))
                    } else if (!formik.values.phone) {
                      toast.error(t('Please enter Phone Number'))
                    } else {
                      formik.handleSubmit(e)
                    }
                  }}>
                    <div className={`progress-nav mb-4 ${activeTab === 4 ? 'd-none' : ''}`}>
                      <Progress
                        value={progressBarValue}
                        style={{height: "2px"}}
                      />

                      <Nav
                        className="nav-pills progress-bar-tab custom-nav"
                        role="tablist"

                      >
                        <NavItem>
                          <NavLink
                            to="#"
                            id="pills-gen-info-tab"
                            className={classnames(
                              {
                                active: activeTab === 1,
                                done: activeTab <= 4 && activeTab >= 0,
                              },
                              "rounded-pill"
                            )}
                            onClick={(e) => {
                              e.preventDefault()
                              toggleTab(1, 0)
                            }}
                            tag="button"
                          >
                            1
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            to="#"
                            id="pills-gen-info-tab"
                            className={classnames(
                              {
                                active: activeTab === 2,
                                done: activeTab <= 4 && activeTab > 1,
                              },
                              "rounded-pill"
                            )}
                            onClick={(e) => {
                              e.preventDefault()
                              toggleTab(2, 50)
                            }}
                            tag="button"
                          >
                            2
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            to="#"
                            id="pills-gen-info-tab"
                            className={classnames(
                              {
                                active: activeTab === 3,
                                done: activeTab <= 4 && activeTab > 2,
                              },
                              "rounded-pill"
                            )}
                            onClick={(e) => {
                              e.preventDefault()
                              toggleTab(3, 100)
                            }}
                            tag="button"
                          >
                            3
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>

                    <TabContent activeTab={activeTab}>
                      <TabPane tabId={1}>
                        <Row>
                          <div className="col-12 mb-3">
                            <span className="text-muted">{t("Enter the amount in USD")}
                              <sup className="text-danger fs-12">*</sup>
                            </span>
                          </div>
                          <div className="input-group">
                            <Input
                              name="deposit_amount"
                              type="number"
                              className="form-control product-price-input"
                              id="deposite_amount_input"
                              placeholder={t("Deposit Amount")}
                              autoComplete="off"
                              value={formik.values.deposit_amount}
                              onChange={formik.handleChange}
                            />
                          </div>
                        </Row>

                        <div className="d-flex align-items-start gap-3 mt-4 justify-content-center">
                          <button
                            type="button"
                            className="btn btn-soft-success nexttab fs-16 w-100"
                            onClick={() => {
                              toggleTab(activeTab + 1, 50)
                            }}
                          >
                            {t("NEXT")}
                          </button>
                        </div>
                      </TabPane>

                      <TabPane tabId={2}>
                        <Row>
                          <div className="col-12 mb-3">
                            <span className="text-muted">
                              {t("Card info")}
                              <sup className="text-danger fs-12">*</sup>
                            </span>
                          </div>

                          <div className="col-12 mb-3 input-group">
                            <span className="input-group-text">
                              <i className="bx bx-credit-card fs-16 text-muted"></i>
                            </span>
                            <Cleave
                              placeholder={t("Card number")}
                              autoComplete="off"
                              name="card_number"
                              options={{
                                creditCard: true,
                              }}
                              value={creditCardNo}
                              onChange={(e) => {
                                onCreditCardChange(e)
                                setCreditCardNo(e.target.value)
                                formik.setFieldValue('card_number', e.target.value)
                              }}
                              className="form-control"
                            />
                          </div>

                          <div className="col-6 mb-3">
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="bx bx-calendar fs-16 text-muted"></i>
                              </span>
                              <Cleave
                                name="expiry_date"
                                placeholder={t("MM/YY")}
                                options={{
                                  date: true,
                                  delimiter: "/",
                                  datePattern: ['m', 'y']
                                }}
                                value={dateFormat}
                                autoComplete="off"
                                onChange={(e) => {
                                  setDateFormat(e.target.value)
                                  onDateFormatChange(e)
                                  formik.setFieldValue('expiry_date', e.target.value)
                                }}
                                className="form-control"
                              />
                            </div>
                          </div>

                          <div className="col-6 mb-3">
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="bx bx-lock fs-16 text-muted"></i>
                              </span>
                              <Input
                                type="text"
                                name="cvc"
                                className="form-control rounded-end"
                                maxLength="3"
                                placeholder="CVC"
                                autoComplete="off"
                                value={formik.values.cvc}
                                onChange={formik.handleChange}
                              />
                            </div>
                          </div>
                        </Row>
                        <div className="d-flex gap-3 mt-4 justify-content-between">
                          <Button
                            type="button"
                            className="btn btn-soft-warning prevtab fs-16 w-50"
                            onClick={() => {
                              toggleTab(activeTab - 1, 0)
                            }}
                          >
                            {t("PREVIOUS")}
                          </Button>
                          <Button
                            type="button"
                            className="btn btn-soft-success nexttab fs-16 w-50"
                            onClick={() => {
                              toggleTab(activeTab + 1, 100)
                            }}
                          >
                            {t("NEXT")}
                          </Button>
                        </div>
                      </TabPane>

                      <TabPane tabId={3}>
                        <Row>
                          <div className="col-12 mb-3">
                            <span className="text-muted">{t("Billing info")}</span>
                          </div>
                          <div className="col-12 mb-3 input-group">
                            <div className="w-100">
                              <label htmlFor="country" className="form-label">
                                {t("Country")}
                                <sup className="text-danger fs-12">*</sup>
                              </label>
                              <Select
                                name="country"
                                options={countriesList.current}
                                value={country}
                                onChange={(selectedOptions) => {
                                  setCountry(selectedOptions)
                                  formik.setFieldValue('country', selectedOptions.value)
                                }}
                                required
                              />
                            </div>
                          </div>
                          <div className="input-group mb-3">
                            <div className="col-sm-12 w-100">
                              <label htmlFor="canton" className="form-label">
                                {t("Canton")}
                                <sup className="text-danger fs-12">*</sup>
                              </label>
                              <Input
                                type="text"
                                className="form-control"
                                id="canton"
                                placeholder={t("Canton*")}
                                name="canton"
                                autoComplete="off"
                                value={formik.values.canton}
                                onChange={(e) =>
                                  formik.setFieldValue('canton', e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="input-group mb-3">
                            <div className="col-sm-12 w-100">
                              <label htmlFor="address" className="form-label">
                                {t("Address")}
                                <sup className="text-danger fs-12">*</sup>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="address"
                                placeholder={t("Address*")}
                                name="address"
                                value={formik.values.address}
                                onChange={(e) =>
                                  formik.setFieldValue('address', e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6  mb-3">
                            <label htmlFor="postal_code" className="form-label">
                              {t("Postal/ZIP code")}
                              <sup className="text-danger fs-12">*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("Postal/ZIP code*")}
                              name="postal_code"
                              value={formik.values.postal_code}
                              onChange={(e) =>
                                formik.setFieldValue('postal_code', e.target.value)
                              }
                            />
                          </div>
                          <div className="col-sm-12 col-md-6 mb-3">
                            <label htmlFor="city" className="form-label">
                              {t("City")}
                              <sup className="text-danger fs-12">*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("City*")}
                              name="city"
                              value={formik.values.city}
                              onChange={(e) =>
                                formik.setFieldValue('city', e.target.value)
                              }
                            />
                          </div>
                          <div className="mb-3 col-6">
                            <label htmlFor="phone" className="form-label">
                              {t("Phone")}
                              <sup className="text-danger fs-12">*</sup>
                            </label>
                            <div className="input-group col-6">
                              <PhoneInputComponent
                                phone={formik.values.phone}
                                onChange={(newValue) =>
                                  formik.setFieldValue('phone', newValue)
                                }
                              />
                            </div>
                          </div>
                        </Row>
                        <div className="d-flex gap-3 mt-4 justify-content-between">
                          <button
                            type="button"
                            className="btn btn-soft-warning prevtab fs-16 w-50"
                            onClick={() => {
                              toggleTab(activeTab - 1, 50)
                            }}
                          >
                            {t("PREVIOUS")}
                          </button>
                          <button
                            type="submit"
                            disabled={isButtonDisabled}
                            className="btn btn-soft-success nexttab fs-16 w-50"
                          >
                            {t("SUBMIT")}
                          </button>
                        </div>
                      </TabPane>

                      {response &&
                        <TabPane tabId={4}>
                          <div>
                            <div className="text-center">
                              <div className="mb-4">
                                {statusIcons[response.status]}
                              </div>
                              <h4>{t(`${response.title}`)}</h4>
                              <span dangerouslySetInnerHTML={{__html: t(`${response.text}`)}}></span>
                            </div>
                          </div>
                          <div className="d-flex gap-3 mt-4 justify-content-center">
                            <button
                              type="button"
                              className="btn btn-soft-success nexttab fs-16 w-100"
                              onClick={() => {
                                toggleTab(activeTab - 3, 0)
                                setResponse('')
                              }}
                            >
                              {t("NEW DEPOSIT")}
                            </button>
                          </div>
                        </TabPane>
                      }
                    </TabContent>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <LastTransactions
                limit={5}
                type={'acquisition'}
                currencyType={'fiat'}
                methodsRef={lastTransactionsMethods}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreditCard
