import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap'
import {useTranslation} from "react-i18next"

const BreadCrumb = ({ title, pageTitle }) => {
  const { t } = useTranslation()

    return (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t(`${pageTitle}`)}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                {/*<li className="breadcrumb-item"><Link to="#">{pageTitle}</Link></li>*/}
                                <li className="breadcrumb-item active">{import.meta.env.VITE_APP_NAME}</li>
                            </ol>
                        </div>

                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default BreadCrumb;
