import {Button, Card, CardBody, CardHeader, Form, FormGroup, Input, InputGroup, Label} from "reactstrap"
import React, {useState} from "react"
import {disable2fa, enable2fa, generate2fa} from '../../../api/auth'
import {toast} from "react-toastify";
import {copyToClipboard} from "../../../helpers/utils";
import {useTranslation} from "react-i18next"

function TwoStepVerification({twoFactorAuthIsEnabled, toggleSecurityStateHandler}) {
  const [secureCode, setSecureCode] = useState('')
  const [secureCodeInput, setSecureCodeInput] = useState('')
  const [qrCode, setQrCode] = useState('')
  const [generateBtnText, setGenerateBtnText] = useState('Generate secret key to Enable 2FA')
  const { t } = useTranslation()

  function generate2faHandler(e) {
    const btn = e.target
    btn.disabled = true

    generate2fa().then(r => {
      if (r.success) {
        setQrCode(r['2fa_qr'])
        setSecureCode(r.security_code)
        setGenerateBtnText('Re-generate secret key to Enable 2FA')
        toast.success(r.message)
      }
    }).finally(() => btn.disabled = false)
  }

  function sendSecurityCodeHandler(e) {
    e.preventDefault()
    const btn = e.target.querySelector('[type=submit]')
    btn.disabled = true

    const data = {
      secret: secureCodeInput
    }

    enable2fa(data).then(r => {
      if (r.success) {
        toast.success(r.message)
        toggleSecurityStateHandler()
      } else {
        toast.error(r.message)
        setSecureCodeInput('')
      }
    }).finally(() => btn.disabled = false)
  }

  function disable2FAHandler(e) {
    const btn = e.target
    btn.disabled = true

    disable2fa().then(r => {
      if (r.success) {
        toggleSecurityStateHandler()
        toast.success(r.message)
      }
    }).finally(() => btn.disabled = false)
  }

  return (
    <Card>
      <CardHeader>
        <h4 className="mb-0">2FA</h4>
      </CardHeader>
      <CardBody>
        <p>{t("2fa_description")}</p>
        {!!twoFactorAuthIsEnabled ?
          <Button color="danger" onClick={disable2FAHandler}>{t("Disable 2FA")}</Button>
          :
          <>
            <Button className="w-100 mb-2" color="primary" onClick={generate2faHandler}>{t(`${generateBtnText}`)}</Button>
            {(qrCode && secureCode) ?
              <>
                <span>{t("Please scan the code or enter the security code manually to get your verification code to enable 2FA.")}</span>
                <h6 className="text-center mt-3 mb-0">{t("Google Authenticator QRCode")}</h6>
                <div className="w-100 text-center">
                  {qrCode &&
                    <span dangerouslySetInnerHTML={{__html: qrCode}}></span>
                  }
                  {secureCode &&
                    <div
                      className="hstack align-items-center justify-content-center cursor-pointer"
                      onClick={() => copyToClipboard(secureCode)}
                    >
                      <code>{secureCode}</code>
                      <i className="ri-file-copy-2-line fs-18 ms-2"></i>
                    </div>
                  }
                </div>
                <Form onSubmit={sendSecurityCodeHandler}>
                  <FormGroup>
                    <Label className="form-label mt-3">{t("2FA code")}</Label>
                    <InputGroup>
                      <Input
                        onChange={e => setSecureCodeInput(e.target.value)}
                        value={secureCodeInput}
                        type="text"
                        className="form-control"
                        placeholder={t("Enter verification code from 2FA app here")}
                      />
                      <Button color="success" type="submit">{t("Verify")}</Button>
                    </InputGroup>
                  </FormGroup>
                </Form>
              </> : ''
            }
          </>
        }
      </CardBody>
    </Card>
  )
}

export default TwoStepVerification
