import React, {useEffect, useState, useRef, useCallback} from "react"
import {
  Container,
  Button,
  Input,
  Row,
  Col,
  Label,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from "reactstrap"
import {Link} from "react-router-dom"
import SimpleBar from "simplebar-react"
import classnames from "classnames"
import {closeTicket, commentTicket, createTicket, getTicket} from "../../api/support"
import {copyChatMessage, prettyDate} from "../../helpers/utils"
import {getProfile} from "../../api/account"
import {toast} from "react-toastify"
import '../../assets/scss/pages/_chat-page-background.scss'
import {useTranslation} from "react-i18next"

//Import Icons & avatar
import default_avatar from "../../assets/images/users/default_avatar.webp"

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import {useDispatch, useSelector} from "react-redux"
import {getTicketsData} from "../../slices/tickets/thunks"

const Support = () => {
  const dispatch = useDispatch()
  const ticketsData = useSelector(s => s.Tickets.ticketsData)
  const userChatShow = useRef()
  const [messageBox, setMessageBox] = useState(null)
  const [curMessage, setCurMessage] = useState("")
  const [infoDropdown, setInfoDropdown] = useState(false)
  const [singleTicket, setSingleTicket] = useState()
  const [userId, setUserId] = useState()
  const [profileData, setProfileData] = useState()
  const [closeButtonDisable, setCloseButtonDisable] = useState(false)
  const [isCreateForm, setIsCreateForm] = useState(false)
  const [isMouseOnItem, setIsMouseOnItem] = useState('')

  const { t } = useTranslation()

  const getProfileData = () => {
    getProfile().then(r => {
      setProfileData(r)
      setUserId(r.id)
    })
  }

  const openTicketHandler = async (id) => {
    try {
      const response = await getTicket(id)
      setSingleTicket(response)
      setIsCreateForm(false)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const userChatOpen = () => {
    if(window.innerWidth < 992){
      userChatShow.current.classList.add("user-chat-show")
    }
  }

  const backToUserChat = () => {
    userChatShow.current.classList.remove("user-chat-show");
  }

  const isCreateNewTicketForm =() => {
    setSingleTicket('')
    setIsCreateForm(true)
  }

  const toggleInfo = () => {
    setInfoDropdown(!infoDropdown);
  }

  const createTicketHandler = (e) => {
    e.preventDefault()
    let data = new FormData(e.target)

    if (data.get('subject') === '') {
      toast.error(t('Subject field is required'))
    } else if (data.get('message') === '') {
      toast.error(t('Message field is required'))
    } else {
      createTicket(data).then(r => {
        if (r.success) {
          toast.success(r.message)
          dispatch(getTicketsData())
          openTicketHandler(r.id)
        } else {
          toast.error(r.message)
        }
      }).catch(e => {
        toast.error(e)
      })
    }
  }

  const commentTicketHandler = (e) => {
    e.preventDefault()
    const btn = e.target.querySelector('[type="submit"]')
    btn.disabled = true

    let data = new FormData(e.target)

    commentTicket(singleTicket.id, data).then(r => {
      if (r.success) {
        toast.success(r.message)
        e.target.reset()
        setCurMessage('')
        openTicketHandler(singleTicket.id)
        dispatch(getTicketsData())
      } else {
        toast.error(r.message)
      }
    }).catch(e => {
      toast.error(e)
    }).finally(() => {
      btn.disabled = false

    })
  }

  const closeTicketHandler = () => {
    setCloseButtonDisable(true)

    closeTicket(singleTicket.id).then(r => {
      if (r.success) {
        toast.success(r.message)
        dispatch(getTicketsData())
      } else {
        toast.error(r.message)
      }

      setCloseButtonDisable(false)
    }).catch(e => {
      toast.error(e)
    })
  }

  const scrollToBottom = useCallback(() => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 1000
    }
  }, [messageBox])

  useEffect(() => {
    if (singleTicket) scrollToBottom()
  }, [singleTicket, scrollToBottom])

  useEffect(() => {
    getProfileData()
    dispatch(getTicketsData())
  }, [])

  useEffect(() => {
    if (singleTicket?.id) {
      openTicketHandler(singleTicket.id)
    }
  }, [ticketsData])

  document.title = t("Support") + ' | ' + import.meta.env.VITE_APP_NAME

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
            <div className="chat-leftsidebar">

              <div className="p-3">
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <h5 className="mb-0">{t("Chats list")}</h5>
                  </div>
                  <div>
                    <UncontrolledTooltip placement="bottom" target="addTicket">
                      {t("Create New Chat")}
                    </UncontrolledTooltip>
                    <button
                      color=""
                      id="addTicket"
                      onClick={() => {
                        isCreateNewTicketForm()
                        userChatOpen()
                      }}
                      className="btn btn-soft-success btn-sm shadow-none"
                    >
                      <i className="ri-add-line align-bottom"></i>
                    </button>
                  </div>
                </div>
              </div>
              {/*// Button "Create new ticket" END*/}

              {/*// Tickets list START*/}
              <div className="pt-4 px-1 border-top">
                <SimpleBar
                  className="chat-room-list pt-3"
                  style={{margin: "-16px 0px 0px"}}
                >
                  <div className="chat-message-list">
                    <ul
                      className="list-unstyled chat-list chat-user-list users-list"
                      id="userList"
                    >
                      {ticketsData?.data?.length > 0 && (ticketsData?.data || []).map((chat, idx) => (
                        <li
                          key={idx}
                        >
                          <div
                            className={classnames(
                              `flex-grow-1 align-items-center d-flex justify-content-between
                                  overflow-hidden fs-12 py-1 cursor-pointer`,
                              {
                                'bg-light': singleTicket?.id === chat.id,
                                'bg-soft-info': isMouseOnItem === String(idx)
                              })}
                            style={{transition: '0.15s'}}
                            onMouseEnter={() => setIsMouseOnItem(String(idx))}
                            onMouseLeave={() => setIsMouseOnItem('')}
                            onClick={() => {
                              openTicketHandler(chat.id)
                              userChatOpen()
                            }}
                          >
                              <span
                                className="ps-2 pe-1 text-truncate"

                              >
                                #{chat.id} | {chat.subject}
                              </span>
                            <span
                              className="pe-2"
                            >
                              <small className="fs-10">
                                <span
                                  className={`badge rounded-pill badge-soft-${chat.status_color === 'danger' ?
                                    'warning' : chat.status_color
                                  }`}
                                >
                                  {chat.status === '1' ? t('Waiting') : (chat.status === '3' ? t('Answered') : t(chat.status_name))}
                                </span>
                              </small>
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </SimpleBar>
              </div>
              {/*// Tickets list END*/}

            </div>

            <div className="user-chat bg-chat  w-100 overflow-hidden" ref={userChatShow}>
              <div className="chat-content d-lg-flex">
                <div className="w-100 overflow-hidden position-relative">
                  <div className="position-relative">

                    {/*Top Bar START*/}
                    <div className="p-3 user-chat-topbar">
                      {singleTicket ?
                        <Row className="align-items-center" style={{minHeight: '27px'}}>
                          <Col sm={6} xs={8}>
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1 overflow-hidden">
                                <div className="d-flex align-items-center">
                                  <div className="flex-shrink-0  d-lg-none me-3">
                                    <Link
                                      to="#"
                                      onClick={backToUserChat}
                                      className="user-chat-remove fs-18 p-1"
                                    >
                                      <i className="ri-arrow-left-s-line align-bottom"></i>
                                    </Link>
                                  </div>
                                  <div className="flex-grow-1 overflow-hidden">
                                    <h4 className={classnames("text-truncate mb-0", {
                                      "text-muted": singleTicket.status === '4'
                                    })}>
                                      {t("Subject")}: {singleTicket?.subject}
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col sm={6} xs={4}>
                            <ul className="list-inline user-chat-nav text-end mb-0">

                                <li className="list-inline-item mb-0 ms-3">
                                  <UncontrolledDropdown>
                                    <DropdownToggle tag="a" href="#" className="text-muted">
                                      <i className="ri-more-2-fill fs-18"/>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                      {singleTicket &&
                                        <DropdownItem style={{cursor: 'inherit'}} className="text-muted">
                                          <i className="mdi mdi-information-outline text-body fs-14 me-2 align-bottom"/>
                                          {t("Status")}: {singleTicket.status === '1' ? t('Waiting') :
                                          (singleTicket.status === '3' ? t('Answered') :
                                            t(singleTicket.status_name))}
                                        </DropdownItem>
                                      }
                                      {singleTicket.status !== '4' &&
                                        <DropdownItem onClick={closeTicketHandler} className="text-body">
                                          <i className="ri-close-circle-line fs-14 me-2 align-bottom"/>
                                          {t('Close ticket')}
                                        </DropdownItem>
                                      }
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </li>
                            </ul>
                          </Col>
                        </Row>
                        :
                        <Row className="align-items-center">
                          <Col>
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1 overflow-hidden">
                                <div className="d-flex align-items-center">
                                  <div className="flex-shrink-0  d-lg-none me-3">
                                    <Link
                                      to="#"
                                      onClick={backToUserChat}
                                      className="user-chat-remove fs-18 p-1"
                                    >
                                      <i className="ri-arrow-left-s-line align-bottom"></i>
                                    </Link>
                                  </div>
                                  <div className="flex-grow-1 overflow-hidden">
                                    <h4 className="mb-0 text-center">
                                      {isCreateForm ?
                                        <span>{t("Create ticket")}</span> :
                                        <span>{t('Chat')}</span>
                                      }
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      }
                    </div>
                    {/*Top Bar END*/}

                    {/*// ScrollBar section START //*/}
                    <div className="position-relative" id="users-chat">
                      <PerfectScrollbar
                        className={classnames('p-3 p-lg-4', {
                          'chat-conversation-create': isCreateForm,
                          'chat-conversation': !isCreateForm,
                        })}
                        id="chat-conversation"
                        containerRef={ref => setMessageBox(ref)}
                      >
                        <div id="elmLoader"></div>
                        {(!singleTicket && !isCreateForm) &&
                          <div className="position-relative h-75">
                            <div className="absolut-chat-preview">
                              <h2 className="text-muted text-nowrap">{t("Select a ticket from the existing ones")}</h2>
                              <h4 className="text-muted lh-lg">{t('or create a new one')} </h4>
                              <button
                                type="btn"
                                className="btn btn-success px-5 mt-3"
                                onClick={() => {
                                  isCreateNewTicketForm()
                                  userChatOpen()
                                }}
                              >
                                {t('Create new ticket')}
                              </button>
                            </div>
                          </div>
                        }
                        <ul
                          className="list-unstyled chat-conversation-list"
                          id="users-conversation"
                        >
                          {singleTicket &&
                            <div className="d-flex justify-content-center mb-3">
                              <span>{t("Opened")} {prettyDate(singleTicket.created_at)}</span>
                            </div>
                          }
                          {singleTicket &&
                            <li className="chat-list right">
                              <div className="conversation-list">
                                <div className="chat-avatar">
                                  <img
                                    src={profileData?.avatar_url ? profileData.avatar_url : default_avatar}
                                    alt=""
                                  />
                                </div>
                                <div className="user-chat-content">
                                  <div className="ctext-wrap">
                                    <div className="ctext-wrap-content spa keep-lines">
                                      <p className="mb-0 ctext-content">
                                        {singleTicket?.message}
                                      </p>
                                    </div>
                                    {copyChatMessage({ text: singleTicket.message, title: t('Copy') })}
                                  </div>
                                  <div className="conversation-name">
                                    <small className="text-muted time">
                                      {prettyDate(singleTicket.created_at)}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </li>
                          }
                          {singleTicket?.comments &&
                            singleTicket.comments.map((message, key) => (
                              <li
                                className={
                                  message.user_id === userId
                                    ? " chat-list right"
                                    : "chat-list left"
                                }
                                key={key}
                              >
                                <div className="conversation-list">
                                  <div className="chat-avatar">
                                    <img
                                      src={message.user_id === userId ?
                                        (profileData?.avatar_url ? profileData.avatar_url : default_avatar) :
                                        default_avatar
                                      }
                                      alt=""
                                    />
                                  </div>

                                  <div className="user-chat-content">
                                    <div className="ctext-wrap">
                                      <div className="ctext-wrap-content">
                                        <p className="mb-0 ctext-content keep-lines">
                                          {message.message}
                                        </p>
                                      </div>
                                      {copyChatMessage({ text: message.message, title: t('Copy') })}
                                    </div>
                                    <div className="conversation-name">
                                      {message.user_id !== userId &&
                                        <small className="text-muted">
                                          {t("Support Team")}
                                        </small>
                                      }
                                      <small className="text-muted">
                                        {prettyDate(message.created_at)}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>

                        {singleTicket?.status === '4' &&
                          <div className="d-flex justify-content-center mt-3">
                            <span>{t("Closed")} {prettyDate(singleTicket.updated_at)}</span>
                          </div>
                        }

                      </PerfectScrollbar>
                    </div>
                    {/*// ScrollBar section END //*/}

                    {/*// Form to send message START //*/}
                    {singleTicket &&
                      <div className="chat-input-section p-3 p-lg-4">
                        <form id="chatinput-form" onSubmit={commentTicketHandler}>
                          <Row className="g-0 align-items-center">
                            <div className="col">
                            <textarea
                              value={curMessage}
                              name="comment"
                              rows="4"
                              disabled={singleTicket.status === '4'}
                              onChange={e => setCurMessage(e.target.value)}
                              className="form-control bg-light border-light comment-textarea"
                              placeholder={
                                singleTicket?.status === '4' ?
                                  t("This ticket is closed. You can no longer post messages here. If you have more questions, please create a new ticket.") :
                                  t("Type your message...")
                              }
                            />
                            </div>
                            <div className="col-auto">
                              <div className="chat-input-links ms-2">
                                <div className="links-list-item">
                                  <Button
                                    type="submit"
                                    disabled={singleTicket.status === '4'}
                                    color="success"
                                    className="chat-send waves-effect waves-light"
                                  >
                                    <i className="ri-send-plane-2-fill align-bottom"></i>
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Row>
                        </form>
                      </div>
                    }
                    {/*// Form to send message END //*/}

                    {/*// Form to create new ticket START //*/}
                    {isCreateForm &&
                      <div className="chat-input-section p-3 p-lg-4">
                        <form onSubmit={e => createTicketHandler(e)}>
                          <Row className="g-0 align-items-center">
                            <div className="col">
                              <div className="mb-3">
                                <Label htmlFor="ticketSubject" className="form-label">{t('Subject')}</Label>
                                <Input
                                  name="subject"
                                  type="text"
                                  autoComplete="off"
                                  className="form-control bg-light border-light"
                                  id="ticketSubject"
                                  placeholder={t("Enter your subject")}
                                />
                              </div>
                              <div className="col">
                                <Label htmlFor="ticketMessage" className="form-label">{t('Message')}</Label>
                                <textarea
                                  id="ticketMessage"
                                  name="message"
                                  rows="5"
                                  className="form-control bg-light border-light create-textarea"
                                  placeholder={t("Type your message...")}
                                />
                              </div>
                              <p className="text-muted text-center mt-2">
                                {t('Please place here full description of your issue.')}
                              </p>
                              <div className="d-flex justify-content-center">
                                <Button
                                  color="success"
                                  type="submit"
                                  className="btn-label right"
                                >
                                  <i className="bx bx-check-double label-icon align-middle fs-16 ms-2"></i>
                                  {t('Create ticket')}
                                </Button>
                              </div>
                            </div>
                          </Row>
                        </form>
                      </div>
                    }
                    {/*// Form to create new ticket END //*/}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Support
