import React, {useState, useEffect} from "react"
import {
  Col,
  Form,
  Input,
  Row,
  NavItem,
  NavLink,
  Nav, TabContent, TabPane, Button, UncontrolledAlert,
} from "reactstrap"
import Select from "react-select"
import {Link} from "react-router-dom"
import classnames from "classnames"
import {calculateMaxSum, createCryptoWithdraw} from "../../../api/withdrawal"
import {toast} from "react-toastify"
import {getProfile} from "../../../api/account"
import {useFormik} from "formik"
import * as Yup from "yup"
import {isTwoPrecision, toTwoPrecision, validateFloatValue} from "../../../helpers/utils"
import {useDispatch, useSelector} from "react-redux"
import {staticData} from "../../../api/general"
import {getAccountBalance} from "../../../slices/auth/profile/thunk"
import {useTranslation} from "react-i18next"
import {isEmpty} from "lodash"

const WithdrawalCryptoForm = ({formSentHandler, isReal}) => {
  const dispatch = useDispatch()
  const accountBalance = useSelector(s => s.Profile.accountBalance)
  const [lockedAssetBalance, setLockedAssetBalance] = useState(0)
  const [lockedAssetUsdBalance, setLockedAssetUsdBalance] = useState(0)
  const [useUnlockedBalance, setUseUnlockedBalance] = useState(false)
  const [activeTab, setActiveTab] = useState('')
  const [fees, setFees] = useState([])
  const [assetAmount, setAssetAmount] = useState('')
  const [totalUsdAmount, setTotalUsdAmount] = useState('')
  const [totalWithdraw, setTotalWithdraw] = useState(0.00)
  const [selectedAsset, setSelectedAsset] = useState('')
  const [assets, setAssets] = useState([])
  const [dailyWithdraw, setDailyWithdraw] = useState(1)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [customerPlan, setCustomerPlan] = useState('')
  const [warningMessage, setWarningMessage] = useState('')
  const [blockedTab, setBlockedTab] = useState('')
  const { t } = useTranslation()
  const isViteMaxSum = import.meta.env?.VITE_MAX_SUM_ONLY ?? ''
  const formik = useFormik({
    initialValues: {
      withdrawal_address: '',
      total_usd: '',
      amount: '',
      asset_id: '',
    },
    validationSchema: Yup.object({
      withdrawal_address: Yup.string().required(),
      total_usd: Yup.string().required(),
      amount: Yup.string().required(),
      asset_id: Yup.string().required(),
    }),
    onSubmit: (data) => {
      setIsButtonDisabled(true)
      if (isReal) {
        data = {...data, use_unlocked_balance: true}
      }

      data = {...data, fee_value: activeTab}

      createCryptoWithdraw(data).then(res => {
        let message = res?.message || 'Empty response message.'

        if (res.success) {
          toast.success(message)
          formSentHandler()
          setIsButtonDisabled(false)
          dispatch(getAccountBalance())
          clearForm()
        } else {
          toast.error(message)
          setIsButtonDisabled(false)
        }
      })
    },
  })

  const getFees = (asset_id) => {
    let args = asset_id ? {asset_id: Number(asset_id) ?? null, is_real: isReal ?? false} : null

    staticData('transaction_crypto_fees', args).then(r => {
      setFees(r)
      Object.entries(r).map(([key, value]) => {
        if (value.active) {
          setActiveTab(key)
        }
      })
    })
  }

  const clearForm = () => {
    formik.setFieldValue('total_usd', '')
    formik.setFieldValue('amount', '')
    formik.setFieldValue('asset_id', '')
    setSelectedAsset('')
    setAssetAmount('')
    setTotalUsdAmount('')
    setTotalWithdraw('')
    formik.setFieldValue('withdrawal_address', '')
  }

  const changeSelectedAssetHandler = (selectOptions) => {
    setSelectedAsset(selectOptions)
    formik.setFieldValue('asset_id', selectOptions.value)
    getFees(selectOptions.value)

    if (assetAmount && selectOptions) {
      setTotalUsdAmount((Number(assetAmount) * selectOptions.price).toFixed(2))
    }
  }

  const changeAmountHandler = (e) => {
    formik.setFieldValue('amount', e.target.value)
    const newAmount = e.target.value
    setAssetAmount(newAmount)

    if (selectedAsset) {
      setTotalWithdraw(calcAmount(newAmount))
      setTotalUsdAmount((newAmount * selectedAsset.price).toFixed(2))
    }
  }

  const changeTotalUsdAmountHandler = (e) => {
    formik.setFieldValue('total_usd', e.target.value)
    const newTotal = e.target.value
    setTotalUsdAmount(newTotal)

    if (activeTab && selectedAsset) {
      setAssetAmount((newTotal / selectedAsset.price).toFixed(8))
      setTotalWithdraw(calcAmount(assetAmount))
      formik.setFieldValue('total_usd', newTotal)
    }
  }

  function toggleTab(tab) {
    if (activeTab !== tab && !fees[tab].blocked) {
      setActiveTab(tab)
    }
  }

  const calcFee = (activeTab) => {
    if (activeTab) {
      setDailyWithdraw(fees[activeTab].limit_title)
      return (fees[activeTab].fee / 100) + 1
    }

    return 1
  }

  useEffect(() => {
    if (!isReal && isViteMaxSum === 'true' && !isEmpty(selectedAsset) && activeTab) {
      let data = {
        asset_id: selectedAsset.value,
        fee_value: activeTab
      }

      calculateMaxSumHandler(data)
    }
  }, [activeTab, selectedAsset, isReal])

  const calculateMaxSumHandler = e => {
    let data

    if (!isReal && isViteMaxSum === 'true') {
      data = e
    } else {
      let formEl = e.target.closest('form')
      let formData = Object.fromEntries(new FormData(formEl))

      if (isReal) {
        data = {...formData, use_unlocked_balance: true}
      } else {
        data = formData
      }
    }

    calculateMaxSum(data).then(r => {
      if (r.asset_amount > 0) {
        if (r.success) {
          setAssetAmount(Number(r.asset_amount).toFixed(8) * 1)
          setTotalUsdAmount(r.total_usd_amount_without_fee)
          setTotalWithdraw(r.total_asset_amount)
        } else {
          toast.error(r.message)
        }
      } else {
        toast.warning(r.message)
      }
    })
  }

  const calcAmount = (assetAmount) => {
    return Number(assetAmount * calcFee(activeTab)).toFixed(8) * 1
  }

  useEffect(() => {
    setTotalWithdraw(calcAmount(assetAmount))
  }, [selectedAsset, totalUsdAmount, activeTab])

  useEffect(() => {
    let lockedBalance = accountBalance.filter(i => !!i.is_real === !!isReal)
    let withdrawAsset = lockedBalance?.find(i => Number(i.asset_id) === Number(selectedAsset.value || ''))
    let assets = []
    lockedBalance.forEach(i => {
      if (i.type === 'crypto') {
        assets.push({value: i.asset_id, label: i.code, price: i.price})
      }
    })

    setAssets(assets)
    setLockedAssetBalance(withdrawAsset?.balance || 0)
    setLockedAssetUsdBalance(withdrawAsset?.balance * withdrawAsset?.price || 0)
  }, [accountBalance, selectedAsset, useUnlockedBalance])

  useEffect(() => {
    getFees()

    getProfile().then(r => {
      setCustomerPlan(r.plan)
    })
  }, [])

  useEffect(() => {
    formik.setFieldValue('total_usd', totalUsdAmount)
    formik.setFieldValue('amount', assetAmount)
  }, [totalUsdAmount, assetAmount]);

  useEffect(() => {

    if (fees && blockedTab) {
      setWarningMessage(() => {
        return(
          <Row className={classnames('', {'d-none' : !fees[blockedTab].blocked})}>
            <Col xs={12}>
              <UncontrolledAlert color="warning" key={blockedTab}>
                {t(`${fees[blockedTab]?.blocked_message}`)}
              </UncontrolledAlert>
            </Col>
          </Row>
        )
      })
    }
  }, [blockedTab])

  return (
    <React.Fragment>
      <Form onSubmit={(e) => {
        e.preventDefault()

        if (!formik.values.asset_id) {
          toast.error(t('Please select currency'))
        } else if (!formik.values.amount || !formik.values.total_usd) {
          toast.error(t('Please enter an amount'))
        } else if (!formik.values.withdrawal_address) {
          toast.error(t('Please enter an withdrawal address'))
        } else {
          formik.handleSubmit(e)
        }
      }}
      >
        <div>
          <Row className="mb-3">
            <Col xs={12}>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <span className="text-muted">{t("Available_w")}</span>
                <span className="fs-15">
                  <span>
                    {isTwoPrecision(selectedAsset.label, lockedAssetBalance) + ' '}
                    (${toTwoPrecision(lockedAssetUsdBalance)}) {selectedAsset.label}
                  </span>
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="text-muted me-2">{t("Amount")}</span>
                <span className="fs-15">
                  {t("Remaining Daily Withdrawal Amount")}:
                  <span className="change_limit">
                    {dailyWithdraw === 'Unlimited' ? t('Unlimited') : ` ${dailyWithdraw}`}
                  </span>
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row>
                <Col>
                  <div className="input-group mb-3 d-flex">
                    <Select
                      className="w-100"
                      placeholder={t("Select currency . . . ")}
                      name="asset_id"
                      onChange={changeSelectedAssetHandler}
                      value={selectedAsset}
                      options={assets}
                    />
                  </div>
                  <div className="input-group mb-3 d-flex">
                    <span className="input-group-text w-25">{t("Amount")}</span>
                    <Input
                      type="text"
                      name="amount"
                      placeholder="0.00"
                      className="form-control"
                      autoComplete="off"
                      disabled={!isReal && String(isViteMaxSum) === 'true'}
                      value={assetAmount}
                      onChange={(e) => {
                        let dotInput = validateFloatValue(e.target.value)

                        if (dotInput !== false) {
                          changeAmountHandler(e)
                          setAssetAmount(dotInput)
                        } else {
                          return false
                        }
                      }}
                    />
                    <span className="input-group-text justify-content-center">{selectedAsset.label}</span>
                  </div>
                  <div className="input-group mb-3 d-flex">
                    <span className="input-group-text w-25">{t("Total")}</span>
                    <Input
                      type="text"
                      name="total_usd"
                      placeholder="0.00"
                      className="form-control selected-currency-filed"
                      disabled={!isReal && String(isViteMaxSum) === 'true'}
                      value={totalUsdAmount}
                      onChange={(e) => {
                        let dotInput = validateFloatValue(e.target.value)

                        if (dotInput !== false) {
                          changeTotalUsdAmountHandler(e)
                          setTotalUsdAmount(dotInput)
                        } else {
                          return false
                        }
                      }}
                    />
                    <span className="input-group-text justify-content-center">USD</span>
                  </div>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col xs={12}>
                  <div>
                    <Link
                      to=""
                      className="text-success"
                      onClick={(e) => {
                        if (!isReal && isViteMaxSum === 'true') {
                          return
                        }

                        calculateMaxSumHandler(e)
                      }}
                    >
                      {t("Max sum")}
                    </Link>
                  </div>
                </Col>
              </Row>
              {customerPlan === 'network overloaded' &&
              <Row>
                <Col xs={12}>
                  <div role="alert" className="alert alert-warning">
                    {t("Network is busy. Gas prices are high and estimates are less accurate.")}
                  </div>
                </Col>
              </Row>}

              {warningMessage}

              <Row>
                <Col className="d-flex justify-content-between">
                  <div>
                    <h3 className="fw-bold">{t("Fee")}</h3>
                  </div>
                  <div className="border-1">
                    <Nav
                      className="custom-nav nav-justified square border border-success rounded"
                      role="tablist"
                    >
                      {fees &&
                        Object.keys(fees).map((fee) =>
                          <NavItem key={fee}>
                            <NavLink
                              href="#"
                              id="steparrow-gen-info-tab"
                              className={classnames({
                                active: activeTab === fee,
                                "bg-success": activeTab === fee,
                              })}
                              onClick={() => {
                                toggleTab(fee)
                                setBlockedTab(fee)
                              }}
                            >
                              {t(`${fee.capitalize()}`)}
                            </NavLink>
                          </NavItem>)
                      }
                    </Nav>
                  </div>
                </Col>
              </Row>
              <Row className="mb-3 mt-1">
                <Col>
                  <TabContent activeTab={activeTab}>
                    {Object.keys(fees).map((fee) =>
                      <TabPane key={fee} id="steparrow-gen-info" tabId={fee}>
                        <div>
                          <Row>
                            <Col className="d-flex justify-content-between">
                              <span className="fs-14">
                                {t("Withdrawal Fee")}: {fees[fee].fee}% ({toTwoPrecision(totalUsdAmount * (Number(fees[fee].fee) / 100))}$)
                              </span>
                              <span className="fs-14">{t("Estimated time")}: {t(fees[fee].time)}</span>
                            </Col>
                          </Row>
                        </div>
                      </TabPane>
                    )}
                  </TabContent>
                </Col>
              </Row>
              <Row className="mb-3 border-top">
                <Col className="lh-lg">
                  <div className="pt-1">
                    <span className="fs-16"
                    >
                     {t("Withdrawal Amount")}:
                      <span className="float-end">
                        {isTwoPrecision(selectedAsset.code, assetAmount)} {selectedAsset.label}
                      </span>
                    </span>
                  </div>
                  {isReal &&
                    <div>
                      <span className="fs-16 fw-semibold">
                        {t("You receive")}:
                        <span className="float-end">
                          {isTwoPrecision(selectedAsset.label, (assetAmount - (assetAmount *
                              (Number(fees[activeTab]?.fee) / 100))))} {selectedAsset.label}
                        </span>
                      </span>
                    </div>
                  }
                  {!isReal &&
                    <>
                      <div>
                        <span className="fs-16">
                          {t("Paying from Available Balance")}:
                          <span className="float-end">
                            {isTwoPrecision(selectedAsset.label, (assetAmount * (Number(fees[activeTab]?.fee) / 100)))} {selectedAsset.label}
                          </span>
                        </span>
                      </div>
                      <div>
                        <span className="fs-16 fw-semibold">
                          {t("Total Amount")}:
                          <span className="float-end">
                            {isTwoPrecision(selectedAsset.label, totalWithdraw)} {selectedAsset.label}
                          </span>
                        </span>
                      </div>
                    </>
                  }
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <div>
                    <label htmlFor="form-label">{t("Withdraw Address")}</label>
                    <Input
                      type="text"
                      name="withdrawal_address"
                      className="form-control"
                      value={formik.values.withdrawal_address}
                      onChange={formik.handleChange}
                      autoComplete="off"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    type="submit"
                    disabled={isButtonDisabled}
                    className="btn w-100 fs-16 btn-soft-success text-uppercase"
                  >
                    {t("Withdraw")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <input type="hidden" name="fee_value" value={activeTab}></input>
      </Form>
    </React.Fragment>
  )
}

export default WithdrawalCryptoForm
