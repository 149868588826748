import {DropdownItem} from "reactstrap"
import classnames from "classnames"
import React, {useEffect, useMemo, useRef, useState} from "react"
import {toggleNotification} from "../../api/notifications"
import {prettyDate} from "../../helpers/utils"
import BaseTable from "../../Components/Tables/BaseTable"
import BaseActionDropdown from "../../Components/Tables/Actions/BaseActionDropdown"
import {useTranslation} from "react-i18next"
import {useSelector} from "react-redux"

function NotificationsTable({togglePageButtons, updateBellNotifications}) {
	const baseTableMethodsRef = useRef(null)
	const [selectedRows, setSelectedRows] = useState([])
  const { t } = useTranslation()
  const notifications = useSelector(s => s.Notification.notifications)
  const [isFirstCalling, setIsFirstCalling] = useState(true)

	const selectedRowsHandler = (row) => {
		setSelectedRows(row)
	}

  const markNotificationHandler = (ids) => {
    togglePageButtons(true)
		toggleNotification(ids).then(() => {
      updateBellNotifications()
      // updateTable()
    })
	}

	const columns = useMemo(
		() => [
			{
				id: "id",
				name: <span className='font-weight-bold fs-14'>ID</span>,
				selector: row => row.id,
        width: "320px",
			},
			{
				id: "title",
				name: <span className='font-weight-bold fs-14'>{t("Title")}</span>,
				selector: row => t(row.data.title, {tr_id: row.data.tr_id}),
				width: "250px",
			},
			{
				id: "sub_title",
				name: <span className='font-weight-bold fs-14'>{t("Subtitle")}</span>,
				selector: row => t(row.data.sub_title,
          {
            tr_id: row.data.tr_id,
            tr_status: t(row.data.tr_status),
            sold_asset: row.data.sold_asset,
            buy_asset: row.data.buy_asset,
            doc_type: row.data.doc_type,
            invoice_id: row.data.invoice_id
          }
        ),
				width: "400px",
			},
			// {
			// 	id: "message",
			// 	name: <span className='font-weight-bold fs-14'>Message</span>,
			// 	selector: row => row.data?.message ? row.data?.message : '-',
			// },
			{
				id: "created_at",
				name: <span className='font-weight-bold fs-14'>{t("Created At")}</span>,
				selector: row => prettyDate(row.created_at),
        width: "200px",
			},
			{
				id: "status",
				name: <span className='font-weight-bold fs-14'>{t("Status")}</span>,
				selector: row =>
					<span className={`w-100 badge bg-${!!row.read_at ? 'soft-success' : 'soft-warning'}
						text-${!!row.read_at ? 'success' : 'warning'}`}>{!!row.read_at ? t('Read') : t('Unread')}
					</span>,
        width: "120px",
			},
      {
        id: "actions",
        name: <span className='font-weight-bold fs-14'>{t("Actions")}</span>,
        selector: row =>
          <>
            <button
              type="submit"
                className={classnames('btn', 'btn-sm', 'w-100', {
                  'btn-warning': !!row.read_at,
                  'btn-success': !row.read_at
                })}
                onClick={() => markNotificationHandler([row.id])}
              >
                {!!row.read_at ? t("Mark as unread") : t("Mark as read")}
              </button>
          </>
      },
		], [markNotificationHandler, t])

  const updateTable = () => {
    baseTableMethodsRef.current.updateTable()
  }

  useEffect(() => {
    if (!isFirstCalling) {
      updateTable()
    } else (
      setIsFirstCalling(false)
    )
  }, [notifications])

  const contextActions = (
		<BaseActionDropdown>
			<DropdownItem onClick={() => {
        markNotificationHandler(selectedRows.map(i => i.id))
      }}>
        {t("Mark as read")}
      </DropdownItem>
			<DropdownItem onClick={() => {
        markNotificationHandler(selectedRows.map(i => i.id))
      }}>
        {t("Mark as unread")}
      </DropdownItem>
		</BaseActionDropdown>
	)

  return (
		<BaseTable
			columns={columns}
			title={t("Notifications")}
			url="/spa/notifications"
			methodsRef={baseTableMethodsRef}
			actions={contextActions}
			selectedRowsHandler={selectedRowsHandler}
		/>
	)
}

export default NotificationsTable
