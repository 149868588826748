import React, {useEffect, useState} from "react"
import BreadCrumb from "../../Components/Common/BreadCrumb"
import {Button, Card, CardBody, CardHeader, Col, Container, Form, Label, Row} from "reactstrap"
import {toast} from "react-toastify"
import {useNavigate, useParams} from "react-router-dom"
import {closeTicket, commentTicket, getTicket} from "../../api/support"
import {prettyDate} from "../../helpers/utils";

const SingleTicket = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [conversation, setConversation] = useState(null)
  const [ticketStatus, setTicketStatus] = useState(4)
  const [conversationData, setConversationData] = useState([])

  const submitHandler = (e) => {
    e.preventDefault()
    const btn = e.target.querySelector('[type="submit"]')
    btn.disabled = true

    let data = new FormData(e.target)

    commentTicket(id, data).then(r => {
      if (r.success) {
        getData()
        toast.success(r.message)
        e.target.reset()
      } else {
        toast.error(r.message)
      }
    }).catch(e => {
      toast.error(e)
    }).finally(() => btn.disabled = false)
  }

  const closeTicketHandler = (e) => {
    e.target.disabled = true;

    closeTicket(id).then(r => {
      if (r.success) {
        toast.success(r.message)
        navigate('/support')
      } else {
        toast.error(r.message)
      }
    }).catch(e => {
      toast.error(e)
    })
  }

  const getData = () => {
    getTicket(id).then(r => setConversation(r))
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    let data = []

    if (conversation?.comments?.length > 0) {
      data = conversation.comments.map((item, index) => {
        return <div key={index} className="d-flex align-items-start text-muted mb-3">
          <div className="flex-grow-1">
            <hr className="w-100 mt-0" />
            <h5 className="fs-14">
              {item.user_id === conversation?.customer.id ? conversation.customer.name : 'Support Team'}
              <span className="text-muted ps-2 fs-12">{prettyDate(item.created_at)}</span>
            </h5>
            <p className="mb-0 keep-lines">{item.message}</p>
          </div>
        </div>
      })
    }

    if (conversation) {
      setTicketStatus(Number(conversation.status))
    }

    setConversationData(data)
  }, [conversation])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Support" pageTitle="Single Ticket" />
          <Row>
            <Col xs={12} className="col-12 offset-xl-1 col-xl-10 offset-xxl-2 col-xxl-8 mb-4">
              <div className="hstack justify-content-between">
                <Button color="primary" onClick={() => {navigate('/support')}}>Back</Button>
                {ticketStatus !== 4 &&
                <Button
                  className="btn-danger"
                  onClick={(e) => {
                    closeTicketHandler(e)
                  }}
                >
                  Close ticket
                </Button>}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 offset-xl-1 col-xl-10 offset-xxl-2 col-xxl-8 mb-4">
              <Card>
                {conversation ?
                  <>
                    <CardHeader><h5>Subject: {conversation.subject}</h5></CardHeader>
                    <CardBody>
                      <div className="d-flex align-items-start text-muted mb-4">
                        <div className="flex-grow-1">
                          <h5 className="fs-14">
                            {conversation.customer.name}
                            <span className="text-muted ps-2 fs-12">
                              Created: {prettyDate(conversation.created_at)}
                            </span>
                          </h5>
                          <p className="mb-0 keep-lines">{conversation.message}</p>
                        </div>
                      </div>
                      {conversationData && conversationData}
                      <Row className="pt-1 fs-12">
                        <Col className="text-end col-12">
                          <span className="text-muted">
                            Status: <span className={'fs-12 badge bg-' + conversation.status_color}>
                              {conversation.status_name}
                            </span>
                          </span>
                        </Col>
                      </Row>
                    </CardBody>
                  </>
                  : <CardHeader><h5>Loading...</h5></CardHeader>
                }
              </Card>
            </Col>
          </Row>
          {ticketStatus !== 4 &&
            <Row>
              <Col className="col-12 offset-xl-1 col-xl-10 offset-xxl-2 col-xxl-8 mb-4">
                <Form onSubmit={submitHandler}>
                  <div className="mb-3">
                    <Label htmlFor="ticketComment" className="form-label">Message</Label>
                    <textarea name="comment" className="form-control" id="ticketComment" rows="3" placeholder="Enter your message"></textarea>
                  </div>
                  <Button type="submit" color="primary" className="btn w-100 mb-5">SEND</Button>
                </Form>
              </Col>
            </Row>
          }
        </Container>
      </div>
    </React.Fragment>
  );
}

export default SingleTicket
