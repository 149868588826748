import {Button, Col, Form, Input, Label, Row} from "reactstrap";
import React, {useState} from "react";
import {changePassword} from "../../api/account";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next"

const ChangePasswordForm = () => {
  const { t } = useTranslation()
  const [isOldPassShow, setIsOldPassShow] = useState(false)
  const [isNewPassShow, setIsNewPassShow] = useState(false)
  const [isConfirmPassShow, setIsConfirmPassShow] = useState(false)

	const submitHandler = e => {
		e.preventDefault()

		const formData = new FormData(e.target)
		const data = Object.fromEntries(formData.entries())

		changePassword(data)
			.then(r => {
				if (r.success) {
					toast.success(r.message)
					e.target.reset()
				} else {
					toast.error(r.message)
				}
			})
			.catch(r => {
				if (r.errors) {
					Object.entries(r.errors).forEach(entry => {
						const [key, value] = entry
						value.forEach(i => toast.error(i))
					})
				}
			})
	}

	return (
		<>
			<Form onSubmit={submitHandler}>
				<Row className="g-2">
					<Col lg={4}>
						<div>
							<Label htmlFor="oldpasswordInput" className="form-label">
                {t("Old Password")}
                <sup className="text-danger">*</sup>
              </Label>
							<div className="position-relative auth-pass-inputgroup">
                <Input
                  name="old_password"
                  type={isOldPassShow ? "text" : "password"}
                  className="form-control"
                  id="oldpasswordInput"
                  placeholder={t("Enter current password")}
                />
                <Button
                  color="link"
                  onClick={() => setIsOldPassShow(!isOldPassShow)}
                  className="position-absolute end-0 top-0 text-decoration-none text-muted"
                  type="button"
                >
                  <i className="ri-eye-fill align-middle"></i>
                </Button>
              </div>
						</div>
					</Col>

					<Col lg={4}>
						<div>
							<Label htmlFor="newpasswordInput" className="form-label">
                {t("New Password")}
                <sup className="text-danger">*</sup>
              </Label>
              <div className="position-relative auth-pass-inputgroup">
                <Input
                  name="new_password"
                  type={isNewPassShow ? "text" : "password"}
                  className="form-control"
                  id="newpasswordInput"
                  placeholder={t("Enter new password")}
                />
                <Button
                  color="link"
                  onClick={() => setIsNewPassShow(!isNewPassShow)}
                  className="position-absolute end-0 top-0 text-decoration-none text-muted"
                  type="button"
                >
                  <i className="ri-eye-fill align-middle"></i>
                </Button>
              </div>
						</div>
					</Col>

					<Col lg={4}>
						<div>
							<Label htmlFor="confirmpasswordInput" className="form-label">
                {t("Confirm Password")}
                <sup className="text-danger">*</sup>
              </Label>
              <div className="position-relative auth-pass-inputgroup">
                <Input
                  name="new_password_confirmation"
                  type={isConfirmPassShow ? "text" : "password"}
                  className="form-control"
                  id="confirmpasswordInput"
                  placeholder={t("Confirm password")}
                />
                <Button
                  color="link"
                  onClick={() => setIsConfirmPassShow(!isConfirmPassShow)}
                  className="position-absolute end-0 top-0 text-decoration-none text-muted"
                  type="button"
                >
                  <i className="ri-eye-fill align-middle"></i>
                </Button>
              </div>
						</div>
					</Col>

					{/*<Col lg={12}>
            <div className="mb-3">
              <Link to="#" className="link-primary text-decoration-underline">Forgot Password ?</Link>
            </div>
          </Col>*/}

					<Col lg={12}>
						<div className="text-end mt-3">
							<button type="submit" className="btn btn-success">{t("Change Password")}</button>
						</div>
					</Col>
				</Row>
			</Form>
		</>
	)
}

export default ChangePasswordForm
