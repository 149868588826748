import React, {useState, useEffect, useRef} from 'react';
import {BalanceChart} from '../pages/DashboardCrypto/DashboardCryptoCharts';
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import {cryptoIconsMapper, isTwoPrecision, toTwoPrecision} from "../helpers/utils";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next"

const AccountBalance = () => {
  const accountBalance = useSelector(s => s.Profile.accountBalance)

  const { t } = useTranslation()

  const [lockedBalance, setLockedBalance] = useState([])
  const [unlockedBalance, setUnlockedBalance] = useState([])

  const [chartData, setChartData] = useState([])
  const [chartLabels, setChartLabels] = useState([])

  const [cardHeaderTab, setCardHeaderTab] = useState('unlocked')
  const [loading, setLoading] = useState(true)

  const chartColors= useRef([
    'primary',
    'info',
    'warning',
    'success',
    'danger',
  ])

  const prepareBalance = (r) => {
    let lockedBalance = r.filter(i => i.is_real === 0)
    let unlockedBalance = r.filter(i => i.is_real === 1)

    setLockedBalance(lockedBalance)
    setUnlockedBalance(unlockedBalance)
    setLoading(false)
  }

  useEffect(() => {
    prepareBalance(accountBalance)
  }, [accountBalance])

  useEffect(() => {
    if (cardHeaderTab === 'locked') {
      setChartData(lockedBalance.map(i => Number(i.balance * i.price)))
      setChartLabels(lockedBalance.map(i => i.code));
    } else {
      setChartData(unlockedBalance.map(i => Number(i.balance * i.price)))
      setChartLabels(unlockedBalance.map(i => i.code))
    }
  }, [cardHeaderTab, lockedBalance, unlockedBalance])

  return (
    <React.Fragment>
      <div className="col-sm-12">

        <div className="card card card-height-100" style={{minHeight: 520}}>
          <div className="card-header border-0 d-flex">
            <p>{t("Balance")}</p>

            <Nav tabs className="nav justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0 ms-auto">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: cardHeaderTab === "unlocked", })}
                  onClick={() => { setCardHeaderTab("unlocked"); }}
                >
                  {t("Available")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: cardHeaderTab === "locked", })}
                  onClick={() => { setCardHeaderTab("locked"); }}
                >
                  {t("Locked")}
                </NavLink>
              </NavItem>
            </Nav>

          </div>
          <div className="card-body">
            {chartData && chartData.length ?
              (<BalanceChart
                series={chartData}
                labels={chartLabels}
                dataColors='["--vz-primary", "--vz-info", "--vz-warning", "--vz-success", "--vz-danger"]'
              />) : null}

            <TabContent activeTab={cardHeaderTab} className="text-muted">
              <TabPane tabId="locked" id="locked_balance_tab">
                <ul className="list-group list-group-flush border-dashed mb-0 mt-3 pt-2">
                  {lockedBalance.length ? lockedBalance.map((asset, idx) => (
                    <li className="list-group-item px-0" key={idx}>
                      <div className="d-flex">
                        <div className="flex-shrink-0 avatar-xs">
                          <span className="avatar-title bg-light p-1 rounded-circle">
                            <img
                              src={cryptoIconsMapper[asset.code]}
                              className="img-fluid"
                              alt={asset.name + 'asset icon'}
                            />
                          </span>
                        </div>
                        <div className="flex-grow-1 ms-2">
                          <h6 className="mb-1">{asset.name}</h6>
                          <p className="fs-12 mb-0 text-muted">
                            <i className={`mdi mdi-circle fs-10 align-middle text-${chartColors.current[(idx % chartColors.current.length)]} me-1`}></i>
                            {asset.code}
                          </p>
                        </div>
                        <div className="flex-shrink-0 text-end">
                          <h6 className="mb-1">{isTwoPrecision(asset.code, asset?.balance || 0)}</h6>
                          <p className="text-success fs-12 mb-0">${toTwoPrecision(asset?.balance * asset?.price) || 0}</p>
                        </div>
                      </div>
                    </li>
                  )) : (
                    <li className="list-group-item fs-24 text-center">
                      {loading ? t("Loading...") : t("No balance")}
                    </li>
                  )}
                </ul>
              </TabPane>

              <TabPane tabId="unlocked" id="unlocked_balance_tab">
                <ul className="list-group list-group-flush border-dashed mb-0 mt-3 pt-2">
                  {unlockedBalance.length ? (
                    unlockedBalance.map((asset, idx) => (
                    <li className="list-group-item px-0" key={idx}>
                      <div className="d-flex">
                        <div className="flex-shrink-0 avatar-xs">
                    <span className="avatar-title bg-light p-1 rounded-circle">
                        <img src={cryptoIconsMapper[asset.code]} className="img-fluid" alt={asset.name + 'asset icon'}/>
                    </span>
                        </div>
                        <div className="flex-grow-1 ms-2">
                          <h6 className="mb-1">{asset.name}</h6>
                          <p className="fs-12 mb-0 text-muted">
                            <i className={`mdi mdi-circle fs-10 align-middle text-${chartColors.current[(idx % chartColors.current.length)]} me-1`}></i>
                            {asset.code}
                          </p>
                        </div>
                        <div className="flex-shrink-0 text-end">
                          <h6 className="mb-1">{isTwoPrecision(asset.code, asset?.balance || 0)}</h6>
                          <p className="text-success fs-12 mb-0">${toTwoPrecision(asset?.balance * asset?.price) || 0}</p>
                        </div>
                      </div>
                    </li>
                    ))
                  ) : (
                    <li className="list-group-item fs-24 text-center">
                      {loading ? t("Loading...") : t("No balance")}
                    </li>
                  )}
                </ul>
              </TabPane>
            </TabContent>
          </div>
        </div>

      </div>
    </React.Fragment>
  )
}

export default AccountBalance;
