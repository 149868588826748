import { createSlice } from "@reduxjs/toolkit";
import {getBalanceData} from "./thunk";

export const initialState = {
    balance: [],
    error: null
};

const AccountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getBalanceData.fulfilled, (state, action) => {
            state.balance = action.payload;
        });
        builder.addCase(getBalanceData.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });
    }
});

// export const {} = AccountSlice.actions;

export default AccountSlice.reducer;
