import React, {useState, useRef, useEffect} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {Card, Col, Container, Row, Form, Input, Button} from 'reactstrap'
import classnames from "classnames"
import {registration} from '../../../api/auth'
import HCaptcha from "@hcaptcha/react-hcaptcha"
import {toast} from "react-toastify"
import {useTranslation} from "react-i18next"

import AuthSlider from '../authCarousel'

// Toastify
import {ToastContainer} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'

//formik
import {useFormik} from 'formik'
import * as Yup from 'yup'
import LanguageDropdown from "../../../Components/Common/LanguageDropdown";

const CoverSignUp = () => {
  const navigate = useNavigate()

  const [token, setToken] = useState(null)
  const captchaRef = useRef(null)
  const { t } = useTranslation()

  const [passwordShow, setPasswordShow] = useState(false)
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false)
  const [validPassword, setValidPassword] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passDoNotMatch, setPassDoNotMatch] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [validLastName, setValidLastName] = useState(false)
  const [validName, setValidName] = useState(false)
  const [validEmail, setValidEmail] = useState(false)

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      password: "",
      name: "",
      last_name: "",
      email: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, t("Name is too short"))
        .max(15, t("Name is too long"))
        .required(t("This field is required")),
      last_name: Yup.string()
        .min(2, t("Second name is too short"))
        .max(15, t("Second name is too long"))
        .required(t("This field is required")),
      email: Yup.string()
        .required(t("This field is required")),
      password: Yup.string()
        .min(8, t('Password must be at least 8 characters'))
        .matches(RegExp('(.*[a-z].*)'), t('At least lowercase letter'))
        .matches(RegExp('(.*[A-Z].*)'), t('At least uppercase letter'))
        .matches(RegExp('(.*[0-9].*)'), t('At least one number'))
        .required(t("This field is required")),
    }),
    onSubmit: (data) => {
      setIsButtonDisabled(true)

      data = {...data, 'h-captcha-response': token, 'password_confirmation': confirmPassword}

      if (data.password !== confirmPassword) {
        setPassDoNotMatch(true)
        setIsButtonDisabled(false)
      } else {
        registration(data).then(r => {
          if (r.success) {
            setPassDoNotMatch(false)
            setIsButtonDisabled(false)
            navigate('/auth-success-msg-cover')
          } else {
            Object.entries(r.errors).forEach(entry => {
              const [key, value] = entry
              value.forEach(error => {
                toast.error(t(error))
              })
            })
            setIsButtonDisabled(false)
          }
        })
      }
      setIsButtonDisabled(false)

    }
  })

  const onLoad = () => {
    // this reaches out to the hCaptcha JS API and runs the
    // execute function on it. you can use other functions as
    // documented here:
    // https://docs.hcaptcha.com/configuration#jsapi
    captchaRef.current.execute()
  }

  document.title = `${t("Sign Up")} | ${import.meta.env.VITE_APP_NAME}`
  return (
    <React.Fragment>
      <ToastContainer/>
      <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div className="bg-overlay"></div>
        <div className="auth-page-content overflow-hidden pt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <Card className="overflow-hidden m-0">
                  <Row className="justify-content-center g-0">

                    <AuthSlider/>

                    <Col lg={6}>
                      <div className="p-lg-5 p-4">
                        <div className="position-relative">
                          {(import.meta.env.VITE_MULTILANGUAGE === 'true') &&
                            <div className="position-absolute" style={{top: '-25px', right: '-7px'}}>
                              <LanguageDropdown/>
                            </div>
                          }
                          <h5 className="text-primary">{t("Create Your Account")}</h5>
                        </div>

                        <div className="mt-4">
                          <Form onSubmit={(e) => {
                            e.preventDefault()

                            if (validation.errors.name && validation.errors.name.length > 0) {
                              setValidName(true)
                            } else if (validation.errors.last_name && validation.errors.last_name.length > 0) {
                              setValidLastName(true)
                            } else if (validation.errors.email && validation.errors.email.length > 0) {
                              setValidEmail(true)
                            } else if (validation.errors.password && validation.errors.password.length > 0) {
                              setValidPassword(true)
                            } else {
                              validation.handleSubmit(e)
                            }
                          }}>

                            <div className="mb-3">
                              <label className="form-label mb-0">
                                {t("First Name")}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className={classnames('form-control', {
                                  'border-danger': validName
                                })}
                                name="name"
                                value={validation.values.name}
                                // onBlur={validation.handleBlur}
                                onChange={(e) => {
                                  validation.handleChange(e)
                                  setValidName(false)
                                }}
                                placeholder={t("Enter first name")}
                                autoComplete="off"
                              />
                              {validName &&
                                <span className="mt-0 text-danger fs-11"> {validation.errors.name} </span>
                              }
                            </div>
                            <div className="mb-3">
                              <label className="form-label mb-0">
                                {t("Last Name")}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className={classnames('form-control', {
                                  'border-danger': validLastName
                                })}
                                name="last_name"
                                value={validation.values.last_name}
                                onChange={(e) => {
                                  validation.handleChange(e)
                                  setValidLastName(false)
                                }}
                                placeholder={t("Enter last name")}
                                autoComplete="off"
                              />
                              {validLastName &&
                                <span className="mt-0 text-danger fs-11"> {validation.errors.last_name} </span>
                              }
                            </div>

                            <div className="mb-3">
                              <label className="form-label mb-0">
                                {t("Email")}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className={classnames('form-control', {
                                  'border-danger': validEmail
                                })}
                                name="email"
                                value={validation.values.email}
                                onChange={(e) => {
                                  validation.handleChange(e)
                                  setValidEmail(false)
                                }}
                                placeholder={t("Enter email address")}
                                autoComplete="off"
                              />
                              {validEmail &&
                                <span className="mt-0 text-danger fs-11"> {validation.errors.email} </span>
                              }
                            </div>

                            <div className="mb-3">
                              <label className="form-label mb-0">
                                {t("Password")}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="position-relative auth-pass-inputgroup">
                                <Input
                                  type={passwordShow ? "text" : "password"}
                                  className={classnames('form-control pe-5 password-input', {
                                    'border-danger': validPassword
                                  })}
                                  placeholder={t("Enter password")}
                                  name="password"
                                  value={validation.values.password}
                                  onBlur={() => {
                                    if (validation.errors.password && validation.errors.password.length > 0) {
                                      setValidPassword(true)
                                    }

                                    validation.handleBlur
                                  }}
                                  onChange={(e) => {
                                    validation.handleChange(e)
                                    setValidPassword(false)
                                  }}
                                  // invalid={!!(validation.errors.password && validation.touched.password)}
                                />
                                {validPassword &&
                                  <span className="mt-0 text-danger fs-11"> {validation.errors.password} </span>
                                }
                                <Button
                                  color="link"
                                  onClick={() => setPasswordShow(!passwordShow)}
                                  className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                  type="button"
                                  id="password-addon"
                                >
                                  <i className="ri-eye-fill align-middle"></i>
                                </Button>
                              </div>
                            </div>

                            <div className="mb-3">
                              <label className="form-label mb-0">
                                {t("Confirm password")}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="position-relative auth-pass-inputgroup">
                                <Input
                                  type={confirmPasswordShow ? "text" : "password"}
                                  className={classnames('form-control pe-5 password-input', {
                                    'border-danger': passDoNotMatch
                                  })}
                                  placeholder={t("Confirm password")}
                                  value={confirmPassword}
                                  onChange={e => {
                                    setConfirmPassword(e.target.value)
                                    setPassDoNotMatch(false)
                                  }}
                                  autoComplete="off"
                                />
                                {passDoNotMatch &&
                                  <span className="mt-0 text-danger fs-11">
                                    {t("Passwords do not match")}
                                  </span>
                                }
                                <Button
                                  color="link"
                                  onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}
                                  className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                  type="button"
                                  id="password-addon"
                                >
                                  <i className="ri-eye-fill align-middle"></i>
                                </Button>
                              </div>
                            </div>

                            <HCaptcha
                              sitekey={import.meta.env.VITE_H_CAPTCHA_SITE_KEY}
                              // onLoad={onLoad}
                              data-open-callback={onLoad}
                              onVerify={setToken}
                              ref={captchaRef}
                            />

                            <div className="mt-4">
                              <button
                                className="btn btn-success w-100"
                                type="submit"
                                disabled={isButtonDisabled}
                              >
                                {t("Create an account")}
                              </button>
                            </div>

                          </Form>
                        </div>

                        <div className="mt-5 text-center">
                          <p className="mb-0">
                            {t("Already have an account")} ?
                            <Link
                              to="/login"
                              className="fw-semibold text-primary ms-2 text-decoration-underline"
                            >
                              {t("Sign In")}
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>

            </Row>
          </Container>
        </div>

        <footer className="footer">
          <Container>
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center">
                  <p className="mb-0">{new Date().getFullYear()} © {import.meta.env.VITE_APP_NAME}</p>
                </div>
              </div>
            </div>
          </Container>
        </footer>
      </div>
    </React.Fragment>
  )
}

export default CoverSignUp
