import BaseTable from "../../Components/Tables/BaseTable"
import React, {useMemo, useRef, useState} from "react"
import {balancePay, cryptoPay} from "../../api/invoice"
import {toast} from "react-toastify"
import {useNavigate} from "react-router-dom"
import {Button} from "reactstrap"
import {prettyDate} from "../../helpers/utils"
import {useDispatch} from "react-redux"
import {getAccountBalance} from "../../slices/auth/profile/thunk"
import {useTranslation} from "react-i18next"

const InvoiceTable = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selectedRows, setSelectedRows] = useState([])
  const baseTableMethodsRef = useRef(null)
  const { t } = useTranslation()

  const columns = useMemo(
    () => [
      {
        id: "id",
        name: <span className='font-weight-bold fs-14'>{t("Invoice ID")}</span>,
        selector: (row) => (
          <a onClick={() => {navigate(`/invoices/show/${row.id}`)}} className="cursor-pointer">
            {row.uniq_id}
          </a>
        ),
        sortable: true,
      },
      {
        id: "status",
        name: <span className='font-weight-bold fs-14'>{t("Status")}</span>,
        selector: row => (<span className={'badge badge-soft-' + row.status_color}>{t(`${row.status_upper}`)}</span>
        ),
      },
      {
        id: "invoicing_date",
        name: <span className='font-weight-bold fs-14'>{t("Invoicing Date")}</span>,
        selector: row => prettyDate(row.formatted_invoicing_date, false),
      },
      {
        id: "due_date",
        name: <span className='font-weight-bold fs-14'>{t("Due Date")}</span>,
        selector: row => prettyDate(row.formatted_due_date, false),
      },
      {
        id: "payout_asset",
        name: <span className='font-weight-bold fs-14'>{t("Payout Asset")}</span>,
        selector: row => row.formatted_payout_asset,
      },
      {
        id: "amount",
        name: <span className='font-weight-bold fs-14'>{t("Amount")}</span>,
        selector: row => row.formatted_total_amount,
      },
      {
        id: "actions",
        width: "240px",
        name: <span className='font-weight-bold fs-14'>{t("Actions")}</span>,
        selector: row => (
          <>
            <div className="d-flex flex-nowrap">
              <Button
                disabled={['paid', 'declined'].includes(row.status)}
                className="btn-sm me-2"
                color="primary"
                onClick={() => cryptoPayHandler(row.id)}
              >
                {t("Top Up")}
              </Button>
              <Button
                disabled={['paid', 'declined'].includes(row.status)}
                className="btn-sm btn-success"
                onClick={() => balancePayHandler(row.id)}
              >
                {t("Pay from balance")}
              </Button>
            </div>
          </>
        ),
      },
    ], [t])

  const updateTable = () => {
    baseTableMethodsRef.current.updateTable()
  }

  const cryptoPayHandler = (id) => {
    if (confirm(t('Do you want to pay by crypto?'))) {
      cryptoPay(id).then(r => {
        let message = r?.message || 'Empty response message.'

        if (r.success) {
          toast.success(r.message)
          if (r.redirect) {
            navigate('/deposit/show/' + r.transaction_id)
          }
          navigate('/deposit/show/' + r.transaction_id)
        } else {
          toast.error(message)
        }
      })
    }
  }

  const balancePayHandler = (id) => {
    if (confirm(t('Do you want to pay from your balance?'))) {
      balancePay(id).then(r => {
        let message = r?.message || 'Empty response message.'

        if (r.success) {
          toast.success(r.message)
          updateTable()
          dispatch(getAccountBalance())
        } else {
          toast.error(message)
        }
      })
    }
  }

  const selectedRowsHandler = (row) => {
    setSelectedRows(row)
  }

  return (
    <BaseTable
      columns={columns}
      title={t("Invoices")}
      url="/spa/invoices/data"
      selectedRowsHandler={selectedRowsHandler}
      methodsRef={baseTableMethodsRef}
    />
  )
}

export default InvoiceTable
