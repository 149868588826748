import {APIClient} from "../helpers/api_helper"

const api = new APIClient()

export const getBalance = (separated = true) => {
    return api.get('/spa/account-balance', {separated: Number(separated)})
}

export const getProfile = () => {
    return api.get('/spa/profile', {})
}

export const getProfileInfo = () => {
    return api.get('/spa/profile/info', {})
}

export const updateProfile = data => {
    return api.post('/spa/profile/update', data)
}

export const changePassword = data => {
    return api.post('/spa/profile/change-password', data)
}

export const getDocuments = () => {
    return api.get('/spa/profile/documents', {})
}

export function addDocuments(data) {
    return api.create('/spa/profile/add-documents', data)
}

export function changeAvatar(data) {
    return api.create('/spa/profile/change-avatar', data)
}

export function changeLanguage(data) {
  return api.create('/spa/profile/change-language', data)
}
