import React, {useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import {getProfile} from "../api/account"

const Navdata = () => {
  const history = useNavigate()
  //state data
  const [isDashboard, setIsDashboard] = useState(false)
  const [isExchange, setIsExchange] = useState(false)
  const [isDeposit, setIsDeposit] = useState(false)
  const [isInvoices, setIsInvoices] = useState(false)
  const [isSupport, setIsSupport] = useState(false)
  const [isTransactions, setIsTransactions] = useState(false)
  const [isWithdraw, setIsWithdraw] = useState(false)
  const [customerPlan, setCustomerPlan] = useState('')

  const [isCurrentState, setIsCurrentState] = useState('Dashboard')

  const getProfileData = () => {
    getProfile().then((r) => {
      setCustomerPlan(r.plan)
    })
  }

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu")
      const iconItems = ul.querySelectorAll(".nav-icon.active")
      let activeIconItems = [...iconItems]
      activeIconItems.forEach((item) => {
        item.classList.remove("active")
        let id = item.getAttribute("subitems")
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show")
      })
    }
  }

  useEffect(() => {
    document.body.classList.remove('twocolumn-panel')
    if (isCurrentState !== 'Dashboard') {
      setIsDashboard(false)
    }
    if (isCurrentState !== 'Exchange') {
      setIsExchange(false)
    }
    if (isCurrentState !== 'Deposit') {
      setIsDeposit(false)
    }
    if (isCurrentState !== 'Withdraw') {
      setIsWithdraw(false)
    }
    if (isCurrentState !== 'Invoices') {
      setIsInvoices(false)
    }
    if (isCurrentState !== 'Support') {
      setIsSupport(false)
    }
  }, [
    history,
    isCurrentState,
    isDashboard,
    isExchange,
    isDeposit,
    isInvoices,
    isSupport,
  ])

  useEffect(() => {
    getProfileData()
  }, [])

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Dashboard",
      icon: "ri-bank-line",
      link: "/dashboard",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault()
        setIsDashboard(!isDashboard)
        setIsCurrentState('Dashboard')
        updateIconSidebar(e)
      },
    },
    {
      id: "exchange",
      label: "Exchange",
      icon: " ri-exchange-dollar-line",
      link: "/exchange",
      stateVariables: isExchange,
      click: function (e) {
        e.preventDefault()
        setIsExchange(!isExchange)
        setIsCurrentState('Exchange')
        updateIconSidebar(e)
      },
    },
    {
      id: "deposit",
      label: "Deposit",
      icon: "ri-share-line",
      link: "/#",
      click: function (e) {
        e.preventDefault()
        setIsDeposit(!isDeposit)
        setIsCurrentState('Deposit')
        updateIconSidebar(e)
      },
      stateVariables: isDeposit,
      subItems: [
        {
          id: "credit_card_deposit",
          label: "Credit Card",
          link: "/deposit/credit-card",
          parentId: "deposit"
        },
        {
          id: "crypto_bank_deposit",
          label: "Crypto",
          link: "/deposit/crypto",
          click: function (e) {
            e.preventDefault()
            setIsDeposit(!isDeposit)
          },
          stateVariables: isDeposit,
        },
        {
          id: "bank_details",
          label: "Bank Details",
          link: "/deposit/bank-details",
        },
      ],
    },
    {
      id: "withdraw",
      label: "Withdraw",
      icon: "bx bx-money-withdraw",
      link: "/#",
      click: function (e) {
        e.preventDefault()
        setIsWithdraw(!isWithdraw)
        setIsCurrentState('Withdraw')
        updateIconSidebar(e)
      },
      stateVariables: isWithdraw,
      subItems: [
        {
          id: "withdraw_bank_transfer",
          label: "Bank Transfer",
          link: "/withdraw/bank-transfer",
          click: function (e) {
            e.preventDefault()
            setIsWithdraw(!isWithdraw)
          },
          stateVariables: isWithdraw,
        },
      ],
    },
    {
      id: "transactions",
      label: "Transactions",
      icon: "ri-exchange-dollar-fill",
      link: "/transactions",
      stateVariables: isInvoices,
      click: function (e) {
        e.preventDefault()
        setIsInvoices(!isInvoices)
        setIsCurrentState('Invoices')
        updateIconSidebar(e)
      },
    },
    {
      id: "invoices",
      label: "Invoices",
      icon: "ri-currency-line",
      link: "/invoices",
      stateVariables: isInvoices,
      click: function (e) {
        e.preventDefault()
        setIsInvoices(!isInvoices)
        setIsCurrentState('Invoices')
        updateIconSidebar(e)
      },
    },
    {
      id: "support",
      label: "Support",
      icon: "ri-file-unknow-line",
      link: "/support",
      stateVariables: isSupport,
      click: function (e) {
        e.preventDefault()
        setIsSupport(!isSupport)
        setIsCurrentState('Support')
        updateIconSidebar(e)
      }
    },
  ]

  // Additional menu items for no suspicious customers
  if (customerPlan !== 'suspicious') {
    menuItems.map((i) => {
      if (i.id === 'withdraw') {
        return {
          ...i,
          subItems: i.subItems.unshift({
            id: "withdraw_crypto",
            label: "Crypto",
            link: "/withdraw/crypto",
            parentId: "withdraw"
          })
        }
      } else return i
    })
  }

  return <React.Fragment>{menuItems}</React.Fragment>
}

export default Navdata
