import {APIClient} from "../helpers/api_helper"

const api = new APIClient

export const getTicket = (id) => {
    return api.get('/spa/support/show', {id})
}

export const getTickets = () => {
    return api.get('/spa/support/data', {})
}

export const createTicket = (data) => {
    return api.create('/spa/support/create', data)
}

export const commentTicket = (id, data) => {
    return api.create(`/spa/support/comment/${id}`, data)
}

export const closeTicket = (id) => {
    return api.create(`/spa/support/close/${id}`)
}
