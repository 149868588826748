import {APIClient} from '../helpers/api_helper'

const api = new APIClient

export function getProfileInfo() {
    return api.get('/spa/profile/info', {})
}

export function generate2fa() {
    return api.get('/spa/generate-2fa', {})
}

export function enable2fa(data) {
    return api.post('/spa/enable-2fa', data)
}

export function disable2fa() {
    return api.get('/spa/disable-2fa', {})
}

export function verify2fa(data) {
    return api.post('/spa/verify-2fa', data)
}

export const registration = (data) => {
  return api.post('/spa/registration', data)
}

export function logout() {
    return api.post('/sign-out', {})
}
