
export default {
  "Menu": "Меню",
  "Dashboard": "Главная",
  "Exchange": "Обмен",
  "Deposit": "Депозит",
  "Credit Card": "Кредитная Карта",
  "Crypto": "Криптовалюта",
  "Bank Details": "Банковские реквизиты",
  "Withdraw": "Вывод",
  "Bank Transfer": "Банковский перевод",
  "Transactions": "Транзакции",
  "Invoices": "Счета",
  "Support": "Поддержка",
  "More": "Больше"
}
