import {APIClient} from "../helpers/api_helper"

const api = new APIClient()

export const createCreditCardDeposit = (data) => {
    return api.post('/spa/deposit/credit-card', {...data})
}

export const createCryptoDeposit = (data) => {
    return api.post('/spa/deposit/crypto', {...data})
}

export const getDeposit = (id) => {
    return api.get('/spa/deposit/show', {id})
}

export const getBankDetails = () => {
    return api.get('/spa/deposit/bank-details', {})
}
