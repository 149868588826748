import {APIClient} from "../helpers/api_helper"

const api = new APIClient();

export const createCryptoWithdraw = (data) => {
    return api.post('/spa/withdrawal/crypto', data);
}

export const createBankTransferWithdraw = (data) => {
    return api.post('/spa/withdrawal/bank-transfer', data);
}

export const calculateMaxSum = (data) => {
    return api.post('/spa/withdrawal/calculate/max-sum', data);
}
