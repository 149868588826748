import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {Button, Card, CardBody, Col, Container, Row} from 'reactstrap'
import {ToastContainer} from "react-toastify"
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth"
import {useTranslation} from "react-i18next"

const Pending = () => {
  const logoHeight = import.meta.env.VITE_THEME_LOGO_HEIGHT || 70
  const logoSmHeight = import.meta.env.VITE_THEME_LOGO_HEIGHT_SM || 30
  const brandLogosUrl = import.meta.env.VITE_APP_URL + '/storage/brand_logos/'
  const logoLightUrl = brandLogosUrl + 'logo-light.png'
  const logoLightSmUrl = brandLogosUrl + 'logo-light-sm.png'
  const logoDarkUrl = brandLogosUrl + 'logo-dark.png'
  const logoDarkSmUrl = brandLogosUrl + 'logo-dark-sm.png'
  const navigator = useNavigate()

  const { t } = useTranslation()

  document.title = t("Sign In") + ' | ' + import.meta.env.VITE_APP_NAME

  return (
    <React.Fragment>
      <ToastContainer/>
      <div className="auth-page-content">
        <div className="auth-page-wrapper">
          <ParticlesAuth>
            <div className="auth-page-content">
              <Container>
                <Row>
                  <Col lg={12}>
                    <div className="text-center mt-sm-5 mb-4 text-white-50">
                      <div>
                        <Link to="/" className="logo logo-dark">
                      <span className="logo-sm">
                          <img src={logoDarkSmUrl} alt="" height={logoSmHeight}/>
                      </span>
                          <span className="logo-lg">
                        <img src={logoDarkUrl} alt="" height={logoHeight}/>
                      </span>
                        </Link>

                        <Link to="/" className="logo logo-light">
                      <span className="logo-sm">
                        <img src={logoLightSmUrl} alt="" height={logoSmHeight}/>
                      </span>
                          <span className="logo-lg">
                        <img src={logoLightUrl} alt="" height={logoHeight}/>
                      </span>
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Col md={8} lg={6} xl={5}>
                    <Card className="mt-4">
                      <CardBody className="p-4">
                        <div className="text-center mt-2">
                          <h5 className="text-primary">{t("Thank you for registration!")}</h5>
                          <p className="text-muted">{t("Soon your account will be activated")}</p>
                        </div>
                        <div className="text-center">
                          <Button color="primary" className="btn" onClick={() => navigator('/logout')}>
                            {t("Logout")}
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </ParticlesAuth>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Pending
