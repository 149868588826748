import {createSlice} from "@reduxjs/toolkit"

const initialState = {
	notifications: []
}

const NotificationSlice = createSlice({
	name: 'NotificationSlice',
	initialState,
	reducers: {
		setNotifications(state, action) {
			state.notifications = action.payload.data || []
		}
	}
})

export default NotificationSlice.reducer
export const {setNotifications} = NotificationSlice.actions
