import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap"
import BreadCrumb from "../../Components/Common/BreadCrumb"
import React, {useEffect, useState} from "react"
import {readAllNotification} from "../../api/notifications"
import NotificationsTable from "./NotificationsTable"
import {getNotifications} from "../../slices/notification/thunk"
import {useDispatch, useSelector} from "react-redux"
import {setNotifications} from "../../slices/notification/reducer"
import {useTranslation} from "react-i18next"

function Notifications() {
  const dispatch = useDispatch()
  const notifications = useSelector(s => s.Notification.notifications)
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0)
  const { t } = useTranslation()

	const getNotificationsData = () => {
		getNotifications().then(r => dispatch(setNotifications(r)))
  }

  const togglePageButtons = (disabled = false) => {
    let buttons = document.querySelectorAll('.notifications-page button')
    buttons?.forEach(i => i.disabled = disabled)
  }

  const markAllAsReadHandler = (e) => {
    togglePageButtons(true)
    readAllNotification().then(() => getNotificationsData())
  }

  useEffect(() => {
    setUnreadNotificationsCount(notifications.reduce((a, i) => a + Number(!i.read_at), 0))
    togglePageButtons()
  }, [notifications]);

  return (
    <React.Fragment>
      <div className="page-content notifications-page">
        <Container fluid>
          <BreadCrumb title="Crypto" pageTitle="Notifications"/>

          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("Notifications")}</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap">
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={markAllAsReadHandler}
                      >
                        <i className="bx bx-list-check fs-20 align-bottom me-1"></i>{" "}
                        {t("Mark all as read")}
                      </button>
                    </div>
                  </div>
                </Row>
              </CardHeader>
            </Card>
          </Col>

          <NotificationsTable
            updateBellNotifications={getNotificationsData}
            togglePageButtons={togglePageButtons}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Notifications
