
export default {
  // 2FA Auth
  "2fa_description": "Двухфакторная аутентификация (2FA) повышает безопасность доступа, требуя двух методов " +
    "(также называемых факторами) для проверки вашей личности. Двухфакторная аутентификация защищает от фишинга, " +
    "социальной инженерии и атак с перебором паролей, а также защищает ваши логины от злоумышленников, " +
    "использующих слабые или украденные учетные данные.",
  "Disable 2FA": "Отключить 2FA",
  "Generate secret key to Enable 2FA": "Сгенерировать секретный ключ для включения 2FA",
  "Re-generate secret key to Enable 2FA": "Повторная генерация секретного ключа для включения 2FA",
  "Please scan the code or enter the security code manually to get your verification code to enable 2FA.": "Отсканируйте " +
    "код или введите его вручную, чтобы получить проверочный код для включения 2FA.",
  "Google Authenticator QRCode": "QRCode аутентификатора Google",
  "2FA code": "Код 2FA",
  "Enter verification code from 2FA app here": "Введите проверочный код из приложения 2FA",
  "Verify": "Проверить",

  // Progress
  "Your Profile Progress": "Прогресс вашего профиля",
  "Fill more information about you to increace the progress!": "Заполните больше информации о себе, чтобы увеличить прогресс!",

  //Personal detail
  "Personal Details": "Личные Данные",
  "Phone Number": "Номер телефона",
  "Email Address": "Адрес электронной почты",
  "Postal Code": "Почтовый индекс",
  "Birthdate": "Дата рождения",
  "Details": "Подробности",
  "Enter your firstname": "Введите ваше имя",
  "Enter your lastname": "Введите вашу фамилию",
  "Enter your phone number": "Введите ваш номер телефона",
  "Enter your email": "Введите вашу электронную почту",
  "Enter postal code": "Введите ваш почтовый индекс",
  "Update": "Обновить",

  //Change password
  "Change Password": "Изменить Пароль",
  "Old Password": "Старый пароль",
  "New Password": "Новый пароль",
  "Confirm Password": "Подтвердите пароль",
  "Enter current password": "Введите текущий пароль",
  "Enter new password": "Введите новый пароль",

  //Documents
  "Documents": "Документы",
  "File Name": "Имя файла",
  "Upload Date": "Дата загрузки",
  "Upload File": "Загрузить файл",
  "No data": "Нет данных",

  //Upload File modal
  "Upload document": "Загрузить документ",
  "Document type": "Тип документа",
  "Files": "Файлы",
  "Browse": "Просмотреть",
  "No files selected": "Файлов не выбрано",
  "Submit": "Отправить",
  "Choose...": "Выберите..."
}
