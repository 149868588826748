import {APIClient} from "../helpers/api_helper"

const api = new APIClient()

export function getInvoice(id) {
    return api.get(`/spa/invoices/show/${id}`, {})
}

export function cryptoPay(id) {
    return api.post('/spa/invoices/pay/crypto/', {id})
}

export function balancePay(id) {
    return api.post('/spa/invoices/pay/balance/', {id})
}
