import {Col, Label} from "reactstrap";
import Select from "react-select";
import {useEffect, useState} from "react";
import {APIClient} from "../../../helpers/api_helper";


const SelectInput = (props) => {
  const api = new APIClient();
  const [selectedValues, setSelectedValues] = useState(null);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getOptions = () => {
    if (props?.yes_or_no) {
      setOptions([{label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}]);
    } else if (options.length < 1) {
      setLoading(true);
      api.get('/spa/select-list-data/', {name: props.name}).then((res) => {
        setOptions(res);
        setLoading(false);
      })
    }
  };

  useEffect(() => {
    let values = [];

    if(!Array.isArray(selectedValues)) {
      values = selectedValues?.value || null;
    } else {
      values = selectedValues?.map((i) => i.value) || [];
    }

    let data = {
      selectName: props.name,
      values: values,
    }
    props.selectedDataHandler(data)
  }, [selectedValues]);

  return (
    <Col className="col-12 col-sm-6 col-md-6 col-xs-4 col-lg-3">
      <div>
        <Label htmlFor="choices-multiple-default" className="form-label mb-0">{props.title}</Label>
        <Select
          placeholder="Choose..."
          isClearable={true}
          value={selectedValues}
          isMulti={props.is_multiple || false}
          onMenuOpen={getOptions}
          onChange={(selected) => {
            setSelectedValues(selected);
          }}
          options={options}
          isLoading={loading}
        />
      </div>
    </Col>
  )
};

export default SelectInput;
