import {APIClient} from "../helpers/api_helper";
import {store} from "../store";

const api = new APIClient();

export const lastTransactions = (data) => {
  return api.get('/spa/widgets/last-transactions', {
    limit: data.limit,
    type: data.type || '',
    currencyType: data.currencyType || '',
  })
}

export const cryptoRates = () => {
  return api.get('/spa/static-data', {name: 'crypto_rates'})
}
