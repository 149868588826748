import { createSlice } from "@reduxjs/toolkit"

export const initialState = {
  ticketsData: {},
  error: '',
}

const TicketsSlice = createSlice({
  name: "Tickets",
  initialState,
  reducers: {
    setTickets(state, action) {
      state.ticketsData = action.payload
    }
  },
})

export const { setTickets } = TicketsSlice.actions

export default TicketsSlice.reducer
