import {useEffect, useState} from "react";
import {getLoggedinUser} from "../../helpers/api_helper";
import {useDispatch} from "react-redux";
import {setProfile} from "../../slices/auth/profile/reducer";

const useProfile = () => {
  const dispatch = useDispatch()
  const userProfileSession = getLoggedinUser()
  let token =
    userProfileSession &&
    userProfileSession["access_token"]
  const [loading, setLoading] = useState(!userProfileSession)
  const [userProfile, setUserProfile] = useState(
    userProfileSession ? userProfileSession.user : null
  );

  useEffect(() => {
    const userProfileSession = getLoggedinUser()
    let token =
      userProfileSession &&
      userProfileSession["access_token"]
    setUserProfile(userProfileSession ? userProfileSession.user : null)
    dispatch(setProfile(userProfileSession))
    setLoading(!token)
  }, [])


  return {userProfile, loading, token}
}

export {useProfile}
