import React, {useEffect, useState} from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  Table,
  TabPane,
} from "reactstrap"
import BreadCrumb from "../../Components/Common/BreadCrumb"
import {getBankDetails} from "../../api/deposit"
import classnames from "classnames"
import {copyToClipboard} from "../../helpers/utils"
import {useTranslation} from "react-i18next"

const BankDetails = () => {
  const [bankDetails, setBankDetails] = useState([])
  const [activeTab, setActiveTab] = useState('')
  const [isBankDetailsBlocked, setIsBankDetailsBlocked] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()

  const getBankDetailsData = () => {
    getBankDetails().then((r) => {
      if (r.message) {
        setIsBankDetailsBlocked(true)
      } else {
        setBankDetails(r)
      }
    }).finally(() => {
        setIsLoading(false)
      }
    )
  }

  function toggleTab(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  useEffect(() => {
    if (bankDetails.length > 0) {
      setActiveTab(bankDetails[0].name)
    }
  }, [bankDetails])

  useEffect(() => {
    getBankDetailsData()
  }, [])

  document.title = t('Bank Details') + ' | ' + import.meta.env.VITE_APP_NAME

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Home" pageTitle="Bank Details"/>
          <Row className="justify-content-center">
            <Col className="col-sm-12 col-lg-10 col-xl-8">
              <Card>
                <CardBody>
                  {!isBankDetailsBlocked ? <p className="fs-16">{t("Banks List")}</p> : null}
                  {isBankDetailsBlocked ?
                    <div className="d-flex align-items-center mb-3 flex-column w-100">
                      <div className="avatar-xl my-4 mx-auto">
                        <div className="avatar-title bg-light text-muted rounded-circle"
                             style={{fontSize: '95px'}}>
                          <i className="mdi mdi-block-helper"></i>
                        </div>
                      </div>
                      <h4>{t("Bank deposit is temporary disabled !")}</h4>
                      <span>{t("This information is not currently available.")}</span> <br/>
                      <span>{t("For more details, please contact support.")}</span>
                    </div> :
                    <>
                      <Nav pills className="nav-customs nav-danger mb-3">
                        {bankDetails.length > 0 &&
                          bankDetails.map((bankDetail, idx) => (
                            <NavItem key={idx}>
                              <NavLink
                                style={{cursor: "pointer"}}
                                className={classnames({active: activeTab === bankDetail.name,})}
                                onClick={() => {
                                  toggleTab(bankDetail.name)
                                }}
                              >
                                {bankDetail.name}
                              </NavLink>
                            </NavItem>
                          ))}
                      </Nav>
                      <TabContent activeTab={activeTab} className="text-muted">
                        {bankDetails.length > 0 ?
                          bankDetails.map((bankDetail, idx) => (
                            <TabPane key={idx} tabId={bankDetail.name}>
                              <Row className="d-flex mt-3 d-flex">
                                <Col xs={12}>
                                  <div
                                    className="text-center mb-3 pb-3 pt-3 border-bottom
                                      border-bottom-dashed border-top border-top-dashed"
                                  >
                                    {t("BANK INFORMATION")}
                                  </div>
                                  <Table borderless responsive>
                                    <tbody>
                                    <tr>
                                      <td className="border-bottom border-bottom-dashed pb-4">
                                        {bankDetail.fields.credentials.map((field, idx) =>
                                          <div
                                            className="fs-14 d-flex"
                                            key={idx}
                                          >
                                            <span className="fw-medium d-block w-25 ms-2 text-start me-2">
                                              <i className="ri-checkbox-circle-fill text-primary me-1"></i>
                                              {field.key}:
                                            </span>
                                            <span
                                              className="cursor-pointer"
                                              onClick={() => copyToClipboard(field.value)}
                                            >
                                             {field.value}
                                            </span>
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                    </tbody>
                                  </Table>
                                </Col>
                              </Row>
                            </TabPane>
                          )) : (
                            isLoading ?
                              <div className="w-100 text-center">
                                <Spinner color="primary" className="my-3">Loading ...</Spinner>
                              </div> :
                              <div className="w-100 text-center pt-3 mb-3 border-bottom
                                border-bottom-dashed border-top border-top-dashed">
                                <p className="fs-18">{t("Banks list is empty.")}</p>
                              </div>
                          )
                        }
                      </TabContent>
                    </>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BankDetails
