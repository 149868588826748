import flagus from "../assets/images/flags/us.svg";
import flagrussia from "../assets/images/flags/russia.svg";

const languages = {
  ru: {
    label: "Русский",
    flag: flagrussia,
  },
  en: {
    label: "English",
    flag: flagus,
  }
}

export default languages
