import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  user: {},
  accountBalance: [],
  twoFactorAuthRequired: false,
  error: '',
};

const ProfileSlice = createSlice({
  name: "Profile",
  initialState,
  reducers: {
    setProfile(state, action) {
      state.user = action.payload?.user || {}
    },
    setAccountBalance(state, action) {
      state.accountBalance = action.payload
    },
    setTwoFactorAuthState(state, action) {
      state.twoFactorAuthRequired = !!action.payload
    }
  },
});

export const {
  setProfile,
  setAccountBalance,
  setTwoFactorAuthState
} = ProfileSlice.actions

export default ProfileSlice.reducer;
