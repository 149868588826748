import React, {useEffect, useRef, useState} from 'react'
import {Col, Form, Input, Row} from 'reactstrap'
import {calculate, exchange, getExchangeFee} from "../../api/exchange"
import {toast} from "react-toastify"
import {selectListData} from "../../api/general"
import {getAccountBalance} from "../../slices/auth/profile/thunk"
import {useDispatch, useSelector} from "react-redux"
import {Link} from "react-router-dom"
import {isTwoPrecision, toTwoPrecision} from "../../helpers/utils"
import {Tooltip} from "react-tooltip"
import {useTranslation} from "react-i18next"

let requestTimer = null

const ExchangeTab = ({formSentHandler, isReal}) => {
  const dispatch = useDispatch()
  const accountBalance = useSelector(s => s.Profile.accountBalance)
  const [assets, setAssets] = useState([])
  const calculateSide = useRef('sell')

  const [sellAsset, setSellAsset] = useState(0)
  const [buyAsset, setBuyAsset] = useState('')
  const [assetBalance, setAssetBalance] = useState(0)
  const [assetUsdBalance, setAssetUsdBalance] = useState(0)
  const [sellAssetSelect, setSellAssetSelect] = useState(null)
  const [sellAssetUsdPrice, setSellAssetUsdPrice] = useState(0)
  const [buyAssetSelect, setBuyAssetSelect] = useState(null)
  const [sellAmount, setSellAmount] = useState('')
  const [buyAmount, setBuyAmount] = useState('')
  const [buyAmountInUsd, setBuyAmountInUsd] = useState(0)
  const [totalPayAmount, setTotalPayAmount] = useState(0)
  const [feeAmount, setFeeAmount] = useState(0)
  const [exchangeFee, setExchangeFee] = useState('')
  const [updateSellAssetBalance, setUpdateSellAssetBalance] = useState(1)
  const { t } = useTranslation()

  const calculateAmount = () => {
    requestTimer && clearTimeout(requestTimer)

    if (sellAmount || buyAmount) {
      let amount = 0
      let sellAsset = sellAssetSelect || accountBalance[0]?.asset_id
      let buyAsset = buyAssetSelect || assets[0]?.value

      if (calculateSide.current === 'buy') {
        amount = buyAmount || 0
      } else {
        amount = sellAmount || 0
      }

      requestTimer = setTimeout(() => {
        calculate({
          sell_asset: sellAsset,
          buy_asset: buyAsset,
          amount: amount,
          side: calculateSide.current,
          available_balance: isReal,
          }
        ).then(r => {
          if (r.success) {
            setBuyAmountInUsd(toTwoPrecision(r?.buy_amount_in_usd))
            setTotalPayAmount((Number(r?.sell_amount) + Number(r?.sell_fee_amount)).toFixed(8) * 1 || 0)
            setFeeAmount(Number(r?.sell_fee_amount).toFixed(8) * 1 || 0)
            let assetCode = assets?.find(i => Number(i.value) === Number(sellAsset || 1))?.label || 'BTC'

            if (calculateSide.current === 'buy') {
              setSellAmount(isTwoPrecision(assetCode, r?.sell_amount || 0))
              calculateSide.current = 'sell'
              // calculateLockedAmount()
            } else {
              setBuyAmount(isTwoPrecision(buyAsset?.label, r?.buy_amount || 0))
            }
          } else {
            Object.values(r.errors).forEach(i => i.forEach(error => toast.error(error)))
          }
        })
      }, 300)
    }
  }

  const sellSelectHandler = (e) => {
    setSellAssetSelect(e.target.options[e.target.selectedIndex].value)
  }

  const buySelectHandler = (e) => {
    setBuyAssetSelect(e.target.options[e.target.selectedIndex].value)
  }

  const sellAmountInputHandler = (e) => {
    calculateSide.current = 'sell'
    let val = e.target?.value.replace(/,/g, '.')
    setSellAmount(val < 0 ? Math.abs(val) : val)
  }

  const buyAmountInputHandler = (e) => {
    calculateSide.current = 'buy'
    let val = e.target?.value.replace(/,/g, '.')
    setBuyAmount(val < 0 ? Math.abs(val) : val)
  }

  const resetForm = () => {
    setSellAmount('')
    setBuyAmount('')
    setBuyAmountInUsd('')
    setFeeAmount(0.00)
    setTotalPayAmount(0.00)
    setSellAssetUsdPrice('')
  }

  const submitHandler = (e) => {
    e.preventDefault()

    let data
    const btn = e.target.querySelector('[type="submit"]')
    btn.disabled = true

    let formData = Object.fromEntries(new FormData(e.target))

    if (isReal) {
      data = {...formData, use_unlocked_balance: true}
    } else {
      data = formData
    }

    if (buyAmount <= 0) {
      toast.error(t('Buy amount must be greater than zero'))
      btn.disabled = false
      return
    }

    exchange(data).then(r => {
      if (r.success) {
        resetForm()
        toast.success(r.message)
        formSentHandler()
        dispatch(getAccountBalance())
        setUpdateSellAssetBalance(Number(updateSellAssetBalance) + 1)
      } else {
        toast.error(r.message)
      }
    }).catch(r => {
      if (r.errors) {
        Object.values(r.errors).forEach(i => i.forEach(j => toast.error(j)))
      } else {
        toast.error(r.message || 'Server error!')
      }
    }).finally(() => btn.disabled = false)
  }

  const calculateMaxSumHandler = () => {

    if (sellAsset && assetBalance) {
      setSellAmount(isTwoPrecision(sellAsset, assetBalance))
    } else {
      toast.warning(t('Select the asset you want to sell with a balance greater than zero.'))
    }
  }

  useEffect(() => {
    if (Number(sellAmount)) {
      calculateAmount()
    }
  }, [sellAmount])

  useEffect(() => {
    if (Number(buyAmount)) {
      calculateAmount()
    }
  }, [buyAmount])

  useEffect(() => {
    calculateAmount()
  }, [sellAssetSelect, buyAssetSelect])

  useEffect(() => {
    let balance = accountBalance.filter(i => !!i.is_real === !!isReal)
    let currentSellAsset = balance?.find(i => {
      return Number(i.asset_id) === Number(sellAssetSelect || accountBalance[0]?.asset_id)
    })

    setSellAsset(currentSellAsset?.code)
    setAssetBalance(currentSellAsset?.balance || 0)
    setAssetUsdBalance(currentSellAsset?.balance * currentSellAsset?.price || 0)
    setSellAssetUsdPrice(currentSellAsset?.price || 0)
  }, [accountBalance, sellAssetSelect])

  useEffect(() => {
    selectListData('assets_list').then(r => {
      setAssets(r)
    })
  }, [updateSellAssetBalance])

  useEffect(() => {
    if (buyAssetSelect) {
      setBuyAsset(assets?.find(i => Number(i.value) === Number(buyAssetSelect)))
    }
  }, [buyAssetSelect])

  useEffect(() => {
   if (Number(!sellAmount)) {
     setSellAmount('')
     setFeeAmount('0.00')
     setBuyAmountInUsd('0.00')
   }
  }, [sellAmount])

  useEffect(() => {
    if (Number(!buyAmount)) {
      setBuyAmount('')
      setTotalPayAmount(0.00)
    }
  }, [buyAmount])

  useEffect(() => {
    getExchangeFee().then(r => {
      setExchangeFee(r.fee)
    })
  }, [])

  return (
    <React.Fragment>
      <div className="p-3 bg-soft-warning">
        <div className="float-end ms-2">
          <h6 className="text-warning mb-0">
            <span className="text-dark">{isTwoPrecision(sellAsset, assetBalance)} (${toTwoPrecision(assetUsdBalance)})</span>
          </h6>
        </div>
        <h6 className="mb-0 text-danger">{t("Sell asset balance")} : </h6>
      </div>
      <Form onSubmit={submitHandler}>
        <div className="p-3">
          <Row>
            <Col xs={6}>
              <div className="mb-3">
                <label>{t("Sell asset")} :</label>
                <select className="form-select" name="sell_asset_id" onChange={sellSelectHandler}>
                  {assets?.length
                    ? assets.map((asset) => {
                      if (accountBalance.find(i => i.code === asset.label)) {
                        return <option key={asset.value} value={asset.value}>{asset.label}</option>
                      }
                    })
                    : <option>-</option>
                  }
                </select>
              </div>
            </Col>
            <Col xs-={6}>
              <div className="mb-3">
                <label>{t("Buy asset")} :</label>
                <select className="form-select" name="buy_asset_id" onChange={buySelectHandler}>
                  {assets?.length
                    ? assets.map((asset) => <option key={asset.value} value={asset.value}>{asset.label}</option>)
                    : <option>-</option>
                  }
                </select>
              </div>
            </Col>
          </Row>
          <div>
            <div className="input-group mb-3">
              <label className="input-group-text">{t("Sell amount")}</label>
              <Input
                type="text"
                className="form-control"
                name="sell_amount"
                onInput={sellAmountInputHandler}
                value={sellAmount}
                placeholder="0.00000000"
                autoComplete='off'
              />
            </div>

            <div className="input-group mb-3">
              <label className="input-group-text">{t("Buy amount")}</label>
              <Input
                type="text"
                className="form-control"
                name="buy_amount"
                onInput={buyAmountInputHandler}
                value={buyAmount}
                placeholder="0.00000000"
                autoComplete='off'
              />
            </div>
          </div>
          <div className="mt-3 pt-2">
            <div className="d-flex mb-2">
              <div className="flex-grow-1">
                <div className="fs-13 mb-0">
                  {t("Transaction Fees")}
                  <span className="text-muted ms-1 fs-11">({exchangeFee}%)</span>
                    {!isReal &&
                      <span>
                      <i
                        className="text-muted mb-0 pb-0 ps-1 fs-13 mdi mdi-information-outline"
                        data-tooltip-id={'fee'}
                        data-tooltip-content={t('Paying from Available Balance')}
                      ></i>
                    <Tooltip id={'fee'}/>
                    </span>
                  }
                </div>
              </div>
              <div className="flex-shrink-0">
                <h6 className="mb-0">
                  {sellAsset} {isTwoPrecision(sellAsset, feeAmount)} (${toTwoPrecision(feeAmount * sellAssetUsdPrice)})
                </h6>
              </div>
            </div>
            <div className="d-flex mb-2">
              <div className="flex-grow-1">
                <p className="fs-13 mb-0">{t("Total sell amount")}:</p>
              </div>
              <div className="flex-shrink-0">
                <h6 className="mb-0">
                  {`${sellAsset ? sellAsset : ''} ${sellAmount ? isTwoPrecision(sellAsset, sellAmount) : '0.00'}
                    ($${sellAsset ? (!isReal && sellAsset ? buyAmountInUsd :
                    toTwoPrecision(Number(buyAmountInUsd) + (feeAmount * sellAssetUsdPrice))) : '0.00'})`}
                </h6>
              </div>
            </div>
            <div className="d-flex mb-2">
              <div className="flex-grow-1">
                <p className="fs-13 fw-semibold mb-0">
                  {!isReal ? t("Total pay amount:") : t("You receive:")}
                </p>
              </div>
              <div className="flex-shrink-0">
                {!isReal ?
                  <h6 className="mb-0 fw-semibold">
                    {`${sellAsset ? sellAsset : ''} ${buyAmount ? isTwoPrecision(sellAsset, totalPayAmount) : '0.00'}
                     ($${sellAsset ? toTwoPrecision(totalPayAmount * sellAssetUsdPrice) : '0.00'})`}
                  </h6> :
                  <h6 className="mb-0 fw-semibold">
                    {`${buyAsset.label ? buyAsset.label : 'BTC'}
                     ${buyAmount ? buyAmount : '0.00'}
                     ($${buyAmount ? buyAmountInUsd : '0.00'})`}
                  </h6>
                }
              </div>
            </div>
          </div>
          <Row className="mb-3">
            <Col xs={12}>
              <div>
                <Link to="" className="text-success" onClick={calculateMaxSumHandler}>{t("Max sum")}</Link>
              </div>
            </Col>
          </Row>
          <div className="mt-3 pt-2">
            <button type="submit" className="btn btn-primary w-100">{t("Exchange")}</button>
          </div>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default ExchangeTab
