import React from 'react'
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth"
import {Card, CardBody, Col, Container, Row} from "reactstrap"
import {useTranslation} from "react-i18next"

const Unsubscribe = () => {
  const { t } = useTranslation()

  return (
    <div className="auth-page-content">
      <div className="auth-page-wrapper">
        <ParticlesAuth>
          <div className="auth-page-content">
            <Container>
              <Row className="justify-content-center mt-5">
                <Col md={8} className="mt-5">
                  <Card className="mt-5">
                    <CardBody className="p-5">
                      <div className="text-center mt-2">
                        <i
                          className="mdi mdi-email-remove-outline text-danger"
                          style={{fontSize: "60px"}}
                        ></i>
                        <h1 className="text-primary mb-3">{t("You unsubscribed!")}</h1>
                        {/*<span className="text-muted w-100 w-md-75">*/}
                        {/*  {t("We're sorry to lose you, but we totally understand.")}*/}
                        {/*</span><br/>*/}
                        <span className="text-muted w-100 w-md-75 fs-18">
                          {t("You can close this window.")}
                        </span>
                      </div>
                      <div className="text-center">
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </ParticlesAuth>
      </div>
    </div>
  )
}

export default Unsubscribe
