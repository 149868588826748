import {createAsyncThunk} from "@reduxjs/toolkit";
import {getBalance} from "../../api/account";

export const getBalanceData = createAsyncThunk("account/getBalance", async () => {
  try {
    return getBalance();
  } catch (error) {
    return error;
  }
});
