import React from "react"
import { Navigate } from "react-router-dom"

// Login
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import TwoFactorAuth from "../pages/TwoFactorAuth"
import DirectLogin from "../pages/DirectLogin"
// Registration
import CoverSuccessMsg from "../pages/AuthenticationInner/SuccessMessage/CoverSuccessMsg"
import CoverSignUp from "../pages/AuthenticationInner/Register/CoverSignUp"
import Pending from "../pages/Authentication/Pending"
// Dashboard
import DashboardCrypto from "../pages/DashboardCrypto"
// Invoices
import Invoices from "../pages/Invoices"
import InvoiceDetails from "../pages/Invoices/InvoiceDetails"
// Support
import Support from "../pages/Support"
import CreateTicket from "../pages/Support/CreateTicket"
import SingleTicket from "../pages/Support/SingleTicket"
// Exchange
import Exchange from "../pages/Exchange"
// Deposit
import CreditCard from "../pages/Deposit/CreditCard"
import Crypto from "../pages/Deposit/Crypto"
import Deposit from "../pages/Deposit/DepositDetail"
import BankDetails from "../pages/Deposit/BankDetails"
// Withdraw
import BankTransfer from "../pages/Withdraw/BankTransfer"
import WithdrawCrypto from "../pages/Withdraw/WithdrawCrypto"
// Profile
import Profile from "../pages/Profile"
// Notifications
import Notifications from "../pages/Notifications"
// Transactions
import Transactions from "../pages/Transactions"
// Unsubscribe
import Unsubscribe from "../pages/Authentication/Unsubscribe"
// Error
import Alt404 from "../pages/AuthenticationInner/Errors/Alt404"

const authProtectedRoutes = [
  //Dashboard
  { path: "/dashboard", component: <DashboardCrypto /> },
  //Invoices
  { path: "/invoices", component: <Invoices /> },
  { path: "/invoices/show/:id", component: <InvoiceDetails /> },
  //Exchange
  { path: "/exchange", component: <Exchange /> },
  //Deposit
  { path: "/deposit/credit-card", component: <CreditCard /> },
  { path: "/deposit/crypto", component: <Crypto /> },
  { path: "/deposit/bank-details", component: <BankDetails /> },
  { path: "/deposit/show/:id", component: <Deposit /> },
  //Withdraw
  { path: "/withdraw/bank-transfer", component: <BankTransfer /> },
  //Support
  { path: "/support", component: <Support /> },
  { path: "/withdraw/crypto", component: <WithdrawCrypto /> },
  { path: "/support/create", component: <CreateTicket /> },
  { path: "/support/show/:id", component: <SingleTicket /> },
  // Profile
  { path: "/profile", component: <Profile /> },
  // Notifications
  { path: "/notifications", component: <Notifications /> },
  // Transactions
  { path: "/transactions", component: <Transactions /> },
  // Pending Page
  { path: '/pending-page', component: <Pending />},
  // Logout
  { path: "/logout", component: <Logout /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
]

const login = { path: "/login", component: <Login /> }
const publicRoutes = [
  // 2FA
  { path: '/2fa', component: <TwoFactorAuth />},
  { path: '/unsubscribe-page', component: <Unsubscribe />},
  { path: '/error', component: <Alt404 />},

  // Authentication Page
  login,
  { path: "/direct-login", component: <DirectLogin /> },
  import.meta.env.VITE_REGISTRATION_BLOCKED === 'false' ?
    { path: "/create-new-account", component: <CoverSignUp /> } :
    login,
  { path: "/auth-success-msg-cover", component: <CoverSuccessMsg /> },
]

export { authProtectedRoutes, publicRoutes }
