import React, {useEffect, useState} from 'react'
import IntlTelInput from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/main.css'

const PhoneInputComponent = ({phone, onChange}) => {
  const [value, setValue] = useState(phone)

  const changePhoneNumberHandler = (e) => {
    const newValue = e.target.value
    setValue(newValue)

    if (onChange) {
      onChange(newValue)
    }
  }

  useEffect(() => {
    setValue(phone)
  }, [phone])

  return (
    <>
      <IntlTelInput
        inputClassName="form-control"
        className="w-100"
        fieldName="full_phone"
        value={value}
        // onPhoneNumberChange={(status, value, countryData, number, id) => {
        //   console.log(status, value, countryData, number, id)
        // }}
        // onSelectFlag={(status, value, countryData, number, id) => {
        //   console.log(value, countryData)
        // }}

        telInputProps={{'onInput': changePhoneNumberHandler}}
      />
    </>
  )
}

export default PhoneInputComponent
