import React, {useEffect, useRef, useState} from 'react'
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap"
import {useDispatch, useSelector} from "react-redux"
import {getAccountBalance} from "../../slices/auth/profile/thunk"
import {cryptoIconsMapper, isTwoPrecision} from "../../helpers/utils"
import {useTranslation} from "react-i18next"

// let accountBalanceInterval

const AccountBalanceDropdown = () => {
  const dispatch = useDispatch()
  const [isAccountBalanceDropdown, setIsAccountBalanceDropdown] = useState(false)
  const accountBalance = useSelector(s => s.Profile.accountBalance)
  const { t } = useTranslation()

  const chartColors= useRef([
    'primary',
    'info',
    'warning',
    'success',
    'danger',
  ])

  const getBalanceData = () => {
    dispatch(getAccountBalance('account balance dropdown'))
  }

  const toggleAccountBalanceDropdown = () => {
    setIsAccountBalanceDropdown(!isAccountBalanceDropdown)
  }

  useEffect(() => {
    getBalanceData()
  }, [])

  const reduceBalance = accountBalance.reduce((acc, asset) => {
    if (!acc[asset.code]) {
      acc[asset.code] = { ...asset }
    } else {
      acc[asset.code].balance = Number(acc[asset.code].balance) + Number(asset.balance)
      acc[asset.code].price = asset.price
    }

    return acc
  }, {})

  const mergedBalance = Object.values(reduceBalance);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isAccountBalanceDropdown}
        toggle={toggleAccountBalanceDropdown}
        className="topbar-head-dropdown ms-1 header-item"
      >
        <DropdownToggle
          type="button"
          tag="button"
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
        >
          <i className="bx bx-dollar-circle fs-24"></i>
        </DropdownToggle>
        <DropdownMenu end className="dropdown-menu-lg py-0">
          <div className="dropdown-head bg-primary bg-pattern border-bottom rounded-top">
            <div className="p-3">
              <h6 className="m-0 fs-16 fw-semibold text-white">{t("Account Balance")}</h6>
            </div>
          </div>
          {mergedBalance.length ?
            mergedBalance.map((asset, idx) => {
            return (
              <DropdownItem key={idx} className="ps-2">
                <div className="d-flex pt-1">
                  <div className="flex-shrink-0 avatar-xs">
                    <span className="avatar-title bg-light p-1 rounded-circle">
                      <img
                        src={cryptoIconsMapper[asset.code]}
                        className="img-fluid"
                        alt={cryptoIconsMapper[asset.code]}
                      />
                    </span>
                  </div>
                  <div className="flex-grow-1 ms-2 me-3">
                    <h6 className="mb-1">{asset.name}</h6>
                    <p className="fs-12 mb-0 text-muted">
                      <i
                        className={`mdi mdi-circle fs-10 align-middle text-${chartColors.current[(idx % chartColors.current.length)]} me-1`}
                      ></i>
                      {asset.code}
                    </p>
                  </div>
                  <div className="flex-shrink-0 text-end">
                    <h6 className="mb-1">
                      {isTwoPrecision(asset.code, Number(asset?.balance || 0))}
                    </h6>
                    <p className="text-success fs-12 mb-0">
                      ${(asset?.balance * asset?.price).toFixed(2) || 0}
                    </p>
                  </div>
                </div>
              </DropdownItem>
            )
          }) : (
            <li className="list-group-item fs-14 my-3 text-center">{t("No balance")}</li>
          )}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default AccountBalanceDropdown
