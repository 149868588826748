import React from 'react';
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";
import {toTwoPrecision} from "../../helpers/utils";
import {useTranslation} from "react-i18next"

const BalanceChart = ({dataColors, series, labels}) => {
  let donutAccountBalanceColors = getChartColorsArray(dataColors)
  const { t } = useTranslation()

  let options = {
    labels: labels,
    chart: {
      type: "donut",
      height: 224,
    },

    plotOptions: {
      pie: {
        size: 100,
        offsetX: 0,
        offsetY: 0,
        donut: {
          size: "70%",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '18px',
              offsetY: -5,
            },
            total: {
              show: true,
              fontSize: '13px',
              label: t('Total amount'),
              color: '#9599ad',
              fontWeight: 500,
              formatter: function (w) {
                return "$" + w.globals.seriesTotals.reduce(function (a, b) {
                  return (Number(a) + Number(b)).toFixed(2);
                }, 0);
              }
            },
            value: {
              show: true,
              fontSize: '20px',
              color: '#343a40',
              fontWeight: 500,
              offsetY: 5,
              formatter: function (val) {
                return "$" + Number(val).toFixed(2);
              }
            }
          }
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return "$" + toTwoPrecision(value);
        }
      }
    },
    stroke: {
      lineCap: "round",
      width: 2
    },
    colors: donutAccountBalanceColors,
  };
  return (
    <React.Fragment>
      <ReactApexChart dir="ltr"
                      options={options}
                      series={series}
                      type="donut"
                      height="224"
                      className="apex-charts"
      />
    </React.Fragment>
  );
};

const WidgetsCharts = ({seriesData, chartsColor}) => {
  const areaChartLitecoinColors = [chartsColor];
  let options = {
    chart: {
      width: 130,
      height: 46,
      type: "area",
      sparkline: {
        enabled: true,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 1.5,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [50, 100, 100, 100],
      },
    },
    colors: areaChartLitecoinColors
  };

  return (
    <React.Fragment>
      <ReactApexChart dir="ltr"
                      options={options}
                      series={[...seriesData]}
                      type="area"
                      height="46"
                      className="apex-charts"
      />
    </React.Fragment>
  );
};

export {BalanceChart, WidgetsCharts};
